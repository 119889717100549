import React, { Component } from 'react';

import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton} from '@ionic/react';

import Reset from '../components/Reset';


export default class ResetPassword extends Component {

  
  constructor(props) {
    super(props);
    
    const search = props.location.search; 
    const params = new URLSearchParams(search);
    const email = params.get('email'); 

    this.state = {
      token: this.props.match.params.token,
      email: email,
      sent: false
    };


    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(error, data) {
    if (!!error) {
      let response = error.response.data.error;
      if (!(response instanceof Object)) {
        this.props.toast.current.add({
          message: `Si è verificato un errore, eseguire una nuova richiesta di cambio password`,
          color: 'warning',
        });
      }
      this.setState({ sent:false });
      return;
    }
    this.setState({ sent:true });
    /*this.props.toast.current.add({
      message: `Operazione eseguita correttamente`,
    }); */

  }

  render() {
    return (
      <>

        <IonHeader>
         <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>Cambio password</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          {
            !this.state.sent
            ? <Reset 
              onSubmit={ this.onSubmit }
              token={ this.state.token } 
              email={ this.state.email } 
              />
            : <div className="ion-padding ">
              La password è stata aggiornata, esegui l'accesso con la nuova password.
              <br/><br/><br/><br/>
              <a href='/auth/login' onClick={ (e) => {
                    e.preventDefault();
                    this.setState({ state: 'login', sent:false});
                    this.props.history.replace('/auth/login?segment=login&redirect=/app/home');
                  }}>Vai al login</a>
            </div>
          }
        </IonContent>
      </>
    );
  }
}