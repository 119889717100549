import axios from './axios';

import auth from '../resources/Auth';

export default {

  get: async(id=undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/order${!!id ? `/${id}`: ''}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },

  duplicate: async(id) => {
    let url = `${process.env.REACT_APP_API_URL}/order/${id}/duplicate`;

    return await axios.post(url, {}, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },

  paymentType: async(id,type) => {
    let url = `${process.env.REACT_APP_API_URL}/order/${id}/payment-type/${type}`;

    return await axios.post(url, {}, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },

  confirm: async(id) => {
    let url = `${process.env.REACT_APP_API_URL}/order/${id}/confirm`;

    return await axios.post(url, {}, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },

  cancel: async(id) => {
    let url = `${process.env.REACT_APP_API_URL}/order/${id}/cancel`;

    return await axios.post(url, {}, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },
  

  // remove: async(id) => {
  //   let url = `${process.env.REACT_APP_API_URL}/order/${id}`;

  //   return await axios.delete(url, {
  //     headers: { Authorization: `Bearer ${auth.token}` }
  //   });
  // },



}