import axios from './axios';

import auth from '../resources/Auth';

export default {

  get: async(id=undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/prescriptions${!!id ? `/${id}`: ''}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },


  create: async(prescription) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/prescriptions`;

    prescription.file = JSON.stringify(prescription.file);

    return await axios.post(url, prescription, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },

  update: async(id, prescription) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/prescriptions/${id}`;

    prescription.file = JSON.stringify(prescription.file);

    return await axios.put(url, prescription, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },


  remove: async(id) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/prescriptions/${id}`;

    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },



}