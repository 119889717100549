import React, { useEffect, useState } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import HomeButton from '../components/Home/HomeButton';
import HomeHeader from '../components/Home/HomeHeader';
import HomeImg from '../components/Home/HomeImg';
import CertEcommerce from '../components/Home/CertEcommerce';
import useOptions from '../hooks/Shared/useOptions';
import useShops from '../hooks/Home/useShops';
import useServices from '../hooks/Home/useServices';
import useEvents from '../hooks/Home/useEvents';
import SystemResource from '../resources/System';
import ShopsService from '../services/shops';
import { IonContent, IonGrid, IonRow, useIonToast } from '@ionic/react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';

const HomePage = (props) => {
  const [services, loadServices] = useServices();
  const [events, loadEvents] = useEvents();
  const [shops, selected, loadShops] = useShops();
  const [options, loadOptions] = useOptions();
  const version = `${SystemResource.apiversion}-${process.env.REACT_APP_VERSION}`;
  const { history } = props;
  const { slot } = props;
  const [present, dismiss] = useIonToast();

  const [showButton, setShowButton] = useState({
    search: true,
    category: true,
    prescription: true,
    promotions: true,
    galenica: false,
    services: false,
    events: false,
    orders: false,
    call: false,
    facebook: false,
    instagram: false,
    whatsapp: false,
    turni: false,
    // holter: true,
  });

  const buttons = {
    search: {
      name: 'search',
      type: 'img',
      text: 'Cerca',
      key: 1,
      action: () => history.push(`/app/search`),
      img: '/assets/button/search.png?v=' + version,
    },
    category: {
      name: 'category',
      type: 'img',
      text: 'Prodotti',
      key: 2,
      action: () => history.push(`/app/category`),
      img: '/assets/button/category.png?v=' + version,
    },
    prescription: {
      name: 'prescription',
      type: 'img',
      text: 'Ricetta',
      key: 3,
      action: () => history.push(`/app/prescription`),
      img: '/assets/button/prescription.png?v=' + version,
    },
    promotions: {
      name: 'promotions',
      type: 'img',
      text: 'Offerte',
      key: 4,
      action: () => history.push(`/app/promotions`),
      img: '/assets/button/promotions.png?v=' + version,
    },
    galenica: {
      name: 'galenica',
      type: 'img',
      text: 'Galenica',
      key: 5,
      action: () => history.push(`/app/galenica`),
      img: '/assets/button/galenica.png?v=' + version,
    },
    services: {
      name: 'services',
      type: 'img',
      text: 'Servizi',
      key: 6,
      action: () => history.push(`/app/services`),
      img: '/assets/button/services.png?v=' + version,
    },
    events: {
      name: 'events',
      type: 'img',
      text: 'Eventi',
      key: 14,
      action: () => history.push(`/app/events`),
      img: '/assets/button/eventi.png?v=' + version,
    },
    orders: {
      name: 'orders',
      type: 'img',
      text: 'Ordini',
      key: 7,
      action: () => history.push(`/app/events`),
      img: '/assets/button/orders.png?v=' + version,
    },
    call: {
      name: 'call',
      type: 'img',
      text: 'Chiama',
      key: 8,
      action: () => openCall(),
      img: '/assets/button/call.png?v=' + version,
    },
    facebook: {
      name: 'facebook',
      type: 'img',
      text: 'Facebook',
      key: 9,
      action: () => window.open('' + options?.facebook, '_system'),
      img: '/assets/button/facebook.png?v=' + version,
    },
    instagram: {
      name: 'instagram',
      type: 'img',
      text: 'Instagram',
      key: 10,
      action: () => window.open('' + options?.instagram, '_system'),
      img: '/assets/button/instagram.png?v=' + version,
    },
    whatsapp: {
      name: 'whatsapp',
      type: 'img',
      text: 'Whatsapp',
      key: 11,
      action: () => openChat(),
      img: '/assets/button/whatsapp.png?v=' + version,
    },
    turni: {
      name: 'turni',
      type: 'img',
      text: 'Turni',
      key: 12,
      action: () => window.open('' + options?.turni, '_system'),
      img: '/assets/button/turni.png?v=' + version,
    },
    // holter: {
    //   name: 'holter',
    //   type: 'img',
    //   text: 'Holter Cardiaco',
    //   key: 13,
    //   action: () => history.push(`/app/services/14`),
    //   img: '/assets/button/holter.png?v=' + version,
    // },
  };

  useIonViewWillEnter(() => {
    props.loader.current.show();
    loadOptions();
    loadShops();
    loadServices();
    loadEvents();
    props.loader.current.dismiss();
  });

  useEffect(() => {
    setShowButton({
      ...showButton,
      galenica: !!parseInt(options?.galenica),
      call: !!options?.call_number,
      facebook: !!options?.facebook,
      instagram: !!options?.instagram,
      whatsapp: !!options?.whatsapp_number,
      turni: !!options?.turni,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setShowButton({ ...showButton, services: !!services && services?.length > 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);
  useEffect(() => {
    setShowButton({ ...showButton, events: !!events && events?.length > 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const openCall = async () => {
    if (!!options?.call_number) {
      window.open(`tel:${options?.call_number}`, '_system');
    }
  };

  const openChat = async () => {
    if (!!options?.whatsapp_number && !!options?.whatsapp_text) {
      window.open(`https://wa.me/${options?.whatsapp_number}?text=${encodeURI(options?.whatsapp_text)}`, '_system');
    }
  };

  const saveShop = (val) => {
    const shop_id = { shop_id: val };

    const loadData = async () => {
      try {
        await ShopsService.update(shop_id);
        toast('Punto vendita aggiornato');
      } catch (error) {
        toast('Errore, non è stato possibile aggiornare il punto vendita associato');
      }
    };

    if (selected !== undefined && val !== selected) {
      loadData();
    }
  };

  const toast = (mess) =>
    present({
      buttons: [{ text: 'X', handler: () => dismiss() }],
      message: mess,
      duration: 1000,
    });

  return (
    <>
      <HomeHeader
        shopName={options?.shop_name}
        selected={selected}
        saveShop={saveShop}
        shops={shops}
        history={history}
        slot={slot}
      />
      <IonContent id="homepage" className="ion-no-padding">
        {!!options?.home_image && (
          <HomeImg homeImage={`${options?.home_image}?v=${version}`} shopName={options?.shop_name} />
        )}
        <IonCard className="ion-no-padding ion-align-items-start">
          <IonCardHeader className="ion-align-items-start">
            <IonCardTitle>Benvenuto in Farmacia</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {/* <IonCardSubtitle>I tuoi appuntamenti</IonCardSubtitle>
            Non hai servizi o eventi prenotati */}
          </IonCardContent>
        </IonCard>
        <IonGrid className="ion-no-padding ion-align-items-center">
          <IonRow className=" ion-align-items-center">
            {Object.values(buttons).map(
              (button) =>
                showButton[button.name] && (
                  <HomeButton
                    key={button.key}
                    type={button.type}
                    action={button.action}
                    img={button.img}
                    name={button.name}
                    text={button.text}
                  />
                )
            )}
          </IonRow>
        </IonGrid>

        {!!options?.ecommerce_link && !!options?.ecommerce_logo && (
          <CertEcommerce
            ecommerceLink={`${options?.ecommerce_link}`}
            ecommerceLogo={`${options?.ecommerce_logo}?v=${version}`}
            shopName={options?.shop_name}
          />
        )}
      </IonContent>
    </>
  );
};

export default HomePage;
