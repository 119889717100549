import React, { Component } from 'react';
import { Route } from 'react-router-dom';


import Wrapper from './Wrapper';


export default class PublicRoute extends Component {
  render() {
    const {component: Component, ...rest} = this.props;

    return (
      <Route {...rest} render={props => (
        <Wrapper {...rest} {...props} component={ Component } />
        )} />
    )
  }

}

