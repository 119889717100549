import React, { Component } from 'react';
import { IonLoading } from '@ionic/react';


export default class Loader extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      loaderProps: undefined,
      isOpen: false,
    }

    this.show = this.show.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  async show(...props) {
    await this.setState({ isOpen: true, loaderProps: props });
  }

  async dismiss() {
    await this.setState({ isOpen: false });
  }

  render() {
    return (
      <IonLoading
        isOpen={this.state.isOpen}
        message={'Attendere...'}
        duration={50000}
        {...this.state.loaderProps}
      />
    )
  }

}