import React, { Component } from 'react';

import { trashBin as trash } from 'ionicons/icons';
import {
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonLabel,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonThumbnail,
  IonImg,
  IonIcon,
} from '@ionic/react';

import ProductService from '../services/product';

import Price from '../components/Price';

import '../theme/container.css';

export default class CartProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: this.props.code,
      prod: undefined,
      amount: this.props.amount,
      quantity: this.props.quantity,
      total: this.props.total,
    };

    this.quantityProduct = this.quantityProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.goProductPage = this.goProductPage.bind(this);
  }

  async componentDidMount() {
    let prod = (await ProductService.get(this.props.code)).data;
    await this.setState({ prod });
  }

  async quantityProduct(event) {
    let quantity = event.target.value;
    await this.props.setProductQuantity(this.props.code, quantity);
  }

  async removeProduct() {
    await this.props.changeProductQuantity(this.props.code, -1);
  }

  async deleteProduct() {
    await this.props.deleteProduct(this.props.code);
  }

  async goProductPage() {
    this.props.history.push(`/app/product/${this.props.code}`);
  }

  render() {
    return (
      <>
        {!!this.state.prod && this.props.quantity > 0 ? (
          <IonItemSliding>
            <IonItemOptions side="start">
              <IonItemOption color="danger">
                <IonIcon slot="icon-only" icon={trash} />
              </IonItemOption>
            </IonItemOptions>

            <IonItem lines="full">
              <IonThumbnail slot="start">
                <IonImg
                  src={
                    this.state.prod.immagine && this.state.prod.immagine !== ''
                      ? `https://static.fogliettoillustrativo.net/ws/it/${this.state.prod.immagine}`
                      : `/assets/prodotto_generico_125.png`
                  }
                  onClick={this.goProductPage}
                />
              </IonThumbnail>

              <IonLabel className="ion-text-wrap ion-no-padding">
                <IonGrid>
                  <IonRow>
                    <IonCol size="9">
                      <IonText onClick={this.goProductPage}>
                        <p className="ion-text-capitalize">{this.state.prod.nome_prodotto}</p>
                      </IonText>
                      <small className="price">
                        {this.state.prod.quantita > 0 ? <span>Disponibile</span> : <span>Da ordinare</span>}
                      </small>
                    </IonCol>

                    <IonCol className="ion-text-right" size="3">
                      {/* <IonText color="dark"><strong>{ this.props.total.toFixed(2) } €</strong></IonText> */}
                      <IonText color="">
                        {/* PREZZO SCONTATO 
                        {this.state.prod.prezzo_bancadati <= this.state.prod.prezzo ? null : (
                          <>
                            <span className="mainprice">
                              <Price price={this.state.prod.prezzo_bancadati} />
                              <span className="euro-symbol">€</span>
                            </span>
                            &nbsp;
                          </>
                        )} */}
                        <br />
                        <Price price={this.props.total} />
                      </IonText>
                      <span className="euro-symbol">€</span>

                      <div>
                        <select
                          style={{ width: '90%', textAlign: 'center', backgroundColor: '#fff', borderRadius: '5px' }}
                          value={this.props.quantity}
                          placeholder="Quantità"
                          onChange={this.quantityProduct}
                        >
                          <option value="0">Rimuovi</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonLabel>
            </IonItem>
          </IonItemSliding>
        ) : null}
      </>
    );
  }
}
