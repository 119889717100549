import React, { Component } from 'react';

import { IonContent, IonHeader, IonToolbar, IonIcon, IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonSkeletonText, IonThumbnail, IonImg, IonText, IonInfiniteScroll, IonInfiniteScrollContent, IonBackButton, IonButtons, IonButton, IonSearchbar } from '@ionic/react';

import { cartOutline as cart } from 'ionicons/icons';

import SearchService from '../services/product';

import AuthResource from '../resources/Auth';
import CartResource from '../resources/Cart';

import Price from '../components/Price';

import '../theme/ionIconStack.scss'

export default class SearchPage extends Component {

  constructor(props) {

    super(props);

    this.state = {
      cat1: null,
      searchCategories: null,
      searchText: undefined,
      currentPage: 1,
      requestPerPage: 10,
      fetching: false,
      disabledInfiniteScroll: false,
      prods: [],
      messages: [],
    };

    this.setSearchText = this.setSearchText.bind(this);
    this.addProductToCart = this.addProductToCart.bind(this);
    this.infiniteScrollHandler = this.infiniteScrollHandler.bind(this);
  }

  async componentDidMount() {
    this.ionViewWillEnter();
  }

  async ionViewWillEnter() {
    if (this.state.cat1 === null) {
      const { data: searchCategories } = await SearchService.getCategory();
      await this.setState({ searchCategories: searchCategories.categories });
    }
  }

  async getSearch() {
    let prods = this.state.prods;

    await this.setState({ fetching: true });
    try {
      if (this.state.searchText.length > 2) {
        let search = await SearchService.search(this.state.searchText, this.state.currentPage, this.state.requestPerPage);
        
        prods = search.data.responses[0].hits.hits.map((e) => {
          return e._source;
        });
        
        prods = this.state.prods.concat(prods);
        
        if (this.state.currentPage >= search.data.totPages) {
          await this.setState({ disabledInfiniteScroll: true });
        }
      }
    } catch (error) {
      prods = this.state.prods;
    }
    await this.setState({ fetching: false });
    await this.setState({ prods: prods });

    return prods;
  }

  async setSearchText(searchText) {
    await this.setState({
      searchText,
      prods: [],
      currentPage: 1,
      disabledInfiniteScroll: false,
    });
    let prods = await this.getSearch();
    await this.setState({ prods });
  }

  async infiniteScrollHandler(e) {
    await this.setState({ currentPage: this.state.currentPage + 1 });
    let prods = await this.getSearch();
    await this.setState({ prods });
    e.target.complete();
  }

  async addProductToCart(prod, nome_prodotto = 'Prodotto') {
    if (!!!(await AuthResource.isLogged)) {
      this.props.toast.current.add({
        message: `Accedi o registrati`,
        color: 'warning',
      });
      this.props.history.push('/auth/login');
      return;
    }

    await CartResource.addProduct(prod);
    this.props.toast.current.add({
      message: `${nome_prodotto} aggiunto al carrello`
    });
  }


  render() {
    const { searchCategories } = this.state;

    const renderCategory = (category) => {
      
      return (

          
            <IonRow key={category}>
              <IonCol size="12">
                <IonButton key={category} expand="block" color="light" onClick={() => this.props.history.push(`/app/category/` + category)}>
                  {category.charAt(0).toUpperCase() + category.slice(1)} 
                </IonButton>
              </IonCol> 
              {/* <IonCol size="6">
                <IonButton key={category} expand="block" color="light" onClick={() => this.props.history.push(`/app/category/` + category)}>
                  {category.charAt(0).toUpperCase() + category.slice(1)} 
                </IonButton>
              </IonCol>  */}
            </IonRow> 
          

      );
    };

    return (
      <>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>

            <IonSearchbar autofocus="true" className="ion-no-padding"
              placeholder="Cerca..."
              debounce={500}
              onIonChange={e => this.setSearchText(e.detail.value)}
            />
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            {this.state.prods.length > 0 ? (
              this.state.prods.map((prod, key) => (
                <IonItem key={key} >
                  <IonThumbnail slot="start">
                    <IonImg
                      src={(prod.immagine && prod.immagine !== "")
                        ? `https://static.fogliettoillustrativo.net/ws/it/${prod.immagine}`
                        : `/assets/prodotto_generico_125.png`
                      }
                      onClick={() => this.props.history.push(`/app/product/${prod.codice_ministeriale}`)}
                    />
                  </IonThumbnail>

                  <IonLabel onClick={() => this.props.history.push(`/app/product/${prod.codice_ministeriale}`)}>
                    <h4 className="ion-text-capitalize">{prod.nome_prodotto}</h4>
                    <p className="ion-text-capitalize">{prod.descrizione_ditta_1_produttrice}</p>
                    <p className="price">
                      <IonText color="success">
                        <Price price={prod.prezzo} />
                        <span className="euro-symbol">€</span>
                      </IonText>
                      {/* PREZZO SCONTATO
                      {prod.prezzo_bancadati <= prod.prezzo
                        ? null
                        : <>
                          &nbsp;
                          <span className="mainprice">
                            <Price price={prod.prezzo_bancadati} />
                            <span className="euro-symbol">€</span>
                          </span>
                        </>
                      }

                      {(prod.prezzo_bancadati > 0 && (Math.round((prod.prezzo_bancadati - prod.prezzo) / prod.prezzo_bancadati * 100) > 5)) && (
                        <IonText color="danger">
                          &nbsp;
                          Sconto {Math.round((prod.prezzo_bancadati - prod.prezzo) / prod.prezzo_bancadati * 100)}%
                        </IonText>
                      )} */}
                      &nbsp;
                      {prod.quantita > 0
                        ? <span>Disponibile</span>
                        : <span>Da ordinare</span>
                      }
                    </p>
                  </IonLabel>

                  <IonButton slot="end" color="light" onClick={() => this.addProductToCart(prod.codice_ministeriale, prod.nome_prodotto)} size="default">
                    <IonIcon slot="icon-only" icon={cart} />
                  </IonButton>
                  </IonItem>
              ))
            ) : (
              <>
                <div key="" className="ion-margin ion-padding">
                  <h1>Cerca il tuo prodotto</h1>
                  <p>
                    Cerca inserendo il Nome o il Codice del prodotto, oppure naviga attraverso le seguenti categorie e scorri l'elenco dei risultati.
                    <br /> <br />
                    </p>
                    <IonGrid>
                      {searchCategories ? searchCategories.map((category) => renderCategory(category)) : null}
                    </IonGrid>
                    <p>
                    Premi sul <IonIcon slot="icon-only" icon={cart} /> per aggiungere il prodotto al tuo carrello degli acquisti
                    <br /> <br />
                    <br /> <br />
                    </p>

                  <ul>
                    <li key="1" >Non conosci il nome del prodotto?</li>
                    <li key="2" >Hai difficolta nella ricerca? </li>
                    <li key="3" >Hai una richiesta particolare? </li>
                  </ul>

                  <br />
                  <IonButton expand="block" color="light" onClick={() => this.props.history.push(`/app/productPhoto`)} size="default">
                    Compila la tua richiesta
                  </IonButton>
                  
                </div>
              </>
            )}

            {!!this.state.fetching && (
              <>
                {[...Array(this.state.requestPerPage).keys()].map((e, key) => (
                  <IonItem key={key}>
                    <IonThumbnail slot="start">
                      <IonSkeletonText animated />
                    </IonThumbnail>
                    <IonLabel>
                      <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
                      <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
                      <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
                    </IonLabel>
                  </IonItem>
                ))}
              </>
            )}

            <IonInfiniteScroll
              disabled={this.state.disabledInfiniteScroll}
              onIonInfinite={this.infiniteScrollHandler}
            >
              <IonInfiniteScrollContent loadingSpinner={null} />
            </IonInfiniteScroll>

          </IonList>
        </IonContent>

        <IonToolbar className="ion-no-padding ion-padding-start ion-padding-end">
          <IonButton expand="block" color="light" onClick={() => this.props.history.push(`/app/productPhoto`)} size="default">
            Non trovi il prodotto?
          </IonButton>
        </IonToolbar>
      </>
    );
  }
}
