import React, { Component } from 'react';

import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton} from '@ionic/react';
import OptionService from '../services/option';


export default class PrivacyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacy: ''
    };

  }
  async componentDidMount() {
    try {
      let [ 
        {value: {data: {value: privacy, updated_at}}}, 
      ] = await Promise.allSettled([
        OptionService.get('privacy'),
      ]);
      this.setState({ privacy });
      this.setState({ updated_at });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>

        <IonHeader>
         <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>Privacy</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-margin ion-padding">
          <div  dangerouslySetInnerHTML={{__html: this.state.privacy}} />
          <p>Ultima modifica {this.state.updated_at }</p>
        </IonContent>

      </>
    );
  }
}