import React from 'react'
import { IonIcon } from '@ionic/react';
import { IonItem, IonList, IonLabel } from '@ionic/react';
import { IonGrid } from '@ionic/react';
import { caretDownCircleOutline as caretDown, caretBackCircleOutline as caretBack } from 'ionicons/icons';
import InfoDetailsSideProduct from './InfoDetailsSideProduct'

function InfoSideProduct({searchService, toggleDescription, setToggleDescription}) {
  return (
    
    <IonList>

        <IonItem lines="full" onClick={ () => {setToggleDescription(!toggleDescription)} }>
            <IonLabel>Informazioni prodotto</IonLabel>
            <IonIcon slot="end" icon={ !!toggleDescription ? caretDown : caretBack } />
        </IonItem>

        { !!toggleDescription &&
        <IonGrid>
            <InfoDetailsSideProduct desc={'Descrizione: '} data={searchService?.descrizione.toLowerCase()} />
            <InfoDetailsSideProduct desc={'Ditta produttrice: '} data={searchService?.descrizione_ditta_1_produttrice?.toLowerCase()} />
            <InfoDetailsSideProduct desc={'ATC: '} data={searchService?.descrizione_atc?.toLowerCase()} />
            <InfoDetailsSideProduct desc={'Altre info: '} data={searchService?.descrizione_estesa_degrassi?.toLowerCase()} />
        </IonGrid>
        }

    </IonList>


  )
}

export default InfoSideProduct