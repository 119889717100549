import axios from './axios';
import auth from '../resources/Auth';

export default {

  get: async(id) => {
    let url = `${process.env.REACT_APP_API_URL}/shopservicegroup/${id}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },
}