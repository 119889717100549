import React, { Component } from 'react';

import moment from 'moment';
import localization from 'moment/locale/it';

import { closeOutline as close, card } from 'ionicons/icons';
import {
  withIonLifeCycle,
  IonText,
  IonRow,
  IonGrid,
  IonCol,
  IonModal,
  IonContent,
  IonHeader,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonItemDivider,
} from '@ionic/react';

import PaymentService from '../../services/payment';
import OrderService from '../../services/order';
import HeaderButtons from '../../components/HeaderButtons';
import Price from '../../components/Price';

import '../../theme/container.css';
import './payment.css';

class PayPalPage extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      id: this.props.match.params.id,
      errors: [],
      disabled: true,
      order: undefined,
      paymentCreate: undefined,
    };
  }

  async ionViewWillEnter() {
    await this.setState({ id: this.props.match.params.id });
    const { data: order } = await OrderService.get(this.state.id);

    if (!!!order.id) {
      this.props.history.push(`/app/orders`);
      return;
    }

    if (
      !!!order.payment || //vero se payment è null
      !!!order.payment.valid ||
      order.payment.valid !== 1
    ) {
      order.payment = {};
      order.payment.valid = 0;
    }

    this.setState({ order });
    if (order.payment.valid === 1) {
      this.props.history.push(`/app/orders/${this.state.id}`);
    }

    if (order.paymentType === 'paypal') {
      try {
        this.props.loader.current.show();
        const { data: paymentCreate } = await PaymentService.create('paypal', this.state.id);

        if (!!!paymentCreate.error.code) {
          throw new Error(`Risposta del server non valida`);
        }

        if (paymentCreate.error.code !== '0') {
          throw new Error(`${paymentCreate.error.description} E${paymentCreate.error.code}`);
        }

        if (!!!paymentCreate.submit) {
          throw new Error(`Risposta del server incompleta`);
        }

        await this.setState({ paymentCreate });
        await this.setState({ disabled: false });
      } catch (error) {
        this.props.toast.current.add({
          message: `Si è verificato un errore: ${error}. Verifica i dati inseriti e riprova.`,
          color: 'warning',
        });

        await this.setState({ disabled: true });
      } finally {
        this.props.loader.current.dismiss();
        this.setState({ disabled: false });
      }
    }
  }

  render() {
    let { order } = this.state;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref={`/app/orders/${this.state.id}`} />
            </IonButtons>
            <HeaderButtons {...this.props} />
            <IonTitle>Pagamento con PayPal</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {!!order &&
          !!this.state.paymentCreate &&
          !!this.state.paymentCreate.submit &&
          !!this.state.paymentCreate.payload &&
          order.status === 'finalizing' && //ordine in attesa di conferma
          order.payment.valid !== '1' ? ( //pagamento non eseguito
            <>
              <IonItemDivider>
                <IonLabel>Dati di acquisto</IonLabel>
              </IonItemDivider>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText>
                      Ordine N° {this.state.order.id} del{' '}
                      {moment(order.created_at).locale('it', localization).format('lll')}
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText>Sub totale: </IonText>
                    <IonText className="ion-text-medium">
                      <Price price={order.subTotal} />
                    </IonText>
                    <span className="euro-symbol">€ </span>

                    <IonText color="medium" className="ion-text-small">
                      ({order.totalQuantity} articoli)
                    </IonText>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonText>Spedizione: </IonText>
                    <IonText className="ion-text-medium">
                      <Price price={order.shippingMethod.price} />
                    </IonText>
                    <span className="euro-symbol">€ </span>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <b>
                      <IonText>Totale: </IonText>
                      <IonText color="dark">
                        <Price price={order.total} />
                      </IonText>
                      <span className="euro-symbol">€ </span>
                    </b>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonItemDivider>
                <IonLabel></IonLabel>
              </IonItemDivider>

              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <IonButton
                      expand="block"
                      color="success"
                      onClick={() => (window.location.href = this.state.paymentCreate.submit)}
                      disabled={this.state.disabled}
                      className="ion-margin-horizontal "
                    >
                      <IonIcon icon={card} />
                      &nbsp;
                      <IonLabel>Esegui Pagamento </IonLabel>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </>
          ) : (
            <div className="ion-margin ion-padding">
              <div className="ion-text-center ion-padding-vertical">
                <p>Non è possibile eseguire il pagamento di questo ordine.</p>
                <p>L'ordine è già stato pagato oppure è in uno stato che non può ricevere pagamenti</p>
                <IonButton
                  color="light"
                  expand="block"
                  onClick={() => this.props.history.push(`/app/orders/${this.state.id}`)}
                >
                  Vedi ordine
                </IonButton>
              </div>
            </div>
          )}
        </IonContent>

        <IonModal isOpen={this.state.paymentModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => this.closePaymentModal()}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
              <IonTitle>Pagamento in corso</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <iframe
              title="Pagamento in corso"
              style={{ width: '100%', height: '100%' }}
              src={this.state.iframeurl}
            ></iframe>
          </IonContent>
        </IonModal>

        <IonFooter className="ion-padding"></IonFooter>
      </>
    );
  }
}

export default withIonLifeCycle(PayPalPage);
