import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel } from '@ionic/react';
import { IonButtons, IonButton, IonBackButton, IonIcon } from '@ionic/react';
import { IonList, IonGrid, IonRow, IonCol } from '@ionic/react';

import { addOutline as add, starOutline as start } from 'ionicons/icons';

import AddressService from '../services/address';



class AddressesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: [],
    };
    
    this.getAddresses = this.getAddresses.bind(this);
  }


  ionViewWillEnter() {
    this.getAddresses();
  }

  async getAddresses() {
    let {data: {data: addresses}} = await AddressService.get();
    await this.setState({ addresses });
  }
  

  render() {
    return (
      <>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/auth/login" />
            </IonButtons>

            <IonTitle>Indirizzi</IonTitle>

          </IonToolbar>
        </IonHeader>

        <IonContent>

          <IonList>

            {
              !!this.state.addresses.length
                ? <>
                    {
                      this.state.addresses
                        .filter(address => address.system === false)
                        .map(address => 
                        <IonItem key={ address.id } detail button onClick={ () => { this.props.history.push(`/app/address/${address.id}`) } }>
                          <IonLabel>
                            <IonGrid>
                              <IonRow>
                                <IonCol><p><strong>{ address.name }</strong></p></IonCol>
                                {
                                  !!address.default
                                    ? <IonCol className="ion-text-right" size="1">
                                        <IonIcon icon={ start } />
                                      </IonCol>
                                    : null
                                }                                
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <p>{ address.address_1 }</p>
                                  <p>{ address.address_2 }</p>
                                  <p>{ address.city }, { address.state_region } { address.postal_code }</p>
                                  { !!address.phone_number ? <p>{ `Numero di telefono: ${address.phone_number }` }</p>: null }
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                            
                            
                          </IonLabel>
                        </IonItem>
                      )
                    }
                  </>
                : <IonItem>
                    <IonLabel>
                      <p>Non sono presenti idirizzi</p>
                    </IonLabel>
                  </IonItem>
            }

            

            

            <div className="ion-padding" >
              <IonButton expand="full" onClick={ () => this.props.history.push('/app/address') }>
                <IonIcon slot="start" icon={ add } /> Aggiungi indirizzo
              </IonButton>
            </div>
          </IonList>
          
        </IonContent>

      </>
    );
  }
}

export default withIonLifeCycle(AddressesPage);
