import React from 'react';

import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton} from '@ionic/react';

import Profile from '../components/Profile'

const ProfilePage = (props) => {
  const {toast} = props;

  const onSubmit = async (error) => {
    if (!!error) {
      let response = error.response.data.error;
      if (!(response instanceof Object)) {
        toast.current.add({
          message: `Impossibile aggiornare`,
          color: 'warning',
        });
      }
      return;
    }

    toast.current.add({
      message: `Profilo aggiornato`,
    });
  }

  return(
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Profilo</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <Profile onSubmit={ onSubmit } />
      </IonContent>
    </>
  )

}

export default ProfilePage;