import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons, IonBackButton, IonButton, IonIcon } from '@ionic/react';

import { IonList, IonItem, IonLabel, IonText, } from '@ionic/react';
import { IonTextarea } from '@ionic/react';


import { helpCircleOutline as help} from 'ionicons/icons';
import { addCircleOutline as add, saveOutline as save, trashBinOutline as remove } from 'ionicons/icons';


import ProductRequestService from '../services/product_request';
import File from '../components/File/File';


class ProductPhoto extends Component {

  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.files = React.createRef();
    
    this.state = {
      id: this.props.match.params.id,
      type: 'search',
      note: undefined,
      note_required: true,
      files_required: false,
      files: [],
      errors: {},
    };

    this.fileHandler = this.fileHandler.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.setProductRequest = this.setProductRequest.bind(this);
    this.removeProductRequest = this.removeProductRequest.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    
  }

  async ionViewWillEnter() {
    this.setState({
      id: this.props.match.params.id,
      type:'search',
      note: undefined,
      note_required: true,
      files_required: false,
      files: [],
      errors: {},
      disabled: true,
    });
    
    if (!!!this.state.id) {
      this.files.current.input.current.value = "";
      return;
    }

    let {data: { id, type, note, equivalent, file, }} = await ProductRequestService.get(this.state.id);

    if (!!file && !!file.name) {
      this.files.current.input.current.value = file.name;
    }

    await this.setState({
      id,
      type,
      note,
      equivalent,
      file,
      files: !!file ? [file] : [],
    });

    await this.handleFormValidation();
  }

  async fileHandler(files, event) {
    await this.setState({ files: files });
    await this.handleValidation(event);
    await this.handleFormValidation();
  }

  async textHandler(event) {
    await this.setState({ [event.target.name]: event.detail.value });
    await this.handleValidation(event);
    await this.handleFormValidation();
  }

  async removeFile(key) {
    let files = this.state.files;

    files = files.filter((f, i) => i!== key);
    this.files.current.input.current.value = "";
    this.setState({ files, files_required: true, file:undefined });
    await this.handleFormValidation();
  }

  async handleValidation(event) {
    let errors = {};
    let error = false; 

    if (
      event.target &&
      event.target.firstChild &&
      event.target.firstChild.validationMessage
    ) {
      error = event.target.firstChild.validationMessage;
    }

    if (
      event.firstChild &&
      event.firstChild.validationMessage
    ) {
      error = event.firstChild.validationMessage;
    }

    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];
    if (error) errors[name] = error;
    await this.setState({ errors });
    return false;
  }

  async handleFormValidation(e) {
    if (this.form.current.checkValidity()) {
      this.setState({ disabled: !this.form.current.checkValidity() });
      return true;
    }

    return false;
  }

  async removeProductRequest() {
    await ProductRequestService.remove(this.state.id);
    if (this.props.onSubmit && (typeof this.props.onSubmit === 'function')) {
      this.props.onSubmit('remove');
    }

    this.props.history.push('/app/cart');
    this.props.toast.current.add({ message: `Prodotto rimosso` });
  }

  async setProductRequest(e) {
    e.preventDefault();
    if (!this.handleFormValidation()) return;
    try{  
      this.props.loader.current.show();
      this.setState({ disabled: true });

      let product_request = {
        type: 'search',
        note: this.state.note,
        file: this.state.files.pop(),
      };

      if (this.state.id) {      
        await ProductRequestService.update(this.state.id, product_request);
        this.props.toast.current.add({ message: `Prodotto aggiornato` });
      } else {
        await ProductRequestService.create(product_request);
        this.props.toast.current.add({ message: `Prodotto aggiunto` });
      }

      this.props.history.push('/app/cart');
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    } finally {
      this.props.loader.current.dismiss();
      this.setState({ disabled: false });
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/cart" />
            </IonButtons>

            <IonTitle>Ricerca prodotto</IonTitle>

            <IonButtons slot="end">
              <IonButton href="/app/help/productphoto">
                <IonIcon icon={help} />
              </IonButton>     
            </IonButtons>

          </IonToolbar>
        </IonHeader>

        <IonContent>
          <form ref={this.form} onSubmit={this.setProductRequest}>
            <p className="ion-padding-top ion-padding-start ion-padding-end">
              Attraverso questo modulo puoi richiedere al Farmacista di cercare un prodotto inviando una nota o una fotografia<br/>
              Quando il farmacista avrà individuato il prodotto che cerchi sarai ricontattato.               
            </p>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem lines="none">
                  <IonLabel color={!!errors.files ? 'danger' : null} position="">
                  <p className="">Foto del prodotto
                  {this.state.files_required ? <IonText color="danger">*</IonText> : null}</p>
                  </IonLabel>
              </IonItem>
            </IonList>
            <IonList>
              {this.state.files.map((file, key) => 
                <IonItem key={ key }>
                  <IonLabel className="ion-padding-start">{ file.name }</IonLabel>
                  <IonIcon icon={remove} slot="end" onClick={() => this.removeFile(key)} />                  
                </IonItem>
              )}             
              <IonItem lines={!!errors.files ? 'none' : null}>
                <File onChange={this.fileHandler} name="files" ref={this.files} required={this.state.files_required} />
              </IonItem>
              {!!errors.files && (
                <IonItem>
                  <IonText color="danger">
                  {errors.files}
                  </IonText>
                </IonItem>
              )}    
            </IonList>
            <IonItem>
              <IonLabel position="stacked">Note{this.state.note_required ? <IonText color="danger">*</IonText> : null}</IonLabel>
              <IonTextarea 
                type="text"
                name="note"
                required={this.state.note_required}
                placeholder="Indica qui i codici presenti sulla scatola, il nome del prodotto o altre informazioni utili" 
                onIonChange={this.textHandler}
                value={this.state.note} 
              />
              <small className="ion-padding-bottom">Inserisci informazioni che saranno utili al Farmacista per riconoscere il prodotto che cerchi </small>
            </IonItem>
                
            <div className="ion-padding">
              {!!this.state.id && (
                <IonButton expand="block" color="warning" className="ion-margin-bottom" onClick={this.removeProductRequest}>
                  <IonIcon slot="start" icon={remove} /> Cancella
                </IonButton>
              )}

              <IonButton 
                expand="block"
                type="submit"
                disabled={ this.state.disabled }
                onClick={ this.handleFormValidation }
              >
              {!!this.state.id
                ? <><IonIcon slot="start" icon={save} /> Aggiorna</>
                : <><IonIcon slot="start" icon={add} /> Carica</>
              }
              </IonButton>
            </div>
          </form>
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(ProductPhoto);
