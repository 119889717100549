import React from 'react';

import { IonGrid, IonRow, IonCol } from '@ionic/react';
const css = { width: '110px', maxWidth: '500px' };

const CertEcommerce = ({ ecommerceLink, ecommerceLogo, shopName }) => {
  return (
    <IonGrid className="ion-no-padding">
      <IonRow className="ion-margin-bottom ion-no-padding">
        <IonCol className="ion-text-center ion-no-padding">
          <a href={ecommerceLink} target="_blank" rel="noopener noreferrer">
            <img src={ecommerceLogo} alt={shopName} style={css} />
          </a>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default CertEcommerce;
