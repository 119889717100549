import React from 'react';
import QueryString from 'query-string';
import { IonContent, IonHeader, IonToolbar, IonLabel, IonTitle, IonIcon, IonList, IonItem, useIonViewWillEnter } from '@ionic/react';
// import ShopsService from '../services/shops';
import useShops from '../hooks/Home/useShops';
import UserButton from '../components/User/UserButton';

import {
  personOutline as person,
  logOutOutline as logout,
  homeOutline as address,
  helpOutline as help,
  cardOutline as card,
  personAddOutline as personAdd,
  lockClosedOutline as lockClosed,
  listOutline as list,
  calendarOutline as calendar,
  mailUnreadOutline as mail,
  folderOutline as folder,
  checkboxOutline
} from 'ionicons/icons';

import AuthResource from '../resources/Auth';
import SystemResource from '../resources/System';

const UserPage = (props) => {
  const [shops, loadShops] = useShops()

  const version = SystemResource.apiversion + '-' + process.env.REACT_APP_VERSION;

  const buttons = [
    {key: 1, action: '/app/profile', title: 'Profilo', icon: person, name: null },
    {key: 2, action: '/app/fiscalcodes', title: 'Codice fiscale', icon: folder, name: null },
    //{key: 3, action: '/app/shops', title: 'Punti vendita', icon: folder, name: 'shops' },
    {key: 4, action: '/app/orders', title: 'Ordini', icon: list, name: null },
    {key: 5, action: '/app/reservations', title: 'Prenotazioni', icon: calendar, name: null },
    {key: 6, action: '/app/fidelityCard', title: 'Carta Fedeltà', icon: card, name: null },
    {key: 7, action: '/app/addresses', title: 'Indirizzi', icon: address, name: null },
    {key: 8, action: '/app/settings', title: 'Notifiche', icon: mail, name: null },
    {key: 9, action: '/app/referrer', title: 'Invita un Amico', icon: personAdd, name: null },
    {key: 10, action: '/app/faq', title: 'Domande Frequenti', icon: help, name: null },
    {key: 11, action: '/app/privacy', title: 'Informativa Privacy', icon: lockClosed, name: null },
    {key: 12, action: '/app/privacycheck', title: 'Gestisci consensi privacy', icon: checkboxOutline, name: null },
    {key: 13, action: '/app/tos', title: 'Termini d\'utilizzo', icon: lockClosed, name: null },
  ]  

  const logoutHandler = async () => {
    try {
      await AuthResource.logout();
    } catch (error) {
      console.warn(error);
    }

    const { redirect } = QueryString.parse(props.location.search);
    if (!!redirect) {
      return props.history.replace(redirect);
    }

    props.history.replace('/auth/login');
  }

  const updateApp = () => {
    window.location.reload();
  }

  useIonViewWillEnter(() => {
    // const loadData = async () => {
    //   const {data} = await ShopsService.get();
    //   setShops(data.data);
    // };
    // loadData();
    if (typeof loadShops === "function") {
      loadShops()
    }
  });

  return (
    <>
      <IonHeader mode="md">
        <IonToolbar>
          <IonTitle>Accedi</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList>

        { buttons.map( button => (
          (button.name === 'shops' && shops?.length < 1) ? (
            ''
            ) : (
            <UserButton key={button.key} action={button.action} title={button.title} icon={button.icon} history={props.history} />
          )
        )) }

          <IonItem onClick={logoutHandler}>
            <IonLabel>Esci</IonLabel>
            <IonIcon slot="end" icon={logout} />
          </IonItem>
          <br />
          <IonItem onClick={updateApp}>
            <small slot="end" >Versione: {version}</small>
          </IonItem>
        </IonList>
      </IonContent>
    </>
  );
}

export default UserPage;


