import React from 'react'
import { cartOutline as cart } from 'ionicons/icons';
import { IonIcon, IonButton } from '@ionic/react';

function AddCartSideProduct({addProductToCart, codice_ministeriale, nome_prodotto}) {
  return (
    <IonButton expand="block" size="large" className="ion-margin-top ion-margin-bottom" 
                onClick={ () => addProductToCart(codice_ministeriale, nome_prodotto)} >
        <IonIcon slot="start" icon={ cart } /> Aggiungi al carrello
    </IonButton>
  )
}

export default AddCartSideProduct