import axios from './axios';

export default {
  
  get: async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/address${!!id ? `/${id}` : ''}`;
    let token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  create: async (address) => {
    let url = `${process.env.REACT_APP_API_URL}/address`;
    let token = localStorage.getItem('auth');

    return await axios.post(url, address, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  update: async (id, address) => {
    let url = `${process.env.REACT_APP_API_URL}/address/${id}`;
    let token = localStorage.getItem('auth');

    return await axios.put(url, address, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  remove: async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/address/${id}`;
    let token = localStorage.getItem('auth');

    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },


}