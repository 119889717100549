import React from 'react'
import { IonItem, IonLabel, IonThumbnail, IonSkeletonText } from '@ionic/react';

const Skeleton = () => {
  return (
    <>      
      <IonItem>
        <IonThumbnail slot="start">
          <IonSkeletonText animated />
        </IonThumbnail>
        <IonLabel>
          <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
          <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
          <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
        </IonLabel>
      </IonItem>
      <IonItem>
      <IonThumbnail slot="start">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonLabel>
        <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
        <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
        <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
      </IonLabel>
    </IonItem>
      <IonItem>
      <IonThumbnail slot="start">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonLabel>
        <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
        <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
        <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonThumbnail slot="start">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonLabel>
        <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
        <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
        <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonThumbnail slot="start">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonLabel>
        <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
        <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
        <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonThumbnail slot="start">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonLabel>
        <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
        <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
        <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonThumbnail slot="start">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonLabel>
        <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
        <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
        <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonThumbnail slot="start">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonLabel>
        <h4><IonSkeletonText animated style={{ width: '50%' }} /></h4>
        <p> <IonSkeletonText animated style={{ width: '80%' }} /></p>
        <p> <IonSkeletonText animated style={{ width: '60%' }} /></p>
      </IonLabel>
    </IonItem>

    </>
  )
}

export default Skeleton