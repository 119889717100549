import React from 'react';

import { 
    IonSelect,
    IonSelectOption,
  } from '@ionic/react';
  
const SelectShops = ({selected, saveShop, shops}) => {

    return (
        <IonSelect value={selected?.id} placeholder="Punto vendita" onIonChange={ async (e) => { saveShop(e.detail.value) }}>
            {shops.map( shop => (
            <IonSelectOption selected key={shop.id} value={shop.id}>{shop.name}</IonSelectOption> 
            ))}
        </IonSelect> 
    );

}

export default SelectShops