import firebase from 'firebase/app';
import '@firebase/messaging';
import '@firebase/analytics';

let messaging;

const config = {
  apiKey: "AIzaSyBhHddFvAC63ni5rIij8hWGL1Cz1uFMV04",
  authDomain: "farmacia-salaris.firebaseapp.com",
  databaseURL: "https://farmacia-salaris.firebaseio.com",
  projectId: "farmacia-salaris",
  storageBucket: "farmacia-salaris.appspot.com",
  messagingSenderId: "320564115759",
  appId: "1:320564115759:web:06e42938a6721e8acf09d",
  measurementId: "G-W532PPJRXE"
};

firebase.initializeApp(config);

if(firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey('BCIcpVJcPVDSxExOYUQwPJNSCta3o-j1OiO9PqzK4KKYfvmaGndu6-PDI7qhRlJVGhiNnuhMQhJe4Oora68gxRY');
}

export {
  messaging
};