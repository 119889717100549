import React, { Component } from 'react';

import { find } from 'lodash';
import QueryString from 'query-string';

import { closeOutline as close, addOutline as add, trashBin as trash } from 'ionicons/icons';
import {
  withIonLifeCycle,
  IonText,
  IonRow,
  IonGrid,
  IonCol,
  IonIcon,
  IonContent,
  IonHeader,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonInput,
  IonButtons,
  IonBackButton,
  IonButton,
  IonCheckbox,
  IonTextarea,
} from '@ionic/react';

import AddressService from '../../services/address';
import OptionService from '../../services/option';
import PaymentService from '../../services/payment';

import Price from '../../components/Price';
import HeaderButtons from '../../components/HeaderButtons';

import CartResource from '../../resources/Cart';

import '../../theme/container.css';
import './checkout.css';

class CheckoutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voucherCode: undefined,
      voucherBtn: true,
      cart: undefined,
      prescriptions: [],
      product_requests: [],
      totgal: 0,
      totsearch: 0,
      addresses: [],
      car_note: '',

      shippingType: undefined,
      shippingAddress: undefined,
      shippingModal: false,

      paymentType: undefined,
      paymentModal: false,
      paymentMethods: undefined,

      shippingMethod: undefined,
      shippingMethodsModal: false,

      note: undefined,

      shipping_configs: {
        inShop: false,
        vendingMachine: false,
        pickupPoint: false,
        address: false,
      },
    };

    this.loadCart = this.loadCart.bind(this);
    this.setVoucher = this.setVoucher.bind(this);
    this.deleteVoucher = this.deleteVoucher.bind(this);
    this.setShippingType = this.setShippingType.bind(this);
    this.setShippingMethod = this.setShippingMethod.bind(this);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.checkout = this.checkout.bind(this);
  }

  async ionViewWillEnter() {
    try {
      let [
        {
          value: {
            data: { value: cart_note },
          },
        },
      ] = await Promise.allSettled([OptionService.get('cart_note')]);

      this.setState({ cart_note });
    } catch (error) {
      //console.error(error);
    }
  }

  async ionViewDidEnter() {
    const { loader, match, location, history } = this.props;

    if (!!loader) loader.current.show();
    try {
      let {
        data: { value: shipping_configs },
      } = await OptionService.get('order_shipping_configurations');

      await this.setState({ shipping_configs: JSON.parse(shipping_configs) });
    } catch (error) {
      console.error(error);
    }

    const [
      {
        value: {
          data: { data: addresses },
        },
      },
    ] = await Promise.allSettled([await AddressService.get(), await this.loadCart()]);

    try {
      const { data: paymentMethods } = await PaymentService.get(match.params.id);
      await this.setState({ paymentMethods });
    } catch (error) {
      console.error(error);
    }

    try {
      await this.setState({
        addresses,
        paymentType: this.state.cart.paymentType,
      });
    } catch (error) {
      console.error(error);
    }

    try {
      let { action } = QueryString.parse(location.search);

      switch (action) {
        case 'shipping':
          await this.setState({ shippingModal: true });
          history.replace('/app/checkout');
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }

    try {
      /* if (!!!this.state.paymentType) {
        await this.setPaymentMethod({
          detail: { checked: true },
          target: { type: 'payOnDelivery' },
        });
      } */
    } catch (error) {
      console.error(error);
    } finally {
      loader.current.dismiss();
    }
  }

  async loadCart() {
    if (!!this.props.loader) this.props.loader.current.show();
    try {
      let { data: cart } = await CartResource.current();
      let shippingAddress = undefined;
      if(cart.shippingType === 'pickupPoint'){
        shippingAddress = cart.pickuppoint;
      }else if(cart.shippingType === 'address'){
        shippingAddress = cart.address;
      }
      await this.setState({
        cart,
        prescriptions: cart.prescriptions,
        product_requests: cart.product_requests,
        totgal: cart.product_requests.filter((x) => x.type === 'galenica').length,
        totsearch: cart.product_requests.filter((x) => x.type === 'search').length,
        shippingType: cart.shippingType,
        shippingAddress: shippingAddress,
      });

      if (!!cart.shippingMethod) {
        let shippingMethod = this.state.shipping_configs[this.state.shippingType].methods.find(
          (method) => method.type === cart.shippingMethod.type
        );

        await this.setState({ shippingMethod });
      }

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async setVoucher() {
    try {
      if (!!this.state.voucherCode) {
        await this.setState({ voucherBtn: false });
        await CartResource.addVoucher(this.state.voucherCode);
        this.props.toast.current.add({ message: `Codice sconto inserito` });
      }
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Si è verificato un errore`,
        color: 'warning',
      });
    } finally {
      this.loadCart();
      await this.setState({ voucherCode: undefined });
      await this.setState({ voucherBtn: true });
    }
  }

  async deleteVoucher(voucher_id) {
    try {
      await CartResource.deleteVoucher(voucher_id);
      await this.setState({ voucher_id });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadCart();
      await this.setState({ voucherCode: undefined });
      await this.setState({ voucherBtn: true });
      await this.setState({ voucherModal: false });
    }
  }

  async setShippingType(event) {
    if (!!!event.detail.checked) return;

    const type = event.target.getAttribute('type');
    let address = event.detail.value;

    if (!!address) address = find(this.state.addresses, { id: parseInt(address) });
    try {
      switch (type) {
        case 'inShop':
          await CartResource.update({ shippingType: type });
          await this.setState({ shippingType: type, shippingMethod: undefined, shippingAddress: undefined });
          break;

        case 'vendingMachine':
          await CartResource.update({ shippingType: type });
          await this.setState({ shippingType: type, shippingMethod: undefined, shippingAddress: undefined });
          break;

        case 'pickupPoint':
          await CartResource.update({
            shippingType: type,
            address: address.id,
          });

          await this.setState({ shippingType: type, shippingMethod: undefined, shippingAddress: address });
          break;

        case 'address':
          await CartResource.update({
            shippingType: type,
            address: address.id,
          });

          await this.setState({ shippingType: type, shippingMethod: undefined, shippingAddress: address });
          break;

        default:
          break;
      }

      await this.setState({ paymentType: undefined });
    } catch (error) {
      console.error(error);
    } finally {
      await this.setState({ shippingModal: false });
      this.loadCart();
    }
  }

  async setShippingMethod(event) {
    if (!!!event.detail.checked) return;
    try {
      const type = event.target.getAttribute('type');
      const shippingMethod = this.state.shipping_configs[this.state.shippingType].methods.find(
        (method) => method.type === type
      );

      await CartResource.update({
        shippingMethod: JSON.stringify(shippingMethod),
        paymentType: this.state.paymentType,
      });
      await this.setState({ shippingMethod });
    } catch (error) {
      console.error(error);
    } finally {
      await this.setState({ shippingMethodsModal: false });
      this.loadCart();
    }
  }

  async setPaymentMethod(event) {
    if (!!!event.detail.checked) return;
    try {
      const type = event.target.getAttribute('type');
      await CartResource.update({ paymentType: type, shippingMethod: JSON.stringify(this.state.shippingMethod) });
      await this.setState({ paymentType: type });
    } catch (error) {
      console.error(error);
    } finally {
      await this.setState({ paymentModal: false });
      this.loadCart();
    }
  }

  async checkout() {
    const { loader, toast, history } = this.props;
    const { note } = this.state;

    try {
      loader.current.show();
      this.setState({ disabled: true });
      let {
        data: { id },
      } = await CartResource.checkout({ note });

      toast.current.add({ message: `Richiesta inviata correttamente` });
      history.push(`/app/thankyou/${id}`);
    } catch (error) {
      toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });

      console.error(error);
    } finally {
      loader.current.dismiss();
      this.setState({ disabled: false });
    }
  }

  render() {
    const {
      shippingType,
      shippingAddress,
      paymentMethods,
      cart,
      shippingMethod,
      paymentType,
      shipping_configs,
      note,
      cart_note,
      prescriptions,
      totgal,
      totsearch,
      shippingModal,
      addresses,
      shippingMethodsModal,
      paymentModal,
      voucherModal,
      voucherBtn,
    } = this.state;

    let step = 1;

    let renderShipping = () => {
      switch (shippingType) {
        case 'inShop':
          return <IonText color="default">Ritiro in farmacia</IonText>;

        case 'vendingMachine':
          return <IonText color="default">Distributore</IonText>;

        case 'pickupPoint':
          return !!shippingAddress ? (
            <IonText color="default">
              Punto di raccolta:{' '}
              <b>
                {shippingAddress.name}, {shippingAddress.address_1} {shippingAddress.address_2} {shippingAddress.city},{' '}
                {shippingAddress.state_region} {shippingAddress.postal_code}
              </b>
            </IonText>
          ) : (
            <IonText color="medium">Seleziona metodo di consegna</IonText>
          );

        case 'address':
          return !!shippingAddress ? (
            <IonText color="default">
              Spedire a:{' '}
              <b>
                {shippingAddress.name}, {shippingAddress.address_1} {shippingAddress.address_2} {shippingAddress.city},{' '}
                {shippingAddress.state_region} {shippingAddress.postal_code}
              </b>
            </IonText>
          ) : (
            <IonText color="medium">Seleziona metodo di consegna</IonText>
          );

        default:
          return <IonButton color="light">Seleziona metodo consegna</IonButton>;
      }
    };

    const renderPayment = (method) => {
      if (method === 'payOnline') {
        return <IonText color="default">Anticipato con Carta di credito</IonText>;
      }

      if (!!paymentMethods[method] && !!paymentMethods[method].description) {
        return <IonText color="default">{paymentMethods[method].description}</IonText>;
      }

      return <IonButton color="light">Seleziona un metodo di pagamento.</IonButton>;
    };

    const renderVoucher = () => {
      if (!!cart.vouchers.length) {
        if (!!!cart.apply_voucher && !!!cart.apply_voucher.message && !!!cart.apply_voucher.type) {
          return <IonText color="default">Codice sconto non valido</IonText>;
        }

        if (cart.apply_voucher.type === 'minimum_cart_total') {
          return <IonText color="default">Codice sconto non applicato</IonText>;
        } else if (cart.apply_voucher.type === 'success') {
          return <IonText color="default">Codice sconto applicato</IonText>;
        } else if (cart.apply_voucher.type === 'error') {
          return <IonText color="default">Codice sconto non valido</IonText>;
        } else {
          return <IonText color="default">Codice sconto non valido</IonText>;
        }
      } else {
        return <IonButton color="light">Aggiungi Codice sconto</IonButton>;
      }
    };

    let renderShippingMethods = () => {
      return shippingMethod ? (
        <IonText color="default">{shippingMethod.label}</IonText>
      ) : (
        <IonButton color="light">Seleziona metodo consegna</IonButton>
      );
    };

    let buttonActive = () => {
      return (
        !!shippingMethod &&
        !!paymentType &&
        !!shippingType &&
        (shippingType === 'inShop' ||
          shippingType === 'vendingMachine' ||
          (shippingType === 'pickupPoint' && !!shippingAddress) ||
          (shippingType === 'address' && !!shippingAddress))
      );
    };

    return !!paymentMethods ? (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/cart" />
            </IonButtons>

            <HeaderButtons {...this.props} />

            <IonTitle>Checkout</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonItem lines="full" detail onClick={() => this.setState({ shippingModal: true })}>
            {step++}&nbsp;
            {renderShipping()}
          </IonItem>

          {!!shipping_configs[shippingType] && !!shipping_configs[shippingType].hasOwnProperty('methods') ? (
            <IonItem lines="full" detail onClick={() => this.setState({ shippingMethodsModal: true })}>
              {step++}&nbsp;
              {renderShippingMethods()}
            </IonItem>
          ) : null}

          <IonItem lines="full" detail onClick={() => this.setState({ paymentModal: true })}>
            {step++}&nbsp;
            {renderPayment(paymentType)}
          </IonItem>

          <IonItem lines="full" detail onClick={() => this.setState({ voucherModal: true })}>
            {step++}&nbsp;
            {renderVoucher()}
          </IonItem>

          <IonItem lines="full">
            <IonList>
              <IonText>{step++}&nbsp; Note aggiuntive</IonText>
              <IonTextarea
                placeholder="Inserisci qui le tue indicazioni o richieste al farmacista. "
                value={note}
                onIonChange={(e) => this.setState({ note: e.detail.value })}
              />
              <small className="ion-padding-bottom">
                Per esempio indica se hai richieste particolari sulla consegna.
              </small>
            </IonList>
          </IonItem>

          <IonItem lines="none">
            <IonText>{step++}&nbsp; Riepilogo Pre-ordine</IonText>
          </IonItem>

          <IonItem lines="full" className="ion-padding-bottom">
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol>Prodotti:</IonCol>
                <IonCol className="ion-text-end">{cart.totalQuantity} </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>Ricette:</IonCol>
                <IonCol className="ion-text-end">{prescriptions.length} </IonCol>
              </IonRow>

              <IonRow>
                {totgal > 0 ? (
                  <>
                    <IonCol>Prescrizione Galenica:</IonCol>
                    <IonCol className="ion-text-end">{totgal}</IonCol>
                  </>
                ) : null}
                {totsearch > 0 ? (
                  <>
                    <IonCol>Foto di Prodotto:</IonCol>
                    <IonCol className="ion-text-end">{totsearch}</IonCol>
                  </>
                ) : null}
              </IonRow>

              <IonRow>
                <IonCol>Sub totale:</IonCol>
                <IonCol className="ion-text-end">
                  <IonText color="dark">
                    <Price price={cart.subTotal} />
                  </IonText>
                  <span className="euro-symbol">€</span>
                </IonCol>
              </IonRow>

              {!!shippingMethod ? (
                <IonRow>
                  <IonCol>Spese di spedizione:</IonCol>
                  <IonCol className="ion-text-end">
                    <IonText color="dark">
                      <Price price={shippingMethod.price} />
                    </IonText>
                    <span className="euro-symbol">€</span>
                  </IonCol>
                </IonRow>
              ) : null}

              {!!cart.apply_voucher &&
              !!cart.apply_voucher.discount_amount &&
              cart.apply_voucher.discount_amount !== 0 ? (
                <IonRow>
                  <IonCol>Sconto:</IonCol>
                  <IonCol className="ion-text-end">
                    <IonText color="dark">
                      -<Price price={cart.apply_voucher.discount_amount} />
                      <span className="euro-symbol">€</span>
                    </IonText>
                  </IonCol>
                </IonRow>
              ) : null}

              <IonRow className="ion-padding-top">
                <IonCol>Totale Pre-ordine:</IonCol>
                <IonCol className="ion-text-end">
                  <IonText color="dark">
                    <Price
                      price={
                        parseFloat(cart.total) -
                        (!!cart.apply_voucher &&
                        !!cart.apply_voucher.discount_amount &&
                        cart.apply_voucher.discount_amount !== 0
                          ? parseFloat(cart.apply_voucher.discount_amount)
                          : 0)
                      }
                    />
                  </IonText>
                  <span className="euro-symbol">€</span>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          <IonItem lines="full">
            <IonLabel position="stacked">Informazioni</IonLabel>
            <IonText className="ion-padding-vertical" color="medium">
              {cart_note}
            </IonText>
          </IonItem>

          <IonModal isOpen={shippingModal}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => this.setState({ shippingModal: false })}>
                    <IonIcon icon={close} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Seleziona metodo di consegna</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <IonList>
                {!!shipping_configs.inShop ? (
                  <IonItem>
                    <IonLabel>Ritiro in farmacia</IonLabel>
                    <IonCheckbox
                      slot="start"
                      type="inShop"
                      onIonChange={(e) => this.setShippingType(e)}
                      checked={shippingType === 'inShop'}
                    />
                  </IonItem>
                ) : null}

                {!!shipping_configs.vendingMachine ? (
                  <IonItem>
                    <IonLabel>Distributore</IonLabel>
                    <IonCheckbox
                      slot="start"
                      type="vendingMachine"
                      onIonChange={(e) => this.setShippingType(e)}
                      checked={shippingType === 'vendingMachine'}
                    />
                  </IonItem>
                ) : null}

                {!!shipping_configs.pickupPoint
                  ? addresses
                      .filter((address) => address.system === true)
                      .map((address) => (
                        <IonItem key={address.id}>
                          <IonLabel>
                            {address.name}, {address.address_1} {address.address_2} {address.city},{' '}
                            {address.state_region} {address.postal_code}
                          </IonLabel>
                          <IonCheckbox
                            slot="start"
                            type="pickupPoint"
                            value={address.id}
                            onIonChange={(e) => this.setShippingType(e)}
                            checked={
                              shippingType === 'pickupPoint' && !!shippingAddress && shippingAddress.id === address.id
                            }
                          />
                        </IonItem>
                      ))
                  : null}

                {!!shipping_configs.address
                  ? addresses
                      .filter((address) => address.system === false)
                      .map((address) => (
                        <IonItem key={address.id}>
                          <IonLabel>
                            {address.name}, {address.address_1} {address.address_2} {address.city},{' '}
                            {address.state_region} {address.postal_code}
                          </IonLabel>
                          <IonCheckbox
                            slot="start"
                            type="address"
                            value={address.id}
                            onIonChange={(e) => this.setShippingType(e)}
                            checked={
                              shippingType === 'address' && !!shippingAddress && shippingAddress.id === address.id
                            }
                          />
                        </IonItem>
                      ))
                  : null}
              </IonList>
            </IonContent>

            {!!shipping_configs.address ? (
              <IonFooter className="ion-padding">
                <IonToolbar>
                  <IonButton
                    expand="block"
                    color="light"
                    onClick={() => {
                      this.setState({ shippingModal: false });
                      this.props.history.push('/app/address?redirect=/app/checkout&action=shipping');
                    }}
                  >
                    <IonIcon icon={add} />
                    Consegna a domicilio ad un nuovo indirizzo
                  </IonButton>
                </IonToolbar>
              </IonFooter>
            ) : null}
          </IonModal>

          <IonModal isOpen={shippingMethodsModal}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => this.setState({ shippingMethodsModal: false })}>
                    <IonIcon icon={close} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Seleziona il metodo di consegna</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <IonList>
                {shipping_configs[shippingType] &&
                  shipping_configs[shippingType].methods.map((method) => {
                    return (
                      <IonItem key={method.label}>
                        <IonCheckbox
                          slot="start"
                          type={method.type}
                          checked={!!shippingMethod && shippingMethod.type === method.type}
                          onIonChange={(e) => this.setShippingMethod(e)}
                        />
                        <IonGrid>
                          <IonRow>
                            <IonCol size="10">{method.label}</IonCol>
                            € <Price price={method.price} />
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                    );
                  })}
              </IonList>
            </IonContent>
          </IonModal>

          <IonModal isOpen={paymentModal}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => this.setState({ paymentModal: false })}>
                    <IonIcon icon={close} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Seleziona un metodo di pagamento</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <IonList>
                {!!paymentMethods ? (
                  Object.keys(paymentMethods).map((key, i) => {
                    return !!paymentMethods[key].shippingType.includes(shippingType) ? (
                      <IonItem key={key}>
                        <IonLabel>{paymentMethods[key].description}</IonLabel>
                        <IonCheckbox
                          slot="start"
                          type={key}
                          checked={paymentType === key}
                          onIonChange={this.setPaymentMethod}
                        />
                      </IonItem>
                    ) : null;
                  })
                ) : (
                  <IonItem>
                    <IonLabel>Seleziona prima il metodo di consegna</IonLabel>
                  </IonItem>
                )}
              </IonList>
            </IonContent>
          </IonModal>

          <IonModal isOpen={voucherModal}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => this.setState({ voucherModal: false })}>
                    <IonIcon icon={close} />
                  </IonButton>
                </IonButtons>
                {!!cart.vouchers.length ? (
                  <IonTitle>Coupon Sconto</IonTitle>
                ) : (
                  <IonTitle>Aggiungi un codice promozionale</IonTitle>
                )}
              </IonToolbar>
            </IonHeader>

            <IonContent>
              {!!cart.vouchers.length ? (
                <div className="ion-padding-top">
                  <IonText className="ion-padding">Riepilogo</IonText>
                  <IonList>
                    {cart.vouchers.map((voucher, key) => (
                      <div key={'voucher' + key}>
                        <IonItem>
                          <IonLabel className="ion-text-wrap">
                            <IonText>
                              <h3 className="ion-text-capitalize">
                                Codice: {voucher.code} - {voucher.name}
                                <small className="price">
                                  {!!voucher.discount_amount ? null : (
                                    <>
                                      <span className="mainprice">
                                        -<Price price={voucher.discount_amount} />
                                        <span className="euro-symbol">€</span>
                                      </span>
                                      &nbsp;
                                    </>
                                  )}
                                </small>
                              </h3>
                            </IonText>
                            <p>
                              {voucher.description} &nbsp;
                              {voucher.include_promo_product === 0 ? (
                                <span>Questo codice si applica solo ai prodotti non in promozione.</span>
                              ) : null}
                            </p>
                            <br />
                            <p color="danger">
                              {!!cart.apply_voucher &&
                              !!cart.apply_voucher.message &&
                              !!cart.apply_voucher.type &&
                              cart.apply_voucher.type === 'success' ? (
                                <IonText color="success">{cart.apply_voucher.message}</IonText>
                              ) : null}
                              {!!cart.apply_voucher &&
                              !!cart.apply_voucher.message &&
                              !!cart.apply_voucher.type &&
                              cart.apply_voucher.type !== 'minimum_cart_total' &&
                              cart.apply_voucher.type !== 'success' ? (
                                <IonText color="danger">{cart.apply_voucher.message}</IonText>
                              ) : null}
                              {!!cart.apply_voucher &&
                              !!cart.apply_voucher.message &&
                              !!cart.apply_voucher.type &&
                              cart.apply_voucher.type === 'minimum_cart_total' ? (
                                <IonText color="danger">
                                  Aggiungi ancora un prodotto non in promo al carrello per raggiungere la soglia minima
                                  di <Price price={voucher.minimum_cart_total} />€ e ottenere il tuo sconto!
                                </IonText>
                              ) : null}
                            </p>
                          </IonLabel>

                          <IonIcon slot="end" icon={trash} onClick={() => this.deleteVoucher(voucher.id)} />
                        </IonItem>
                        <IonButton onClick={() => this.setState({ voucherModal: false })} expand="block" type="button">
                          Indietro
                        </IonButton>
                      </div>
                    ))}
                  </IonList>
                </div>
              ) : (
                <div>
                  <IonItem className="ion-margin-vertical">
                    <IonText>
                      Inserisci qui sotto il codice promozionale e premi invia per vedere il totale aggiornato{' '}
                    </IonText>
                    <IonLabel position="floating">Inserisci codice sconto</IonLabel>
                    <IonInput
                      onIonChange={(e) => this.setState({ voucherCode: e.detail.value })}
                      type="text"
                      name="voucherCode"
                      required
                    ></IonInput>
                  </IonItem>
                  <IonButton disabled={!voucherBtn} onClick={() => this.setVoucher()} expand="block" type="button">
                    Inserisci codice
                  </IonButton>
                </div>
              )}
            </IonContent>
          </IonModal>
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonButton
              expand="block"
              color="success"
              onClick={this.checkout}
              className="ion-margin-horizontal "
              disabled={!buttonActive()}
            >
              Invia la richiesta
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </>
    ) : null;
  }
}

export default withIonLifeCycle(CheckoutPage);
