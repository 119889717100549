import axios from './axios';

export default {
  
  get: async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/shops`;
    let token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  // create: async (fiscalcode) => {
  //   let url = `${process.env.REACT_APP_API_URL}/fiscalcode`;
  //   let token = localStorage.getItem('auth');

  //   return await axios.post(url, fiscalcode, {
  //     headers: { Authorization: `Bearer ${token}` }
  //   });
  // },

  update: async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/shops`;
    let token = localStorage.getItem('auth');

    return await axios.put(url, id, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  // remove: async (id) => {
  //   let url = `${process.env.REACT_APP_API_URL}/fiscalcode/${id}`;
  //   let token = localStorage.getItem('auth');

  //   return await axios.delete(url, {
  //     headers: { Authorization: `Bearer ${token}` }
  //   });
  // },


}