import React, { Component } from 'react';

import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton} from '@ionic/react';
import OptionService from '../services/option';

export default class TosPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tos: ''
    };

  }
  async componentDidMount() {
    try {
      let [ 
        {value: {data: {value: tos}}}, 
      ] = await Promise.allSettled([
        OptionService.get('tos'),
      ]);
      this.setState({ tos });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>Termini d'utilizzo</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-margin ion-padding">
          <div  dangerouslySetInnerHTML={{__html: this.state.tos}} />
        </IonContent>

      </>
    );
  }
}