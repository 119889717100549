import React from 'react';
import {IonButtons, IonButton, IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';

const HeaderButtons = ({slot, history}) => {

    return (
      <IonButtons slot={!!slot ? slot : 'end' }>
        <IonButton onClick={ () => history.push('/app/search') } >
          <IonIcon icon={ search } />                
        </IonButton>
      </IonButtons>
    );

}

export default HeaderButtons