import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import swConfig from './swConfig'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
serviceWorker.register(swConfig);

;(() => {
  const isSamsungBrowser = false; //navigator.userAgent.match(/SamsungBrowser/i);

  if (!!isSamsungBrowser) {
    let container = document.getElementById('root');
    if (!!container) {
      container.innerHTML = `
        <div style="padding: 0.8rem;">
          <div>
            <div style="padding-bottom: 1.2rem;"><h1>Il tuo browser non è supportato</h1></div>
            <div style="padding-bottom: 1rem;">
              Questa applicazione utilizza il browser per visualizzare alcune parti e finestre di dialogo.<br/>
              La maggior parte dei browser moderni sono adeguati.<br/>
            </div>
            <div style="padding-bottom: 1rem;">
              Per utilizzare questa applicazione apri l'indirizzo con un browser supportato oppure cercala nell'app store.
              <br/>
            </div>
            <div style="padding-bottom: 1rem">
              Usa uno dei seguenti browser supportati:<br/>
              <div style="padding-left: 0.6rem; padding-top: 0.6rem;">
                Google Chrome (versione 49 o successiva)<br/>
                Apple Safari (versione 7 o successiva)<br/>
                Firefox (versione 4 o successiva)<br/>
              </div>
            </div>
            <div style="padding-top: 1.2rem;">
              <small>
                Per verificare quale browser utilizzi o per scaricarne un altro, vedi <a href="https://whatsmybrowser.org/">https://whatsmybrowser.org/</a>
              </small>
            </div>
          </div>
        </div>
      `;
    }
    return;
  }

  ReactDOM.render(<App />, document.getElementById('root'));

  // Call the element loader after the app has been rendered the first time
  defineCustomElements(window);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
})();
