import React, { Component } from 'react';

import QueryString from "query-string";

import { withIonLifeCycle } from '@ionic/react';
import { IonList, IonItem, IonLabel, IonIcon, IonText, } from '@ionic/react';
import { IonImg, IonInput, IonToggle, IonTextarea, IonButton } from '@ionic/react';

import { IonModal, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonCheckbox, IonFooter } from '@ionic/react';

import { 
  addCircleOutline as add,
  saveOutline as save,
  trashBinOutline as remove,
  closeOutline as close
} from 'ionicons/icons';

import File from '../File/File';

import PrescriptionService from '../../services/prescription';
import FiscalcodeService from '../../services/fiscalcode';



class Prescription extends Component {

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.nre = React.createRef();
    this.fse_consent = React.createRef();
    this.codice_fiscale = React.createRef();
    this.files = React.createRef();
    
    this.state = {
      id: this.props.id,
      nre: undefined,
      fse_consent: false,
      codice_fiscale: undefined,
      fiscalcodes: [],
      note: undefined,
      equivalent: undefined,
      fiscalcodeModal: false,
      files: [],
      errors: {},
    };

    this.fileHandler = this.fileHandler.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.toggleHandler = this.toggleHandler.bind(this);
    this.setPrescription = this.setPrescription.bind(this);
    this.removePrescription = this.removePrescription.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.getAddresses = this.getAddresses.bind(this);
    this.setFiscalCode = this.setFiscalCode.bind(this);
  }

  async ionViewWillEnter() {
    await this.setState({ id: this.props.id});
    await this.getAddresses();

    try {
      let { action, fiscalcode } = QueryString.parse(this.props.location.search);

      switch (action) {
        case 'fiscalcode':
          this.props.history.replace(this.props.location.pathname);
          await this.setState({ 
            codice_fiscale: this.state.fiscalcodes.find(f => f.id === parseInt(fiscalcode) )?.codice_fiscale
          });          
          break;
      
        default:
          this.setState({
            id: this.props.id,
            nre: undefined,
            fse_consent: false,
            note: undefined,
            equivalent: undefined,
            codice_fiscale: undefined,
            files: [],
            errors: {},
            disabled: true,
          });
          break;
      }
    } catch (error) {
      console.error(error);
    }   
    
    if (!!!this.state.id) {
      return;
    }
    
    if(Number.parseInt(this.state.id) > 0 ){

      let { data: { 
        nre, fse_consent, codice_fiscale, note, equivalent, file,
      }} = await PrescriptionService.get(this.state.id);

      await this.setState({ nre, fse_consent, 
        codice_fiscale: !!this.state.codice_fiscale ? this.state.codice_fiscale : codice_fiscale, 
        note, equivalent,
        files: !!file ? [file] : [],
      });
      await this.handleFormValidation();
    }
    
  }


  async getAddresses() {
    let {data: {data: fiscalcodes}} = await FiscalcodeService.get();
    await this.setState({ fiscalcodes });
  }

  async fileHandler(files, event) {
    await this.setState({ files: files });
    await this.handleValidation(event);
    await this.handleFormValidation();
  }

  async removeFile(key) {
    
    let files = this.state.files;
    files = files.filter((f, i) => i!== key);
    this.files.current.input.current.value="";
    this.setState({ files });
    await this.handleFormValidation();
  }

  async inputHandler(event) {
    let errors = this.state.errors;
    delete errors[event.target.name];

    await this.setState({
      [event.target.name]: event.target.value,
      errors,
    });
    await this.handleValidation(event);
    await this.handleFormValidation();
  }

  async textHandler(event) {
    await this.setState({
      [event.target.name]: event.detail.value,
    });
    await this.handleValidation(event);
    await this.handleFormValidation();
  }

  async toggleHandler(event) {
    await this.setState({
      [event.target.name]: event.detail.checked,
    });
    await this.handleFormValidation();
  }

  async handleValidation(event) {
    let errors = {};
    let error = false; 

    if(
      event.target &&
      event.target.firstChild &&
      event.target.firstChild.validationMessage
    ){
      error = event.target.firstChild.validationMessage
    }

    if(
      event.firstChild &&
      event.firstChild.validationMessage
    ){
      error = event.firstChild.validationMessage
    }
     
    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];
    if(error)
      errors[name] = error;
    await this.setState({ errors });
    return false;
  }

  async handleFormValidation() {
    let form_require = {
      fse_required: false,
      nre_required: false,
      files_required: true,
    };
    if(this.state.nre) {
      form_require = {
          fse_required: false,
          nre_required: true,
          files_required: false,
        }
    } 
    if(this.state.fse_consent) {
      form_require = { 
          fse_required: true,
          nre_required: false,
          files_required: false,
        }
    } 

    this.setState(
      { ...form_require }
    );
    
    let errors = this.state.errors;
    delete errors['codice_fiscale'];
    if (!this.state.codice_fiscale) {
      errors['codice_fiscale'] = 'Selezionare un codice fiscale.';
    }
    this.setState({ 
      errors,
      disabled: ( !this.form.current.checkValidity() || !this.state.codice_fiscale ) 
    });
    return ( !this.form.current.checkValidity() || !this.state.codice_fiscale );
  }

  async removePrescription() {
    await PrescriptionService.remove(this.state.id);
    if(this.props.onSubmit && (typeof this.props.onSubmit === 'function')) {
      this.props.onSubmit('remove');
    }
  }

  async setPrescription(e) {
    e.preventDefault();
    if(!this.handleFormValidation()){
      return;
    }

    if (!!!this.state.codice_fiscale) {
      this.props.toast.current.add({
        message: `Ci sono dei problemi con il Codice Fisale`,
        color: 'warning',
      });
      return;
    }

    try{
      this.props.loader.current.show();
      this.setState({ disabled: true });

      let action;

      let prescription = {
        nre: this.state.fse_consent?null:this.state.nre,
        fse_consent: this.state.fse_consent,
        codice_fiscale: this.state.codice_fiscale.toUpperCase(),
        note: this.state.note,
        equivalent: this.state.equivalent,
        file: this.state.fse_consent?null:this.state.files.pop(),
      };

      if (this.state.id && Number.parseInt(this.state.id) > 0 ) {      
        prescription = (await PrescriptionService.update(this.state.id, prescription)).data;
        action = 'update';
      } else {
        prescription = (await PrescriptionService.create(prescription)).data;
        action = 'create';
      }


      let {
        id,
        nre,
        fse_consent,
        codice_fiscale,
        note,
        equivalent,
        file,
      } = prescription;

      await this.setState({
        id,
        nre,
        fse_consent,
        codice_fiscale,
        note,
        equivalent,
        files: !!file ? [file] : [],
      });

      if(this.props.onSubmit && (typeof this.props.onSubmit === 'function')) {
        this.props.onSubmit(action, prescription);
      }
    } catch (error) {
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
      console.error(error);
    } finally {
      this.props.loader.current.dismiss();
      this.setState({ disabled: false });
    }
  }

  async setFiscalCode(event) {
    if (!!!event.detail.checked) {
      return;
    }

    try {
      let fiscalcode = event.target.value;
      await this.setState({codice_fiscale: fiscalcode});
    } catch (error) {
      console.error(error);
    } finally {
      await this.setState({fiscalcodeModal: false});
      this.handleFormValidation();
    }

  }

  render() {
    const {errors} = this.state;

    return (
      <>
        <form ref={ this.form } onSubmit={ this.setPrescription }>
          <IonList lines="full" className="ion-no-margin ion-no-padding">
            { this.state.id === "fse" || ( Number.parseInt(this.state.id) > 0 )
              ?
              <>
                <p className="ion-padding-top ion-padding-start ion-padding-end">Invio ricetta con Fascicolo sanitario Elettronico</p>
                <IonItem>
                  <IonLabel color={ !!errors.fse_consent ? 'danger' : null} position="" >
                    
                  </IonLabel>
                  <IonToggle slot="end" mode="ios" name="fse_consent" required={ this.state.fse_required } onIonChange={ this.toggleHandler } checked={ this.state.fse_consent } />
                  <p className="">
                    Do il consenso per il prelievo delle ricette elettroniche presenti nel Fascicolo Sanitario Elettronico { this.state.fse_required ? <IonText color="danger">*</IonText> : null }
                  </p>
                </IonItem>
              </>
              :null
            } 
            { this.state.id === "nre" || ( Number.parseInt(this.state.id) > 0 )
              ?<>
                <p className="ion-padding-top ion-padding-start ion-padding-end">Invio ricetta con Numero di Ricetta Elettronica</p>
                <IonItem>
                  <IonLabel color={ !!errors.nre ? 'danger' : null} position="floating">Digita qui sotto il numero di ricetta elettronica (NRE)
                    { this.state.nre_required ? <IonText color="danger">*</IonText> : null }
                  </IonLabel>
                  <IonInput type="text" name="nre" required={ this.state.nre_required }
                    placeholder="Compila qui esempio: 2000A1234567891234"
                    ref={ this.nre }
                    minlength={13} maxlength={15}
                    onIonBlur={ this.handleValidation }
                    onIonChange={ this.inputHandler } value={ this.state.nre }/>
                  {
                    !!errors.nre
                      ? <IonText color="danger">
                          { errors.nre }
                        </IonText>
                      : null
                  }
                </IonItem>
                <p className="ion-padding-start">Dove si trova il numero di ricetta elettronica ?</p>
                  <IonImg 
                    style= { { width: '100%', maxWidth: '500px' } }
                    src={ '/assets/nre.png' }
                  ></IonImg>

              </>
              :null
            } 
            { this.state.id === "file" || ( Number.parseInt(this.state.id) > 0 )
              ?<>
                <p className="ion-padding-top ion-padding-start ion-padding-end">Invio ricetta con caricamento Foto</p>
                <IonItem lines="none">
                  <IonLabel color={ !!errors.files ? 'danger' : null} position="">
                  <p className="">Carica il file o fai la fotografia
                    { this.state.files_required ? <IonText color="danger">*</IonText> : null }</p>
                  </IonLabel>
                </IonItem>
                <IonList>
                  {
                    this.state.files.map((file, key) => 
                      <IonItem key={ key }>
                        <IonLabel className="ion-padding-start">{ file.name }</IonLabel>
                        <IonIcon icon={ remove } slot="end" onClick={ () => this.removeFile(key) } />                  
                      </IonItem>
                    )
                  }             
                  <IonItem lines={!!errors.files ? 'none' : null}>
                    <File onChange={ this.fileHandler } name="files" ref={ this.files } required={ this.state.files_required } />
                  </IonItem>
                  {
                    !!errors.files
                      ? <IonItem>
                          <IonText color="danger">
                            { errors.files }
                          </IonText>
                        </IonItem>
                      : null
                  }
                  
                </IonList>
              </>
              :null
            } 

            {/* this.state.id === "vetnre" || ( Number.parseInt(this.state.id) > 0 )
              ?<>
                <p className="ion-padding-top ion-padding-start ion-padding-end">Invio ricetta veterinaria con Numero di Ricetta Elettronica</p>
                <IonItem>
                  <IonLabel color={ !!errors.nre ? 'danger' : null} position="floating">Numero di ricetta
                    { this.state.nre_required ? <IonText color="danger">*</IonText> : null }
                  </IonLabel>
                  <IonInput type="text" name="nre" required={ this.state.nre_required }
                    placeholder="1234567891234"
                    ref={ this.nre }
                    minlength={13} maxlength={13}
                    onIonBlur={ this.handleValidation }
                    onIonChange={ this.inputHandler } value={ this.state.nre }/>
                  {
                    !!errors.nre
                      ? <IonText color="danger">
                          { errors.nre }
                        </IonText>
                      : null
                  }
                  <p className=""></p>
                </IonItem>
                <IonItem>
                  <IonLabel color={ !!errors.nre ? 'danger' : null} position="floating">PIN
                    { this.state.nre_required ? <IonText color="danger">*</IonText> : null }
                  </IonLabel>
                  <IonInput type="text" name="nre" required={ this.state.nre_required }
                    placeholder="1234567891234"
                    ref={ this.nre }
                    minlength={4} maxlength={4}
                    onIonBlur={ this.handleValidation }
                    onIonChange={ this.inputHandler } value={ this.state.nre }/>
                  {
                    !!errors.nre
                      ? <IonText color="danger">
                          { errors.nre }
                        </IonText>
                      : null
                  }
                  <p className=""></p>
                </IonItem>
              </>
              :null
            */}

            {/* this.state.id === "vetfile"  || ( Number.parseInt(this.state.id) > 0 )
              ?<>
                <p className="ion-padding-top ion-padding-start ion-padding-end">Invio ricetta veterinaria con caricamento Foto</p>
                <IonItem lines="none">
                  <IonLabel color={ !!errors.files ? 'danger' : null} position="">
                  <p className="">Carica il file o fai la fotografia
                    { this.state.files_required ? <IonText color="danger">*</IonText> : null }</p>
                  </IonLabel>
                </IonItem>
                <IonList>
                  {
                    this.state.files.map((file, key) => 
                      <IonItem key={ key }>
                        <IonLabel className="ion-padding-start">{ file.name }</IonLabel>
                        <IonIcon icon={ remove } slot="end" onClick={ () => this.removeFile(key) } />                  
                      </IonItem>
                    )
                  }             
                  <IonItem lines={!!errors.files ? 'none' : null}>
                    <File onChange={ this.fileHandler } name="files" ref={ this.files } required={ this.state.files_required } />
                  </IonItem>
                  {
                    !!errors.files
                      ? <IonItem>
                          <IonText color="danger">
                            { errors.files }
                          </IonText>
                        </IonItem>
                      : null
                  }
                  
                </IonList>
              </>
              :null
            */} 

            <IonItem>
              <IonLabel>Farmaco equivalente</IonLabel>
              <IonToggle slot="end" mode="ios" name="equivalent" onIonChange={ this.toggleHandler } checked={ this.state.equivalent } />
            </IonItem>

            <IonItem lines="full" detail onClick={() => this.setState({fiscalcodeModal: true})}>
              { 
                !!this.state.codice_fiscale
                  ? <IonLabel color="default"> { this.state.codice_fiscale } </IonLabel>
                  : <IonText color={!!errors.codice_fiscale ? 'danger' : 'danger'}> Seleziona codice fiscale</IonText>
              }
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Note aggiuntive</IonLabel>
              <IonTextarea type="text" name="note" 
                placeholder="Indica qui se hai preferenze o altre comunicazioni relative alla ricetta." 
                onIonChange={ this.textHandler } value={ this.state.note } />
                
            </IonItem>
            
            <div className="ion-padding">
              
              <IonButton 
                expand="block"
                type="submit"
                disabled={ this.state.disabled }
                onClick={ this.handleFormValidation } >
                {
                  !!this.state.id && Number.parseInt(this.state.id) > 0
                    ? <><IonIcon slot="start" icon={ save } /> Aggiorna</>
                    : <><IonIcon slot="start" icon={ add } /> Aggiungi</>
                }
                
              </IonButton>
              {
                !!this.state.id && Number.parseInt(this.state.id) > 0
                  ?  <IonButton expand="block" color="warning" className="ion-margin-top" onClick={ this.removePrescription }>
                      <IonIcon slot="start" icon={ remove } /> Cancella
                    </IonButton>
                  : null
              }

            </div>

          </IonList>
        </form>
        
        <IonModal isOpen={ this.state.fiscalcodeModal }>
          <>
          <IonHeader>
          <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={ () => this.setState({fiscalcodeModal: false}) }>
                  <IonIcon icon={ close }/>
                </IonButton>
              </IonButtons>
              <IonTitle>Seleziona codice fiscale</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>

            <IonList>

              {
                !!this.state.fiscalcodes.length
                  ? this.state.fiscalcodes                    
                      .map(fiscalcode => 
                        <IonItem key={ fiscalcode.id }>
                          <IonLabel>
                            <strong>{ fiscalcode.codice_fiscale }</strong>
                          </IonLabel>
                          <IonCheckbox slot="start" type="address" value={ fiscalcode.codice_fiscale } onIonChange={ e => this.setFiscalCode(e) } 
                            checked={ this.state.codice_fiscale === fiscalcode.codice_fiscale }/>
                        </IonItem>
                      )
                  : <IonItem>
                  <IonLabel>
                    <p>Non sono presenti codice fiscale</p>
                  </IonLabel>
                </IonItem>
              }
              

              
            </IonList>

          </IonContent>
          <IonFooter className="ion-padding">
            <IonToolbar>
              <IonButton expand="block" color="light" onClick={ () => {
                  this.setState({fiscalcodeModal: false})
                  this.props.history.push(`/app/fiscalcode?redirect=${ this.props.location.pathname }&action=fiscalcode`);
                } } >
                <IonIcon icon={ add }/> Aggiungi un codice fiscale
              </IonButton>
            </IonToolbar>
          </IonFooter>
          </>
        </IonModal>

        
      </>
    );
  }
  
}

export default withIonLifeCycle(Prescription);