import React from 'react'
import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { IonBackButton, IonButtons } from '@ionic/react';
import HeaderButtons from '../HeaderButtons';

function HeaderProduct({ nome_prodotto, slot, history }) {
  return (
    <>
        <IonHeader>
            <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>{ !!nome_prodotto ? nome_prodotto : 'Prodotto non trovato'}</IonTitle>
            <HeaderButtons slot={slot} history={history} />  
            </IonToolbar>
        </IonHeader>
    </>
  )
}

export default HeaderProduct