import React from 'react'
import { IonText } from '@ionic/react';

function CatSideProduct({data}) {
  return (
    <IonText color="medium" className="ion-text-capitalize-first ion-text-small">
        <div>{ data }</div>
    </IonText>          
  )
}

export default CatSideProduct