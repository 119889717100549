import React, { Component } from 'react';

import { IonInput } from '@ionic/react';

import './file.css';


export default class File extends Component {

  constructor(props) {
    super(props);

    this.input = React.createRef();

    this.getFiles = this.getFiles.bind(this);
    this.readFileAsync = this.readFileAsync.bind(this);
  }

  async getFiles(e){
    let files = e.target.firstChild.files;
    let base64_files = [];
    let accept = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'application/pdf', 'image/x-eps', ];

    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        try {
          e.target.firstChild.setCustomValidity('');
          if(accept.indexOf(files[key].type) === -1) {
            e.target.firstChild.setCustomValidity(`Formato file '${files[key].name}' non valido.`);
            e.target.firstChild.value=null      
            continue;
          }
          let base64 = await this.readFileAsync(files[key]);
          base64 = btoa(new Uint8Array(base64).reduce((data, byte) => data + String.fromCharCode(byte), ''));
          base64_files.push({
            name: files[key].name,
            type: files[key].type,
            lastModified: files[key].lastModified,
            size: files[key].size,
            base64
          });
        } catch (error) {
          console.error(error);
        }        
        }
      }

   if(this.props.onChange && (typeof this.props.onChange === 'function')) {
    this.props.onChange(base64_files.slice(0, 1), e);
   }

  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    })
  }

  render() {
    return (
      <div className=" ion-padding-bottom">
        <label className="custom-file-upload">
          <IonInput ref={ this.input } className="button" type="file" onIonChange={ this.getFiles } {...this.props} />
        </label>
        <p>Clicca su "scegli file" per fare la foto o selezionare il file</p>
      </div>
      
    );
  }
  
}
