import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons, IonBackButton, IonButton, IonIcon } from '@ionic/react';
import { helpCircleOutline as help} from 'ionicons/icons';

import Prescription from '../../components/Prescription/Prescription';


class PrescriptionPage extends Component {

  render() {
    return (
      <>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/cart" />
            </IonButtons>

            <IonTitle>Inserisci ricetta</IonTitle>

            <IonButtons slot="end">

              <IonButton href="/app/help/prescription">
                <IonIcon icon={ help } />
              </IonButton>     
            </IonButtons>

          </IonToolbar>
        </IonHeader>

        <IonContent>
          <Prescription 
            id={ this.props.match.params.id }
            onSubmit={ () => this.props.history.replace('/app/cart') }
            loader={ this.props.loader }
            history={ this.props.history }
            location={ this.props.location }
            toast={ this.props.toast }
          /> 
        </IonContent>

      </>
    );
  }
  
}

export default withIonLifeCycle(PrescriptionPage);