import React, { Component } from 'react';

import {
  withIonLifeCycle,
  IonContent,
  IonTitle,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from '@ionic/react';

import {
  checkmarkCircleOutline,
  checkmarkDoneCircleOutline,
  alertCircleOutline,
  helpCircleOutline,
} from 'ionicons/icons';

import OrderService from '../../services/order';
import PaymentService from '../../services/payment';
import HeaderButtons from '../../components/HeaderButtons';
import SearchService from '../../services/product';
import TrackingService from '../../services/tracking';

class PaymentEndPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: undefined,
      id: undefined,
      payment: undefined,
      order: undefined,
    };
  }

  async ionViewWillEnter() {
    try {
      this.props.loader.current.show();
      let paymentID = undefined;
      let paymentMethod = new URLSearchParams(this.props.location.search).get('paymentMethod');

      if (paymentMethod === 'paypal') {
        //paymentMethod = 'paypal';
        paymentID = new URLSearchParams(this.props.location.search).get('token');
      }

      if (!!!paymentID) {
        paymentMethod = 'gestpay';
        paymentID = new URLSearchParams(this.props.location.search).get('paymentID');
      }

      if (!!!paymentID) {
        paymentMethod = 'nexi';
        paymentID = new URLSearchParams(this.props.location.search).get('codTrans');
      }

      if (!!paymentID) {
        const { data: payment } = await PaymentService.payment(paymentMethod, paymentID);
        await this.setState({ payment });
        await this.setState({ id: payment.order_id });
        const { data: order } = await OrderService.get(this.state.id);
        await this.setState({ order });

        let status = new URLSearchParams(this.props.location.search).get('status');
        switch (status) {
          case 'OK':
            await this.setState({ status: 'OK' });

            let promises = order.products.map((product) => SearchService.get(product.code));
            promises = await Promise.allSettled(promises);

            let products = promises
              .map((product) => (product.status === 'fulfilled' ? product.value.data : undefined))
              .filter((product) => !!product);
            TrackingService.purchase(order, products);
            break;

          case 'KO':
            await this.setState({ status: 'KO' });
            break;

          default:
            await this.setState({ status: undefined });
            break;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (!!this.props.loader && !!this.props.loader.current) {
        this.props.loader.current.dismiss();
      }
    }
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <HeaderButtons {...this.props} />
            <IonTitle>Pagamento Inviato</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-margin ion-padding">
          <IonTitle>Esito pagamento</IonTitle>
          <IonGrid className="ion-text-center ion-padding-vertical">
            <IonRow>
              <IonCol>
                <div className="">
                  {this.state.status === 'OK' && this.state.payment.valid === 1 ? (
                    <>
                      <IonIcon style={{ fontSize: '80px' }} color="success" icon={checkmarkDoneCircleOutline}></IonIcon>
                      <h1>Grazie per aver effettuato il pagamento.</h1>
                      <p>
                        La transazione è stata completata e confermata.
                        <br />
                      </p>
                      <p>
                        Una ricevuta dell'acquisto sarà inviata al tuo indirizzo email.
                        <br />
                      </p>
                      <p>
                        Per informazioni sullo stato del tuo ordine controlla il link nella mail di conferma d'ordine
                        che ti abbiamo inviato oppure contatta il nostro servizio clienti.
                      </p>
                    </>
                  ) : (
                    <>
                      {this.state.status === 'OK' ? (
                        <>
                          <IonIcon style={{ fontSize: '80px' }} color="success" icon={checkmarkCircleOutline}></IonIcon>
                          <h1>Grazie per aver effettuato il pagamento.</h1>
                          <p>
                            La transazione è stata inviata e sarà elaborata prima possibile.
                            <br />
                          </p>
                          <p>
                            Una ricevuta dell'acquisto sarà inviata al tuo indirizzo email.
                            <br />
                          </p>
                          <p>
                            Per informazioni sullo stato del tuo ordine controlla il link nella mail di conferma
                            d'ordine che ti abbiamo inviato oppure contatta il nostro servizio clienti.
                          </p>
                        </>
                      ) : null}
                    </>
                  )}

                  {this.state.status === 'KO' ? (
                    <>
                      <IonIcon style={{ fontSize: '80px' }} color="danger" icon={alertCircleOutline}></IonIcon>
                      <h1>Il pagamento è stato rifiutato</h1>
                      <p>
                        La transazione non è andata a buon fine.
                        <br />
                        Verifica la correttezza dei dati inseriti e la presenza di credito sufficiente per effettuare la
                        transazione.
                        <br />
                        Controlla la tua casella di posta e verifica la presenza di comunicazioni relative al tuo
                        ordine. <br />
                        Se non è presente alcuna conferma di pagamento esegui nuovamente il pagamento.
                      </p>
                      <p></p>
                    </>
                  ) : null}

                  {this.state.status === undefined ? (
                    <>
                      <IonIcon style={{ fontSize: '80px' }} color="warning" icon={helpCircleOutline}></IonIcon>
                      <h1>Il pagamento è in sospeso.</h1>
                      <p>
                        La transazione è non stata ancora confermata dalla banca.
                        <br />
                        Attendi prima di riprovare ad eseguire il pagamento, verifica se la transazione è contabilizzata
                        nel tuo estratto conto
                        <br />
                      </p>
                      <p>Contattaci per verificare lo stato del pagamento.</p>
                    </>
                  ) : null}
                </div>

                {!!this.state.id ? (
                  <IonButton
                    color="light"
                    expand="block"
                    onClick={() => this.props.history.push(`/app/orders/${this.state.id}`)}
                  >
                    Vedi ordine
                  </IonButton>
                ) : null}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(PaymentEndPage);
