import React, { Component } from 'react';

import { IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import { IonButton, IonIcon } from '@ionic/react';

import AuthResource from '../resources/Auth';
import SystemResource from '../resources/System';
import ErrorLog from '../services/errorlog';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      state: 'login',
      profile: '',
    };

    this.recovery = this.recovery.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleEyeIconStatus = this.toggleEyeIconStatus.bind(this);
  }

  async toggleEyeIconStatus() {
    this.setState({ eyeIconStatus: !this.state.eyeIconStatus });
  }

  async recovery(e) {
    e.preventDefault();
    let [email] = [this.form.current.email.value];

    try {
      await AuthResource.service.recovery(email);

      if (!!this.props.onRecovery) this.props.onRecovery();
    } catch (error) {
      if (!!this.props.onRecovery) this.props.onRecovery(error);
    }
  }

  async submit(e) {
    e.preventDefault();
    let [email, password] = [e.target.email.value, e.target.password.value];

    try {
      this.props.loader.current.show();
      let response = await AuthResource.login(email, password);
      if (!!this.props.onSubmit) this.props.onSubmit(null, response);

      /* let {data: {data: profile}} = await ProfileService.me();
      this.setState({ profile }); */
      await SystemResource.privacyCheck();
    } catch (error) {
      this.props.onSubmit(error);
      ErrorLog.send(error);
    } finally {
      if (!!this.props.loader && !!this.props.loader.current) {
        this.props.loader.current.dismiss();
      }
    }
  }

  render() {
    return (
      <form ref={this.form} onSubmit={this.submit}>
        <IonList lines="full" className="ion-no-margin ion-no-padding">
          <IonItem>
            <IonLabel position="floating">Email o Telefono</IonLabel>
            <IonInput autocomplete="username" id="email" type="text" name="email" required></IonInput>
          </IonItem>

          {this.state.state === 'login' && (
            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput autocapitalize="off" autocorrect="off" spellcheck="false" autocomplete="current-password" id="current-password" type={!this.state.eyeIconStatus ? 'password' : 'text'} name="password" required></IonInput>
              <IonIcon
                className="ion-padding-top"
                onClick={() => this.toggleEyeIconStatus()}
                icon={this.state.eyeIconStatus ? eyeOffOutline : eyeOutline}
                slot="end"
              ></IonIcon>
            </IonItem>
          )}
        </IonList>

        <div className={`ion-padding ${this.state.state === 'login' ? null : 'ion-hide'}`}>
          <IonButton expand="block" type="submit" className="ion-no-margin">
            Accedi
          </IonButton>
        </div>

        <div className={`ion-padding ${this.state.state === 'recovery' ? null : 'ion-hide'}`}>
          <IonButton expand="block" type="button" onClick={this.recovery} className="ion-no-margin">
            Recupera password
          </IonButton>
        </div>

        <div className={`ion-padding ion-text-center ${this.state.state === 'login' ? null : 'ion-hide'}`}>
          <a
            href="/auth/login"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ state: 'recovery' });
            }}
          >
            Recupera password
          </a>
        </div>

        <div className={`ion-padding ion-text-center ${this.state.state === 'recovery' ? null : 'ion-hide'}`}>
          <a
            href="/auth/login"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ state: 'login' });
            }}
          >
            Torna al login
          </a>
        </div>
      </form>
    );
  }
}
