import axios from './axios';

export default {
  current: async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/cart`;
      let token = localStorage.getItem('auth');

      return await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      
    }

    return false;

  },

  update: async ({ ...body }) => {
    let url = `${process.env.REACT_APP_API_URL}/cart`;
    let token = localStorage.getItem('auth');

    return await axios.put(url, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  checkout: async ({ ...body }) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/checkout`;
    let token = localStorage.getItem('auth');

    return await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  addProduct: async (code, quantity = 1) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/products/${code}`;
    let token = localStorage.getItem('auth');

    return await axios.post(
      url,
      { quantity },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  quantityProduct: async (code, quantity = 1) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/products/${code}`;
    let token = localStorage.getItem('auth');

    return await axios.post(
      url,
      { quantity },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  deleteProduct: async (code) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/products/${code}`;
    let token = localStorage.getItem('auth');

    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  deleteCart: async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/${id}`;
    let token = localStorage.getItem('auth');

    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  addVoucher: async (code) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/vouchers/${code}`;
    let token = localStorage.getItem('auth');

    return await axios.post(
      url,
      { code },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  deleteVoucher: async (code) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/vouchers/${code}`;
    let token = localStorage.getItem('auth');

    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
