import axios from './axios';

export default {

  get: async(codiceMinisteriale)  => {
    let url = `${process.env.REACT_APP_API_URL}/product/${codiceMinisteriale}`;
    return await axios.get(url);
  },
  
  search: async (query, currentPage=1, requestPerPage=5) => {
    let url = `${process.env.REACT_APP_API_URL}/product/search`;

    let queryString = new URLSearchParams();

    if(!!query) {
      queryString.set('q', query);
    }

    queryString.set('rpp', requestPerPage);
    queryString.set('p', currentPage);

    url = `${url}?${queryString.toString()}`; 

    return await axios.get(url);
  },

  /* per ricerca con categorie */

  searchCategory: async (category, currentPage=1, requestPerPage=5) => {
    let url = `${process.env.REACT_APP_API_URL}/product/searchCategory`;

    let queryString = new URLSearchParams();

    if(!!category) {
      queryString.set('q', category);
    }

    queryString.set('rpp', requestPerPage);
    queryString.set('p', currentPage);

    url = `${url}?${queryString.toString()}`; 

    return await axios.get(url);
  },

  getCategory: async (category1, category2, category3, category4) => {
    let url = `${process.env.REACT_APP_API_URL}/product/category`;

    let queryString = new URLSearchParams();

    if(category1 !== undefined) {
      queryString.set(`cat1`, category1)
      if(category2 !== null) {
        queryString.set(`cat2`, category2)
        if(category3 !== null) {
          queryString.set(`cat3`, category3)
          if(category4 !== null) {
            queryString.set(`cat4`, category4)
          } 
        } 
      } 
    } 
    
    url = `${url}?${queryString.toString()}`; 
    return await axios.get(url);
  }
}