import React from 'react'
import { IonImg, IonText } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';

function HeadSideProduct({searchService}){
  return (
    
    <IonGrid>
        <IonRow>
            <IonCol size="7" className="ion-text-capitalize">
                <IonText color="medium" className="ion-text-small ion-text-start">
                    <div>Produttore: { searchService?.descrizione_ditta_1_produttrice.toLowerCase() }</div>
                </IonText>
            </IonCol>
            <IonCol className="ion-text-capitalize-first">
                <IonText color="medium" className="ion-text-small ion-text-end">
                    <div>Codice: { searchService?.codice_ministeriale }</div>
                </IonText>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                <IonImg 
                    src={ ( searchService?.immagine && searchService?.immagine!=="" ) ?`https://static.fogliettoillustrativo.net/ws/i/${searchService?.immagine}`:`/assets/prodotto_generico_250.png` } 
                    style= { {  maxWidth: '300px' } } />  
            </IonCol>
        </IonRow>
    </IonGrid>


  )
}

export default HeadSideProduct