import React from 'react';
import SelectShops from './SelectShops';

import { 
    IonHeader, 
    IonToolbar, 
    IonImg, 
    IonGrid,
    IonRow,
    IonCol,
  } from '@ionic/react';
import {  } from '@ionic/react';
import HeaderButtons from '../HeaderButtons';


const HomeHeader = ({shopName, selected, saveShop, shops, slot, history}) => {

    return (
        <IonHeader> 
            <IonToolbar> 
                <HeaderButtons slot={slot} history={history} />
                <IonGrid> 
                <IonRow> 
                    <IonCol size="7"> 
                        <IonImg  
                        src={`/assets/logo.png`} 
                        alt={shopName} 
                        style={ {  maxWidth: '200px' } }
                            /> 
                    </IonCol> 

                    <IonCol size="5"> 
                    { shops?.length > 1 && (
                        <SelectShops selected={selected} saveShop={saveShop} shops={shops} />
                    )}
                    </IonCol> 
                    
                </IonRow> 
                </IonGrid> 
            </IonToolbar> 
        </IonHeader>
    );

}

export default HomeHeader