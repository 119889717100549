import React from 'react'
import { IonText } from '@ionic/react';
import { IonRow, IonCol } from '@ionic/react';

function InfoDetailsSideProduct({desc, data}) {
  return (
    
    <IonRow className="ion-margin-bottom ion-padding-start ion-text-medium">
        <IonCol size="4">
            <IonText>{desc}</IonText>
        </IonCol>
        <IonCol>
            <IonText className="ion-text-capitalize">{ data }</IonText>
        </IonCol>
    </IonRow>


  )
}

export default InfoDetailsSideProduct