import React, { Component } from 'react';
import { IonToast } from '@ionic/react';


export default class Toast extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
    }

    this.add = this.add.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  async add({...args}) {
    let messages = this.state.messages;
    messages.push({...args})
    await this.setState({ messages });
  }

  async dismiss(key, {...toast}) {
    let messages = this.state.messages;

    try {
      if (toast.onDidDismiss instanceof Function) {
        toast.onDidDismiss();
      }
    } catch (error) {
      console.error(error);
    }

    messages = messages.filter((el, k) => key !== k);
    await this.setState({ messages });
  }

  render() {
    return (
      <>
        {
          this.state.messages.map(({...toast}, key) => (
            <IonToast
              key={ key }
              isOpen={ true }
              color="light"
              duration={2000}
              position="top"
              buttons={[
                {
                  side: 'end',
                  icon: 'close'
                },
              ]}
              {...toast}              
              onDidDismiss={ (...args) => this.dismiss(key, {...toast}) }
            />
          ))
        }
      </>
    )
  }
}