import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons, IonBackButton, IonButton, IonIcon } from '@ionic/react';
import { IonGrid, IonRow, IonCol  } from '@ionic/react';
import { chevronForwardOutline  as go, helpCircleOutline as help} from 'ionicons/icons';

class PrescriptionPage extends Component {

  render() {
    return (
      <>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/cart" />
            </IonButtons>

            <IonTitle>Inserisci ricetta</IonTitle>

            <IonButtons slot="end">

              <IonButton href="/app/help/prescription">
                <IonIcon icon={ help } />
              </IonButton>     
            </IonButtons>

          </IonToolbar>
        </IonHeader>

        <IonContent>
            
          <IonGrid className="ion-padding-top">
            <IonRow className=" ion-padding-bottom">
              <IonCol className="ion-align-self-center">
                <h2 className="text-center">
                  Seleziona il metodo di inserimento della ricetta desiderato
                </h2>
              </IonCol>
            </IonRow>
            <IonRow className="ion-padding-bottom">
              <IonCol className="ion-align-self-center">
                <IonButton color="light" size="large" expand="block"  onClick={ () => { this.props.history.push('/app/prescription/fse')} } >
                  Ho il Fascicolo <br/> Sanitario
                  <IonIcon icon={ go }  slot="end"  />
                </IonButton>  
              </IonCol>
            </IonRow>
            <IonRow className=" ion-padding-bottom">
              <IonCol className="ion-align-self-center">
                <IonButton color="light" size="large" expand="block" onClick={ () => { this.props.history.push('/app/prescription/nre')} } >
                  Ho il codice <br/>  della ricetta
                  <IonIcon icon={ go } slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className=" ion-padding-bottom">
              <IonCol className="ion-align-self-center">
              <IonButton color="light" size="large" expand="block"  onClick={ () => { this.props.history.push('/app/prescription/file')} } >
                Scatta una Foto <br/> carica un File
                <IonIcon icon={ go } slot="end" />
              </IonButton>
              </IonCol>
            </IonRow>
            {/*<IonRow className=" ion-padding-bottom">
              <IonCol className="ion-align-self-center">
              <IonButton color="light" size="large" expand="block"  onClick={ () => { this.props.history.push('/app/prescription/vetnre')} } >
                Ricetta veterinaria <br/> numero e pin <IonIcon icon={ go } />
              </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className=" ion-padding-bottom">
              <IonCol className="ion-align-self-center">
              <IonButton color="light" size="large" expand="block"  onClick={ () => { this.props.history.push('/app/prescription/vetfile')} } >
                Ricetta veterinaria <br/> carica un File <IonIcon icon={ go } />
              </IonButton>
              </IonCol>
            </IonRow> */ }
          </IonGrid>
        </IonContent>

      </>
    );
  }
  
}

export default withIonLifeCycle(PrescriptionPage);