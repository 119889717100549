import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButton,
  IonButtons,
  IonToggle,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import OptionService from '../services/option';
import ProfileService from '../services/profile';
import PrivacyService from '../services/privacy';
import moment from 'moment';
import localization from 'moment/locale/it';
import SystemResource from '../resources/System';

export default class PrivacyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacy: {},
      toggle: {
        uno: '',
        due: '',
        tre: '',
      },
      profile: '',
    };

    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();
    this.reftutti = React.createRef();

    this.checkPrivacy = this.checkPrivacy.bind(this);
  }

  async componentDidMount() {
    await this.updateData();
  }

  async updateData(){
    let { data: { data: profile },} = await ProfileService.me();
    this.setState({ profile });
    try {
      let data = await OptionService.get('privacycheck');
      this.setState({ privacy: data });
    } catch (error) {
      console.error(error);
    }
  }

  async toggleSwitch(n){
    switch (n) {
      case 0:
        this.reftutti.current.checked = !this.reftutti.current.checked;
        this.ref1.current.checked = this.reftutti.current.checked
        this.ref2.current.checked = this.reftutti.current.checked
        this.ref3.current.checked = this.reftutti.current.checked
        break;
      case 1:
        this.ref1.current.checked = !this.ref1.current.checked;
        break;
      case 2:
        this.ref2.current.checked = !this.ref2.current.checked;
        break;     
        
      case 3:
        this.ref3.current.checked = !this.ref3.current.checked;
        break;
      
      default:
        break;
    }
  }

  async checkPrivacy(privacy1, privacy2, privacy3) {
    try {
      if (this.state.privacy.check1 !== null && this.ref1.current.checked === false) {
        if (privacy1 === true) {
          await PrivacyService.check(privacy1, privacy2, privacy3, this.state.profile.id);
          this.props.toast.current.add({
            message: `Consensi privacy aggiornati`,
            duration: 3000,
          });

        } else {
          if (this.ref1.current.checked === false) {
            const message = document.getElementById('err1');
            message.innerHTML = "<span style='color: red;'>Questo consenso &egrave; obbligatorio</>";
          } else {
            const message = document.getElementById('err1');
            message.innerHTML = '';
          }
        }
      } else if (this.state.privacy.check2 !== null && this.ref2.current.checked === false) {
        if (privacy2 === true) {
          await PrivacyService.check(privacy1, privacy2, privacy3, this.state.profile.id);

          this.props.toast.current.add({
            message: `Consensi privacy aggiornati`,
            duration: 3000,
          });
        } else {
          if (this.ref2.current.checked === false) {
            const message = document.getElementById('err2');
            message.innerHTML = "<span style='color: red;'>Questo consenso &egrave; obbligatorio</>";
          } else {
            const message = document.getElementById('err2');
            message.innerHTML = '';
          }
        }
      } else if (this.state.privacy.check3 !== null && this.ref3.current.checked === false) {
        if (privacy2 === true) {
          await PrivacyService.check(privacy1, privacy2, privacy3, this.state.profile.id);

          this.props.toast.current.add({
            message: `Consensi privacy aggiornati`,
            duration: 3000,
          });

        } else {
          if (this.ref3.current.checked === false) {
            const message = document.getElementById('err3');
            message.innerHTML = "<span style='color: red;'>Questo consenso &egrave; obbligatorio</>";
          } else {
            const message = document.getElementById('err3');
            message.innerHTML = '';
          }
        }
      } else {
        await PrivacyService.check(privacy1, privacy2, privacy3, this.state.profile.id);

        this.props.toast.current.add({
          message: `Consensi privacy aggiornati`,
          duration: 3000,
        });
        document.getElementById('err1').innerHTML = '';
        document.getElementById('err2').innerHTML = '';
        document.getElementById('err3').innerHTML = '';
      }
      await this.updateData();
      await SystemResource.privacyCheck();

    } catch (error) {
      console.error(error);
    }
  }

  async onChange(check) {
    if (check) {
      this.ref1.current.checked = true;
      this.ref2.current.checked = true;
      this.ref3.current.checked = true;
    } else {
      this.ref1.current.checked = false;
      this.ref2.current.checked = false;
      this.ref3.current.checked = false;
    }
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonTitle>Gestione dei consensi privacy</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-margin ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonText>
                  <h1>Consensi privacy</h1>
                  <h3>
                    <b>Accetta i consensi</b>
                  </h3>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid>
            {this.state.privacy.uno !== null ? (
              <IonRow>
                <IonCol size="2">
                  <p>
                    <IonToggle
                      id="privacytutti"
                      ref={this.reftutti}
                      onClick={() => this.onChange(this.reftutti.current.checked)}
                    />
                  </p>
                </IonCol>
                <IonCol size="10">
                  <IonText onClick={() =>this.toggleSwitch(0)}>
                    <h1>Accetta tutto</h1>
                  </IonText>
                </IonCol>
              </IonRow>
            ) : (
              ''
            )}

            {this.state.privacy.uno !== null ? (
              <IonRow>
                <IonCol size="2">
                  <IonToggle id="privacy1" ref={this.ref1} />
                </IonCol>
                <IonCol size="10">
                  <IonText onClick={() =>this.toggleSwitch(1)}>
                    <div dangerouslySetInnerHTML={{ __html: this.state.privacy.uno }} />
                    { !!this.state.profile.privacy1date && this.state.profile.privacy1 === 1 ?
                      <sub><i>Accettato il {moment(this.state.profile.privacy1date).locale('it', localization).format('lll')}</i></sub>
                      : <></>
                    }
                    <p id="err1" />
                  </IonText>
                </IonCol>
              </IonRow>
            ) : (
              ''
            )}

            {this.state.privacy.due !== null ? (
              <IonRow>
                <IonCol size="2">
                  <IonToggle id="privacy2" ref={this.ref2} />
                </IonCol>
                <IonCol size="10">
                  <IonText onClick={() =>this.toggleSwitch(2)}>
                    <div dangerouslySetInnerHTML={{ __html: this.state.privacy.due }} />
                    { !!this.state.profile.privacy2date && this.state.profile.privacy2 === 1 ?
                      <sub><i>Accettato il {moment(this.state.profile.privacy2date).locale('it', localization).format('lll')}</i></sub>
                      : <></>
                    }
                    <p id="err2" />
                    
                  </IonText>
                </IonCol>
              </IonRow>
            ) : (
              ''
            )}

            {this.state.privacy.tre !== null ? (
              <IonRow>
                <IonCol size="2">
                  <IonToggle id="privacy3" ref={this.ref3} />
                </IonCol>
                <IonCol size="10">
                  <IonText onClick={() =>this.toggleSwitch(3)} >
                    <div dangerouslySetInnerHTML={{ __html: this.state.privacy.tre }} />
                    { !!this.state.profile.privacy3date && this.state.profile.privacy3 === 1 ?
                      <sub><i>Accettato il {moment(this.state.profile.privacy3date).locale('it', localization).format('lll')}</i></sub>
                      : <></>
                    }
                    <p id="err3" />
                  </IonText>
                </IonCol>
              </IonRow>
            ) : (
              ''
            )}
          </IonGrid>

          <IonRow>
            <IonCol size="12">
              <IonText>*Consenso obbligatorio</IonText>
            </IonCol>
          </IonRow>

          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <p>
                  <IonText>
                    Confermo di avere ricevuto informazioni chiara ed esaustive relative alle modalità, finalità e conformità del trattamento dei dati personali di cui alla normativa di riferimento 
                    <br/>
                    <br/>
                    Consulta <Link to="/app/tos">termini e condizioni d'uso del servizio</Link> e <Link to="/app/privacy">l'informativa della privacy</Link>
                  </IonText>
                </p>
              </IonCol>
            </IonRow>

            <IonButton
              expand="block"
              onClick={() =>
                this.checkPrivacy(this.ref1.current.checked, this.ref2.current.checked, this.ref3.current.checked)
              }
            >
              Procedi
            </IonButton>
          </IonGrid>
        </IonContent>
      </>
    );
  }
}
