import React, { Component } from 'react';

import { IonHeader, IonContent, IonTitle, IonToolbar, IonImg, IonIcon } from '@ionic/react';
import { globeOutline, wifiOutline, cellularOutline } from 'ionicons/icons';


export default class HomePage extends Component {

  async componentDidMount() {

    
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="small">
              <IonImg 
                src={`/assets/logo.png`}
                style= { { width: '70%', maxWidth: '200px' } } /> 
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-margin ion-padding">    
          <h1><IonIcon icon={ globeOutline } /> In attesa di una connessione ad internet.</h1>
        
           <p>
            Sembra che non sia disponibile una connessione ad Internet.<br/>
            Questa App non può funzionare senza connessione ad Internet<br/>
            Verifica la connessione ad una rete WiFi <IonIcon icon={ wifiOutline } /> oppure attiva la connessione Dati <IonIcon icon={ cellularOutline } /><br/>
            <br/>
            L'App riprenderà a funzionare automaticamente quando sarà ripristinata la connessione ad internet.<br/>
            </p>
            <p>Se il problema persiste segnala l'errore alla farmacia</p>
        </IonContent>

      </>
    );
  }
}
