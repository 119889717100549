import {useState} from 'react'
import Promotions from '../../services/promotions';

const usePromotions = (param, def = null ) => {
    const [promotions, setPromotions] = useState()
    const [promotionsLoading, setPromotionsLoading] = useState()
    const [promotionsError, setPromotionsError] = useState()

    const loadPromotions = async () => {

        try { 
            setPromotionsLoading(true)
            const resp = await Promotions.getAllPromotions();
            // console.log(resp.data.result);
            setPromotions(resp.data.result);
        } catch(error) {
            setPromotionsError(error)
        } finally {
            setPromotionsLoading(false)
        }
    }

    return [promotions, promotionsLoading, promotionsError, loadPromotions]
}

export default usePromotions