import EventEmitter from 'events';

import jwt from 'jsonwebtoken';

import AuthService from '../services/auth';

const service = AuthService;

class Auth extends EventEmitter {

  constructor() {
    super();

    (async() => {
      await this.activeRrefresh();
    })();
  }

  get token() {
    return localStorage.getItem('auth');
  }

  get service() {
    return service;
  }

  get isLogged() {
    if(!!!this.token) {
      return false;
    }
    return (async () => {
      try {
        return await this.check()
      } catch (error) {
        return false;
      }
    })();
  }

  async check() {  
    return await AuthService.check();
  }

  async activeRrefresh() {
    if (!!!this.token) {
      return;
    }

    const token = jwt.decode(this.token);
    const expire = new Date(token.exp * 1000);
    const current = new Date();
    const delta = Math.round((expire.valueOf() - current.valueOf()) * 0.8);
    
    setTimeout(async () => {
      const {data: {token}} = await this.service.refresh();
      await localStorage.setItem('auth', token);
      console.log('Token refreshed.');
      this.activeRrefresh();
    }, delta);
    console.log(`Set refresh token at ${new Date(current.valueOf()+delta)}`);
  }

  async login(email, password) {
    let response = await AuthService.login(email, password);
    let token = response.data.token;
    await localStorage.setItem('auth', token);
    this.emit('auth:login');
    return response;
  }

  async logout() {
    try {
      await AuthService.logout();
    } catch (error) {
      console.warn(error);
    }
    await localStorage.clear()
    this.emit('auth:logout');
  }


}

const authResource = new Auth();


export default authResource;