import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { IonInput, IonItem, IonLabel, IonList, IonIcon } from '@ionic/react';
import { IonText, IonCheckbox, IonButton } from '@ionic/react';

import Auth from '../services/auth';
import ErrorLog from '../services/errorlog';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import SystemResource from '../resources/System';

export default class Signup extends Component {

  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.state = {

      name: undefined,
      codice_fiscale: undefined,
      phone_number: undefined,
      email: undefined,
      password: undefined,
      password_confirmation: undefined,
      privacy: false,
      errors: {},
      eyeIconStatus: false,

      // name: 'Babioboi',
      // codice_fiscale: 'STTCRL86E15I452L',
      // phone_number: '393288793229',
      // email: 'borraccetti2@gmail.com',
      // password: 'password',
      // password_confirmation: 'password',
      // privacy: true,
      // referral: 'lupo33'

    };

    this.inputHandler = this.inputHandler.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.onSignup = this.onSignup.bind(this);
    this.toggleEyeIconStatus = this.toggleEyeIconStatus.bind(this);
  }

  async inputHandler(event) {
    let errors = this.state.errors;
    delete errors[event.target.name];

    this.form.current[event.target.name].setCustomValidity('');
    // this.form.current[event.target.name].reportValidity();

    let value = event.target.value

    await this.setState({
      [event.target.name]: value,
      errors,
    });
  }

  async toggleEyeIconStatus(){
    this.setState( { eyeIconStatus: !this.state.eyeIconStatus } );
  }

  async handleValidation(event) {
    let errors = this.state.errors;
    let error = !!event.target ? event.target.firstChild.validationMessage : event.firstChild.validationMessage;
    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];

    await this.setState({ errors });

    if(!!error) {
      errors[name] = error;
      await this.setState({ errors, disabled: true });
      return;
    }

    await this.setState({ disabled: false });
  }

  handleFormValidation(e) {
    if(!!!this.form.current.reportValidity()) {
      this.handleValidation(this.form.current.name.parentElement);
      this.handleValidation(this.form.current.codice_fiscale.parentElement);
      this.handleValidation(this.form.current.phone_number.parentElement);
      this.handleValidation(this.form.current.email.parentElement);
      this.handleValidation(this.form.current.password.parentElement);
      this.handleValidation(this.form.current.password_confirmation.parentElement);
    }
  }

  async onSignup(e) {
    e.preventDefault();

    let [name, email, password, password_confirmation, codice_fiscale, phone_number, privacy, referral] = [
      e.target.name.value,
      e.target.email.value,
      e.target.password.value,
      e.target.password_confirmation.value,
      (`${e.target.codice_fiscale.value}`).toUpperCase(),
      e.target.phone_number.value.replaceAll(" ","").replaceAll("-",""),
      e.target.privacy.value,
      e.target.referral.value,
    ];

    try {
      this.props.loader.current.show();
      let response = await Auth.register(name, email, password, password_confirmation, codice_fiscale, phone_number, privacy, referral);
      await localStorage.setItem('auth', response.data.token);

      if(!!this.props.onSubmit) {
        this.props.onSubmit(null, response);
      };

    } catch (error) {
      if ( !!error.response && !!error.response.data.error ) {
        let response = error.response.data.error;
        for (const key in response) {
          if (response.hasOwnProperty(key)) {
            if (key === 'privacy') {
              await this.setState({ errors: {privacy: response[key][0]}});
              continue;
            }
            if(!!this.form.current[key]){
              this.form.current[key].setCustomValidity('');
              this.form.current[key].setCustomValidity(response[key]);
              this.form.current[key].reportValidity();
              this.handleValidation({ target: this.form.current[key].parentElement });
            }
          }
        }
      }
      ErrorLog.send(error);
      this.props.onSubmit(error);

    } finally {
      if(!!this.props.loader && !!this.props.loader.current ){
        this.props.loader.current.dismiss();
      }
      await SystemResource.privacyCheck();
    }

  }

  render() {
    const {errors} = this.state;

    return (
      <form ref={ this.form } onSubmit={this.onSignup} >
        <IonList lines="full" className="ion-no-margin ion-no-padding">

          <IonItem>
            <IonLabel  color={ !!errors.name ? 'danger' : null} position="floating">Nome e cognome</IonLabel>
            <IonInput type="text" name="name"
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              value={ this.state.name }
              required />
            {
              !!errors.name
                ? <IonText color="danger">
                    { errors.name }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel  color={ !!errors.codice_fiscale ? 'danger' : null} position="floating">Codice fiscale</IonLabel>
            <IonInput type="text" name="codice_fiscale" required
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              minlength={16} maxlength={16}
              pattern="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$"
              style={{textTransform: 'uppercase'}}
              value={ this.state.codice_fiscale }
              />
            {
              !!errors.codice_fiscale
                ? <IonText color="danger">
                    { errors.codice_fiscale }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel color={ !!errors.phone_number ? 'danger' : null} position="floating">Numero di telefono
            <small>&nbsp; Esempio:39 320 1234567</small></IonLabel>
            <IonInput type="tel" name="phone_number" required
              placeholder="39 320 1234567"
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              pattern="\+?[0-9]{2}[\s|\-]?[0-9]{3}[\s|\-]?[0-9]{7}"
              value={ this.state.phone_number }
              />
            {
              !!errors.phone_number
                ? <IonText color="danger">
                    { errors.phone_number }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel  color={ !!errors.email ? 'danger' : null} position="floating">Email</IonLabel>
            <IonInput type="email" name="email"
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              ref={ this.email }
              value={ this.state.email }
              required />
            {
              !!errors.email
                ? <IonText color="danger">
                    { errors.email }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel  color={ !!errors.password ? 'danger' : null} position="floating">Password</IonLabel>
            <IonInput 
              type={ !this.state.eyeIconStatus? 'password' : 'text'} 
              name="password"
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              value={ this.state.password }
              required />
            <IonIcon className="ion-padding-top" onClick={ () => this.toggleEyeIconStatus() } icon={ this.state.eyeIconStatus? eyeOffOutline:eyeOutline } slot='end' ></IonIcon>
            {
              !!errors.password
                ? <IonText color="danger">
                    { errors.password }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel  color={ !!errors.password_confirmation ? 'danger' : null} position="floating">Conferma password</IonLabel>
            <IonInput 
              type={ !this.state.eyeIconStatus? 'password' : 'text'} 
              name="password_confirmation"
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              value={ this.state.password_confirmation }
              required />
            {
              !!errors.password_confirmation
                ? <IonText color="danger">
                    { errors.password_confirmation }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem lines="none">
            <IonLabel  color={ !!errors.privacy ? 'danger' : null} >Accetto i termini d'utilizzo dell'app</IonLabel>
            <IonCheckbox slot="start" name="privacy" checked={ this.state.privacy } onIonChange={
              e => this.setState({ privacy: !this.state.privacy, errors: {privacy: undefined}})
            } />
          </IonItem>

          <IonItem>
            <IonText className="ion-margin-bottom">
              {
                !!errors.privacy
                  ? <IonText className="ion-margin-bottom" color="danger">
                      { errors.privacy }
                      <br/>
                    </IonText>
                  : null
              }
              <IonText className="ion-text-small">
                Accetto i <Link to="/app/tos">termini di utilizzo</Link> e acconsento al trattamento dei miei dati personali per le finalità del servizio.
              </IonText>
            </IonText>
          </IonItem>

          <IonItem>
            <IonLabel  color={ !!errors.referral ? 'danger' : null} position="floating">Codice Invito
            <small>&nbsp; se possiedi un codice sconto inseriscilo (facoltativo)</small></IonLabel>
            <IonInput type="text" name="referral"
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              value={ this.state.referral } />
            {
              !!errors.referral
                ? <IonText color="danger">
                    { errors.referral }
                  </IonText>
                : null
            }
          </IonItem>

        </IonList>

        <div className="ion-padding">
          <IonButton expand="block" type="submit" className="ion-no-margin" onClick={ this.handleFormValidation }>
            Registrati
          </IonButton>
        </div>

      </form>
    );
  }
}
