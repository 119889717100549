import React, { Component } from 'react';

import { IonButton, IonText, IonIcon } from '@ionic/react';
import { IonList, IonItem, IonLabel } from '@ionic/react';
import { IonInput } from '@ionic/react';

import { addCircleOutline as add, saveOutline as save, trashBinOutline as remove } from 'ionicons/icons';
import FiscalcodeForm from '../services/fiscalcode';

import '../theme/container.css'

export default class AddressesForm extends Component {

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.codice_fiscale = React.createRef();

    this.state = {
      id: undefined,
      codice_fiscale: undefined,
      main: undefined,

      errors: {},
    }

    this.inputHandler = this.inputHandler.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.removeFiscsalcodes = this.removeFiscsalcodes.bind(this);
    this.setFiscalcode = this.setFiscalcode.bind(this);
  }

  async componentDidMount() {
    if (!!this.props.id) {
      await this.setState({ id: this.props.id });
      let { data: fiscalcode } = await FiscalcodeForm.get(this.state.id);
      await this.setState(fiscalcode);
      return;
    }
  }

  async inputHandler(event) {
    let errors = this.state.errors;
    delete errors[event.target.name];

    let value = event.target.value

    /*if (event.target.uppercase && !!value) {
      value = value.toUpperCase();
    }*/

    await this.setState({
      [event.target.name]: value,
      errors,
    });
  }

  async handleValidation(event) {
    let errors = this.state.errors;
    let error = !!event.target ? event.target.firstChild.validationMessage : event.firstChild.validationMessage;
    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];
    await this.setState({ errors });

    if(!!error) {
      errors[name] = error;
      await this.setState({ errors });
    }
  }

  handleFormValidation(e) {
    if(!!!this.form.current.reportValidity()) {
       this.handleValidation(this.codice_fiscale.current);
    }
  }

  async removeFiscsalcodes() {
    await FiscalcodeForm.remove(this.props.id);
    if(this.props.onSubmit && (typeof this.props.onSubmit === 'function')) {
      this.props.onSubmit();
    }
  }

  async setFiscalcode(e) {

    e.preventDefault();

    let fiscalcode  = {
      codice_fiscale: this.state.codice_fiscale.toUpperCase()
    }

    try {
      if(!!this.state.id) {
        fiscalcode = (await FiscalcodeForm.update(this.state.id, fiscalcode)).data;
      } else {
        fiscalcode = (await FiscalcodeForm.create(fiscalcode)).data;
      }
  
      await this.setState(fiscalcode);
    
      if(this.props.onSubmit && (typeof this.props.onSubmit === 'function')) {
        this.props.onSubmit(fiscalcode);
      }
    } catch (error) {
      // 
      if (!!error?.response?.data?.error) {
        let errors = this.state.errors;
        let data = error?.response?.data?.error

        for (const name in data) {
          if (data.hasOwnProperty(name)) {
            const messages = data[name].pop();
            errors[name] = messages;            
          }
        }

        await this.setState({ errors });
        return;
      }

      console.error(error);
    }
  }


  render() {
    const { errors } = this.state;

    return (
      <form ref={ this.form } onSubmit={ this.setFiscalcode } >
        <IonList lines="full" className="ion-no-margin ion-no-padding">

          <IonItem>
            <IonLabel color={ !!errors.codice_fiscale ? 'danger' : null} position="floating">Codice fiscale<IonText color="danger">*</IonText></IonLabel>
            <IonInput type="text" name="codice_fiscale" required
              ref={ this.codice_fiscale }
              minlength={16} maxlength={16}
              pattern="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$"
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler } value={ this.state.codice_fiscale }
              uppercase
              style={{textTransform: 'uppercase'}}
              />
              
            {
              !!errors.codice_fiscale
                ? <IonText color="danger">
                    { errors.codice_fiscale }
                  </IonText>
                : null
            }
          </IonItem>

          {
            !!!this.state.system
              ? <div className="ion-padding">                   
                  <IonButton expand="block" type="submit" className="ion-margin-bottom" onClick={ this.handleFormValidation }>
                    {
                      !!this.state.id
                        ? <><IonIcon slot="start" icon={ save } /> Aggiorna</>
                        : <><IonIcon slot="start" icon={ add } /> Aggiungi</>
                    }              
                  </IonButton>

                  {
                    !!this.state.id && !this.state.main
                      ?  <IonButton expand="block" color="warning" className="ion-margin-bottom" onClick={ this.removeFiscsalcodes }>
                          <IonIcon slot="start" icon={ remove } /> Cancella
                        </IonButton>
                      : 
                      <IonText>
                      Il presente codice fiscale è associato all'utente dell'applicazione e ai relativi consensi in materia di privacy, pertanto, la sua cancellazione non è possibile.
                      <br />
                      </IonText>
                  }

                  
                </div>
              : null
          }
          

        </IonList>
      </form>
    );
  }
}