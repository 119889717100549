import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel } from '@ionic/react';
import { IonButtons, IonButton, IonBackButton, IonIcon } from '@ionic/react';
import { IonList } from '@ionic/react';

import { addOutline as add } from 'ionicons/icons';

import FiscalcodeService from '../services/fiscalcode';

class FiscalcodesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fiscalcodes: [],
    };

    this.getAddresses = this.getAddresses.bind(this);
  }

  ionViewWillEnter() {
    this.getAddresses();
  }

  async getAddresses() {
    let {
      data: { data: fiscalcodes },
    } = await FiscalcodeService.get();

    await this.setState({ fiscalcodes });
  }

  render() {
    const { history } = this.props;
    const { fiscalcodes } = this.state;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/auth/login" />
            </IonButtons>

            <IonTitle>Codice fiscale</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            {!!fiscalcodes.length ? (
              <>
                {fiscalcodes.map((fiscalcode) => (
                  <IonItem
                    key={fiscalcode.id}
                    detail
                    button
                    onClick={() => {
                      history.push(`/app/fiscalcode/${fiscalcode.id}`);
                    }}
                  >
                    <IonLabel>
                      <strong>{fiscalcode.codice_fiscale}</strong>
                    </IonLabel>
                  </IonItem>
                ))}
              </>
            ) : (
              <IonItem>
                <IonLabel>
                  <p>Nessun Codice Fiscale presente</p>
                </IonLabel>
              </IonItem>
            )}

            <div className="ion-padding">
              <IonButton expand="full" onClick={() => history.push('/app/fiscalcode')}>
                <IonIcon slot="start" icon={add} /> Aggiungi codice fiscale
              </IonButton>
            </div>
          </IonList>
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(FiscalcodesPage);
