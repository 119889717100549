import axios from './axios';

import auth from '../resources/Auth';

export default {
  get: async (id = undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/shopservices${!!id ? `/${id}` : ''}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
  },

  create: async (shopservice) => {
    let url = `${process.env.REACT_APP_API_URL}/shopservices`;

    return await axios.post(url, shopservice, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
  },
};
