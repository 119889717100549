import axios from './axios';

import auth from '../resources/Auth';

export default {
  get: async (id = undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/fidelityCard${!!id ? `/${id}` : ''}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
  },
  getMain: async (id = undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/fidelityCard${!!id ? `/${id}` : ''}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
  },
};
