import React from 'react'
import { IonIcon, IonButton, IonItem, IonLabel, IonThumbnail, IonImg,IonText } from '@ionic/react';
import Price from '../Price';
import { cartOutline as cart } from 'ionicons/icons';

const Item = ({prod, history, addProductToCart}) => {
  return (
    
    <IonItem >
                  
        <IonThumbnail slot="start">
            <IonImg src={ ( prod.immagine && prod.immagine !== "" ) ?`https://static.fogliettoillustrativo.net/ws/it/${prod.immagine}`:`/assets/prodotto_generico_125.png` } onClick={ () => history.push(`/app/product/${prod.codice_ministeriale}`) } />
        </IonThumbnail>
        <IonLabel onClick={ () => history.push(`/app/product/${prod.codice_ministeriale}`) }>
            <h3 className="ion-text-capitalize"><b>{ prod.nome_prodotto }</b></h3>
            <p className="ion-text-capitalize">{ prod.descrizione_ditta_1_produttrice }</p>
            <p className="price" >
                <IonText color="success">
                <Price price={prod.prezzo}/>
                <span className="euro-symbol">€</span>
                </IonText>
                {/* { PREZZO SCONTATO
                prod.prezzo_bancadati <= prod.prezzo
                    ? null
                    : <>&nbsp;<span className="mainprice">
                        <Price price={prod.prezzo_bancadati}/>
                        <span className="euro-symbol">€</span>
                    </span></>
                }
                
                { prod.prezzo_bancadati > 0 && Math.round((prod.prezzo_bancadati-prod.prezzo)/prod.prezzo_bancadati*100)>5
                ? <IonText color="danger">&nbsp; Sconto {Math.round((prod.prezzo_bancadati-prod.prezzo)/prod.prezzo_bancadati*100)}%</IonText>
                : null
                } */}
                &nbsp;
                { prod.quantita > 0
                ?<span>Disponibile</span>
                :<span>Da ordinare</span>
                }
            </p>
        </IonLabel>
        <IonButton slot="end" color="light" onClick={ () => addProductToCart(prod.codice_ministeriale, prod.nome_prodotto) } size="default">
            <IonIcon slot="icon-only" icon={ cart } />
        </IonButton>
    </IonItem>






  )
}

export default Item