import React from 'react'
import { IonSkeletonText, IonIcon } from '@ionic/react';
import { cartOutline as cart } from 'ionicons/icons';

const Skeleton = () => {
  return (
    <>      
        <IonSkeletonText animated style={{ height: '250px' }}/>
        <ion-col col-4><IonIcon slot="icon-only" icon={ cart } /></ion-col>
        <div>
            <IonSkeletonText animated style={{ width: '100%' }} /><br/>
            <div className="ion-padding">
                <IonSkeletonText animated style={{ width: '60%' }} /><br/>
                <IonSkeletonText animated /><br/>
                <IonSkeletonText animated style={{ width: '88%' }} /><br/>
                <IonSkeletonText animated style={{ width: '70%' }} /><br/>
            </div>
        </div>
    </>
  )
}

export default Skeleton