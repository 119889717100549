import {useState} from 'react'
// import ShopServiceService from '../../services/shopservice';
import SearchService from '../../services/product';

const useSearchService = () => {
    const [searchServices, setSearchServices] = useState()
    const [searchServicesLoading, setSearchServicesLoading] = useState()
    // const [searchServicesError, setSearchServicesError] = useState()

    const loadSearchServices = async (codiceMinisteriale) => {
        try { 
            setSearchServicesLoading(true)
            const resp = await SearchService.get(codiceMinisteriale);
            setSearchServices(resp.data);
        } catch(error) {
            // setSearchServicesError(error)
        } finally {
            setSearchServicesLoading(false)
        }
    }

    // return [services, servicesLoading, servicesError, loadServices]
    return [searchServices, searchServicesLoading, loadSearchServices]
    // return [searchServices, loadSearchServices]
}

export default useSearchService