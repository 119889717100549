
import { messaging } from '../../firebase';
import NotificationService from '../../services/notification';

let token;

export default class Push {

  // constructor() {
  //   // messaging.onMessage(payload => {
  //   //   console.log("Message received. ", payload);
  //   //   const { title, ...options } = payload.data;
  //   // });

  //   // messaging.onTokenRefresh(async () => {
  //   //   await this.active('push');
  //   // });
  // }

  get service() {
    return NotificationService;
  }

  get messaging() {
    return messaging;
  }

  async requestPermission() {
    return await this.messaging.requestPermission();
  }

  async status() {
    try {
      let { data: status } = await this.service.get('push');
      return status.active;
    } catch (error) {
      console.error(error);
      return false;
    }   
  }

  async active() {
    if (Notification.permission !== 'granted') {
      await this.messaging.requestPermission();
    }
  
    token = await this.messaging.getToken();  
    await this.service.active('push', { token });
  
    localStorage.setItem('notification_token', token);
    return token;
  }

  async disable() {
    await this.service.disable('push');
    token = undefined;
    localStorage.removeItem('notification_token');
  }

}



// Auth.on('auth:login', async () => {
//   try {
//     await this.active('push');
//   } catch (error) {
//     console.log(error);
//   }
// });

// Auth.on('auth:logout', () => {
//   localStorage.removeItem('notification_token');
// });

// (async() => {
//   // token = await this.messaging.getToken();
//   console.log( token ); 
// })();

