import React, { Component } from 'react';
import SystemResource from '../resources/System';
import HeaderButtons from '../components/HeaderButtons';
import { withIonLifeCycle } from '@ionic/react';
import moment from 'moment';
import { IonHeader, IonToolbar, IonBackButton, IonContent, IonTitle, IonButtons } from '@ionic/react';

import Calendar from '../components/Calendar';

class ReservationPage extends Component {
  constructor(props) {
    super(props);

    this.loader = React.createRef();

    this.state = {
      momentDate: moment(),
      isEventPage: this.isEventPage(),
    };
  }

  async ionViewWillEnter() {
    if (!!this.props.loader) this.props.loader.current.show();
    try {
      SystemResource.checkVersion();
    } catch (error) {
      console.error(error);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  isEventPage() {
    const { path } = this.props.match.params;
    return path === 'event';
  }
  render() {
    const { momentDate, isEventPage } = this.state;

    const handleNextMonth = () => {
      let momentClone = momentDate.clone().add(1, 'M');

      if (momentClone.format('YYMM') <= moment().add(2, 'M').format('YYMM')) {
        this.setState({ momentDate: momentDate.add(1, 'M') });
      }
    };

    const handlePrevMonth = () => {
      let momentClone = momentDate.clone().subtract(1, 'M');

      if (momentClone.format('YYMM') >= moment().format('YYMM')) {
        this.setState({ momentDate: momentDate.subtract(1, 'M') });
      }
    };

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              {isEventPage ? (
                <IonBackButton defaultHref="/app/events" />
              ) : (
                <IonBackButton defaultHref="/app/services" />
              )}
            </IonButtons>
            <HeaderButtons {...this.props} />
            <IonTitle>Prenotazioni</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent lines="full">
          <Calendar
            momentDate={momentDate}
            serviceId={this.props.match.params.id}
            nextMonth={handleNextMonth}
            prevMonth={handlePrevMonth}
            {...this.props}
          />
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(ReservationPage);
