import axios from './axios';
import _axios from 'axios';

export default {
  
  login: async (email, password) => {
    let url = `${process.env.REACT_APP_API_URL}/auth/login`;

    if(/^-?\d+$/.test(email)){
      email = parseInt(email) +"@phonenumber";
    }
    return await axios.post(url, {
      email, password
    });
  },

  recovery: async (email) => {
    let url = `${process.env.REACT_APP_API_URL}/auth/recovery`;

    return await axios.post(url, {
      email
    });
  },

  reset: async ({...body}) => {
    let url = `${process.env.REACT_APP_API_URL}/auth/reset`;

    return await axios.post(url, {...body});
  },


  register: async (name, email, password, password_confirmation, codice_fiscale, phone_number, privacy, referral) => {
    let url = `${process.env.REACT_APP_API_URL}/auth/register`;

    phone_number.replace('+', '');
    return await axios.post(url, {
      name, email, password, password_confirmation, codice_fiscale, phone_number, privacy, referral
    });
  },

  check: async () => {
    let url = `${process.env.REACT_APP_API_URL}/auth/check`;
    let token = localStorage.getItem('auth');

    return await _axios.post(url, {}, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 2000,
    });
  },

  refresh: async () => {
    let url = `${process.env.REACT_APP_API_URL}/auth/refresh`;
    let token = localStorage.getItem('auth');

    return await axios.post(url, {}, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 2000,
    });
  },

  logout: async () => {
    let url = `${process.env.REACT_APP_API_URL}/auth/logout`;
    let token = localStorage.getItem('auth');

    return await _axios.post(url, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

}