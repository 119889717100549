import React, { Component } from 'react';
import { findKey } from 'lodash';

import {
  withIonLifeCycle,
  IonContent,
  IonHeader,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonText,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
} from '@ionic/react';

import {
  search,
  arrowForward as lock,
  addCircleOutline as add,
  caretDownCircleOutline as caretDown,
  caretBackCircleOutline as caretBack,
  closeOutline as close,
  trashOutline as trash,
} from 'ionicons/icons';

import CartResource from '../resources/Cart';
import SystemResource from '../resources/System';

import CartProductItem from '../components/CartProductItem';
import HeaderButtons from '../components/HeaderButtons';
import Price from '../components/Price';

class CartPage extends Component {
  constructor(props) {
    super(props);

    this.loader = React.createRef();

    this.state = {
      cart: undefined,
      prescriptions: [],
      prods: [],
      product_requests: [],
      subTotal: 0,
      togglePrescription: true,
      toggleProducts: true,
      deleteModalIsOpen: false,
    };

    this.loadCart = this.loadCart.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.setProductQuantity = this.setProductQuantity.bind(this);
    this.changeProductQuantity = this.changeProductQuantity.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.togglePrescription = this.togglePrescription.bind(this);
    this.toggleProducts = this.toggleProducts.bind(this);
    this.setPrescription = this.setPrescription.bind(this);
    this.handleRemoveAll = this.handleRemoveAll.bind(this);
  }

  async ionViewWillEnter() {
    if (!!this.props.loader) {
      this.props.loader.current.show();
    }

    try {
      SystemResource.checkVersion();
      await this.loadCart();
    } catch (error) {
      console.error(error);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async ionViewDidEnter() {
    CartResource.on('cart:update', this.loadCart);
  }

  async ionViewWillLeave() {
    CartResource.removeListener('cart:update', this.loadCart);
  }

  async loadCart() {
    if (!!this.props.loader) {
      this.props.loader.current.show();
    }

    try {
      let { data: cart } = await CartResource.current();
      await this.setState({
        cart,
        prescriptions: cart.prescriptions,
        prods: cart.products,
        product_requests: cart.product_requests,
        subTotal: cart.subTotal,
      });
    } catch (error) {
      //console.error(error);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async updateCart(event) {
    await this.loadCart();
    event.detail.complete();
  }

  async changeProductQuantity(code, quantity) {
    let prods = this.state.prods;

    try {
      let prodKey = findKey(prods, { code });
      prods[prodKey].quantity += quantity;
      if (prods[prodKey].quantity <= 0) {
        return this.deleteProduct(code);
      }

      await CartResource.addProduct(code, quantity);
      await this.setState({ prods });
    } catch (error) {
      console.error(error);
    }
  }

  async setProductQuantity(code, quantity) {
    let prods = this.state.prods;

    try {
      let prodKey = findKey(prods, { code });
      prods[prodKey].quantity = quantity;
      if (prods[prodKey].quantity <= 0) {
        return this.deleteProduct(code);
      }

      await CartResource.quantityProduct(code, quantity);
      await this.setState({ prods });
    } catch (error) {
      console.error(error);
    }
  }

  async deleteProduct(code) {
    let prods = this.state.prods;

    try {
      prods = prods.filter((el) => el.code !== code);
      await CartResource.deleteProduct(code);
      await this.setState({ prods });
    } catch (error) {
      console.error(error);
    }
  }

  togglePrescription() {
    this.setState({ togglePrescription: !this.state.togglePrescription });
  }

  toggleProducts() {
    this.setState({ toggleProducts: !this.state.toggleProducts });
  }

  async setPrescription() {
    this.props.history.push('/app/prescription');
  }

  async handleRemoveAll() {
    CartResource.deleteCart(this.state.cart.id);
    this.setState({ deleteModalIsOpen: false });
  }

  render() {
    const { history } = this.props;
    const { prescriptions, togglePrescription, prods, toggleProducts, product_requests, deleteModalIsOpen, subTotal } =
      this.state;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <HeaderButtons {...this.props} />
            <IonTitle>Carrello</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={this.updateCart}>
            <IonRefresherContent
              pullingIcon="arrow-dropdown"
              pullingText="Abbassa per aggiornare"
              refreshingSpinner="circles"
              refreshingText="Aggiornamento..."
            />
          </IonRefresher>

          <IonItem lines="full" onClick={this.togglePrescription}>
            <IonLabel>{prescriptions.length > 0 ? prescriptions.length : ''} Ricette </IonLabel>
            <IonIcon slot="end" icon={!!togglePrescription ? caretDown : caretBack} />
          </IonItem>

          {!!togglePrescription ? (
            <div>
              <div className="">
                {!!prescriptions.length ? (
                  <IonList>
                    {prescriptions.map((prescription, i) =>
                      !!prescription.file || prescription.nre ? (
                        <IonItem
                          key={prescription.id}
                          detail
                          button
                          onClick={() => history.push(`/app/prescription/${prescription.id}`)}
                        >
                          <IonLabel className="">{`Ricetta ${
                            !!prescription.nre ? prescription.nre : `su file '${prescription.file.name}'`
                          }`}</IonLabel>
                        </IonItem>
                      ) : (
                        <IonItem
                          key={prescription.id}
                          detail
                          button
                          onClick={() => history.push(`/app/prescription/${prescription.id}`)}
                        >
                          <IonLabel className="">{`Ricetta Fascicolo Elettronico n°${i + 1}`}</IonLabel>
                        </IonItem>
                      )
                    )}
                  </IonList>
                ) : null}
              </div>
              <div className="ion-padding">
                <IonButton expand="block" color="" onClick={this.setPrescription}>
                  <IonIcon slot="start" icon={add} /> Aggiungi una ricetta
                </IonButton>
              </div>
            </div>
          ) : null}

          <IonItem lines="full" onClick={this.toggleProducts}>
            <IonLabel>{prods.length > 0 ? prods.length : ''} Prodotti nel carrello</IonLabel>
            <IonIcon slot="end" icon={!!toggleProducts ? caretDown : caretBack} />
          </IonItem>

          {!!toggleProducts ? (
            !!prods.length ? (
              <IonList>
                {prods.map((prod) => (
                  <CartProductItem
                    key={prod.code}
                    code={prod.code}
                    amount={prod.amount}
                    quantity={prod.quantity}
                    total={prod.total}
                    changeProductQuantity={this.changeProductQuantity}
                    setProductQuantity={this.setProductQuantity}
                    deleteProduct={this.deleteProduct}
                    history={this.props.history}
                  />
                ))}
              </IonList>
            ) : (
              <div className="ion-padding">
                <IonText>
                  Non ci sono prodotti nel carrello. <br />
                  Fai una ricerca o aggiungi una ricetta elettronica.
                  <IonButton expand="block" color="" onClick={() => history.push('/app/search')}>
                    <IonIcon slot="start" icon={search} /> Aggiungi un prodotto
                  </IonButton>
                </IonText>
              </div>
            )
          ) : null}

          {/*
            //versione con prodotto in carrello che non mi piace
            !!this.state.vouchers.length ? (
              <IonList>
                {this.state.vouchers.map((voucher, key) => (
                  <IonItemSliding key={'voucher' + key}>
                    <IonItemOptions side="start">
                      <IonItemOption color="danger">
                        <IonIcon slot="icon-only" icon={trash} />
                      </IonItemOption>
                    </IonItemOptions>

                    <IonItem lines="full">
                      <IonThumbnail slot="start">
                        <IonImg src="https://www.riteaid.com/coupons/_jcr_content/root/responsivegrid/two_column_tile_1193872347/left/image.coreimg.svg/1584550573582/coupon-policy.svg" />
                      </IonThumbnail>

                      <IonLabel className="ion-text-wrap ion-no-padding">
                        <IonGrid>
                          <IonRow>
                            <IonCol size="7">
                              <IonText>
                                <p className="ion-text-capitalize">Coupon sconto: {voucher.name}</p>
                                <small className="price">
                                  {voucher.description}
                                  <br />
                                </small>
                              </IonText>
                              <small className="price">
                                {!!voucher.discount_amount ? null : (
                                  <>
                                    <span className="mainprice">
                                      -<Price price={voucher.discount_amount} />
                                      <span className="euro-symbol">€</span>
                                    </span>
                                    &nbsp;
                                  </>
                                )}
                              </small>
                            </IonCol>
                            <IonCol className="ion-text-right" size="5">
                              <IonText color="">
                                -<Price price={voucher.discount_amount} />
                              </IonText>
                              <span className="euro-symbol">€</span>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonLabel>
                    </IonItem>
                  </IonItemSliding>
                ))}
              </IonList>
            ) : null
          */}

          {!!product_requests.length ? (
            <IonList>
              {product_requests.map((product_request, i) =>
                product_request.type === 'galenica' ? (
                  <IonItem
                    key={product_request.id}
                    detail
                    button
                    onClick={() => history.push(`/app/galenica/${product_request.id}`)}
                  >
                    <IonLabel className="">{`Preparazione galenica n°${i + 1}`}</IonLabel>
                  </IonItem>
                ) : product_request.type === 'search' ? (
                  <IonItem
                    key={product_request.id}
                    detail
                    button
                    onClick={() => history.push(`/app/productphoto/${product_request.id}`)}
                  >
                    <IonLabel className="">{`Foto di un Prodotto n°${i + 1}`}</IonLabel>
                  </IonItem>
                ) : null
              )}
            </IonList>
          ) : null}

          {!!product_requests.length || !!prescriptions.length || !!prods.length ? (
            <IonGrid>
              <IonRow>
                <IonCol className="ion-text-right" offset="4">
                  <IonButton
                    color="light"
                    onClick={() => this.setState({ deleteModalIsOpen: true })}
                    className="ion-margin-horizontal ion-text-lowercase"
                  >
                    <IonIcon slot="start" icon={trash} /> Svuota carrello
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : null}

          <IonModal isOpen={deleteModalIsOpen}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => this.setState({ deleteModalIsOpen: false })}>
                    <IonIcon icon={close} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Eliminare tutto il carrello?</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <IonItem>
                <IonText>Vuoi davvero eliminare tutto il carrello?</IonText>
              </IonItem>
              <IonItem>
                <IonText>
                  Se presenti, verranno eliminati:
                  <ul>
                    <li>Tutti i prodotti presenti nel carrello;</li>
                    <li>Tutte le ricette presenti nel carrello;</li>
                    <li>Tutte le preparazione Galenica presenti nel carrello;</li>
                  </ul>
                  Si desidera continuare?
                </IonText>
              </IonItem>
              <IonItem>
                <IonText>
                  <b>Attenzione quest'azione è irreversibile</b>
                </IonText>
              </IonItem>
            </IonContent>

            <IonFooter className="ion-padding">
              <IonToolbar>
                <IonGrid>
                  <IonRow>
                    <IonCol className="ion-text-right">
                      <IonButton
                        expand="block"
                        color="danger"
                        onClick={this.handleRemoveAll}
                        className="ion-margin-horizontal"
                      >
                        <IonIcon slot="start" icon={trash} /> Svuota carrello
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonToolbar>
            </IonFooter>
          </IonModal>
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol className="ion-text-right">
                  <IonButton
                    expand="block"
                    color="success"
                    onClick={() => history.push('/app/checkout')}
                    className="ion-margin-horizontal"
                    disabled={!(!!prods.length || !!prescriptions.length || !!product_requests.length)}
                  >
                    <IonIcon slot="start" icon={lock} /> Procedi
                  </IonButton>
                </IonCol>
                <IonCol className="ion-text-right" style={{ paddingTop: '10px' }} size="3">
                  <IonText>Sub totale: </IonText>
                  <IonText color="dark">
                    <Price price={subTotal} />
                  </IonText>
                  <span className="euro-symbol">€</span>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }
}

export default withIonLifeCycle(CartPage);
