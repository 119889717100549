import axios from './axios';
//import CryptoJS from "react-native-crypto-js";

import auth from '../resources/Auth';

export default { 

  get: async() => {
    let url = `${process.env.REACT_APP_API_URL}/payment/methods`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },
  payment: async(type, id=undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/payment/${type}/payment-id/${id}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },
  order: async(id=undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/payment/order/${id}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },
  create: async(type, id=undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/payment/${type}/create/${id}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },
  post: async(url, payload) => {
    return await axios.post(
      url,
      payload
    );
  },
  submit: async(user, paymentCreate, creditcard ) => {
    return await axios.post(
      paymentCreate.submit, 
      {
        shopLogin: paymentCreate.shopLogin,
        buyer:{  
          name: creditcard.holder,
          email: user.email
        },
        paymentTypeDetails:{  
          creditcard:{
            number: creditcard.number,
            expMonth: creditcard.expMonth,
            expYear: creditcard.expYear,
            CVV: creditcard.cvv
          }
        }
      }, 
      {
        headers: 
          { 
            'paymentToken': `${paymentCreate.payload.paymentToken}`,
            'Content-Type': 'application/json'
          }
      }
    );
  },

}