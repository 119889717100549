import React from 'react'
import { IonTitle, IonHeader, IonToolbar } from '@ionic/react';
import { IonBackButton, IonButtons } from '@ionic/react';


const HeaderPromotions = () => {
  return (
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>Promozioni</IonTitle>
        </IonToolbar>
   </IonHeader>
  )
}

export default HeaderPromotions