import React from 'react';


const numberFormat = (value) => new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2}).format(value);
const Price = (props) => <>{numberFormat(props.price)}</>

export default Price



