import React, { Component } from 'react';

import moment from 'moment';
import localization from 'moment/locale/it';

import {
  withIonLifeCycle,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonText,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

import OrderService from '../services/order';

class OrdersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: undefined,
    };
  }

  async ionViewWillEnter() {
    try {
      this.props.loader.current.show();
      const {
        data: { data: orders },
      } = await OrderService.get();

      await this.setState({ orders });
    } catch (error) {
      this.props.loader.current.dismiss();
      console.error(error);
    }

    this.props.loader.current.dismiss();
  }

  render() {
    const { orders } = this.state;

    const printStateLabel = (state) => {
      let text;
      let color = 'primary';

      switch (state) {
        case 'cancelled':
          text = `Annullato`;
          color = 'medium';
          break;

        case 'created':
        case 'managing':
          text = `In preparazione`;
          color = 'primary';
          break;

        case 'finalizing':
          text = `In attesa della tua conferma`;
          color = 'warning';
          break;

        case 'confirmed':
        case 'processing':
          text = `Preparazione alla spedizione`;
          color = 'secondary';
          break;

        case 'shipped':
          text = `In consegna`;
          color = 'tertiary';
          break;

        case 'completed':
          text = `Consegnato`;
          color = 'success';
          break;

        default:
          break;
      }

      return <IonBadge color={color}>{text}</IonBadge>;
    };

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>I miei ordini</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {!!orders && orders.length > 0 ? (
            <IonList>
              {orders
                .sort((a, b) => moment(b.updated_at).subtract(moment(a.updated_at)))
                .map((order, key) => (
                  <IonItem key={key} detail onClick={() => this.props.history.push(`/app/orders/${order.id}`)}>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonText>
                            Ordine n°{order.id} del {moment(order.created_at).locale('it', localization).format('ll')}
                          </IonText>
                          <br />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonText className="ion-text-small">
                            <div>Ricette: {order.prescriptions.length === 1
                            ? `1 ${order.prescriptions[0].codice_fiscale}`
                            : order.prescriptions.length }</div>
                            {!!order.products.length ? <div>Prodotti: {order.totalQuantity}</div> : null}
                          </IonText>
                        </IonCol>
                        <IonCol>{printStateLabel(order.status)}</IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                ))}
            </IonList>
          ) : (
            <IonItem>
              <IonLabel>
                <p>Non sono presenti ordini</p>
              </IonLabel>
            </IonItem>
          )}
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(OrdersPage);
