import React from 'react'
import { IonContent } from '@ionic/react';
import HeadSideProduct from './HeadSideProduct';
import PriceSideProduct from './PriceSideProduct';
import CatSideProduct from './CatSideProduct';
import InfoSideProduct from './InfoSideProduct';
import AddCartSideProduct from './AddCartSideProduct';
import Skeleton from './Skeleton';

function ContentProduct({searchService, fetching, addProductToCart, toggleDescription, setToggleDescription}) {

  return (

    (!searchService?.nome_prodotto) ? 
        <IonContent lines="full" className="ion-margin ion-padding">
            Prodotto non trovato
        </IonContent>
    :
        <IonContent lines="full" className="ion-margin ion-padding">
            {!fetching
                ? <>
                    <HeadSideProduct searchService={searchService} />
                    <PriceSideProduct searchService={searchService} />
                    <CatSideProduct data={searchService?.descrizione_pricipio_attivo} />
                    <CatSideProduct data={searchService?.categoria1} />
                    { !!searchService?.categoria2 &&  
                        <CatSideProduct data={searchService?.categoria2} />
                    }
                    <AddCartSideProduct 
                        addProductToCart={addProductToCart} 
                        codice_ministeriale={searchService?.codice_ministeriale} 
                        nome_prodotto={searchService?.nome_prodotto} 
                    />
                    <InfoSideProduct 
                        searchService={searchService}
                        setToggleDescription={setToggleDescription} 
                        toggleDescription={toggleDescription}
                    />
                </>
                : 
                <Skeleton />
            }     
        </IonContent>

  )
}

export default ContentProduct