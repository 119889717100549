import axios from './axios';
import { DeviceUUID } from 'device-uuid';

const uuid = new DeviceUUID().get();

export default {
  
  get: async (type) => {
    const url = `${process.env.REACT_APP_API_URL}/profile/notification/${type}${!!uuid ? `/${uuid}` : ''}`;
    const token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  active: async (type, {...body}) => {
    const url = `${process.env.REACT_APP_API_URL}/profile/notification/${type}/${uuid}`;
    const token = localStorage.getItem('auth');

    body.type = type;

    return await axios.post(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  disable: async (type) => {
    const url = `${process.env.REACT_APP_API_URL}/profile/notification/${type}/${uuid}`;
    const token = localStorage.getItem('auth');

    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

}