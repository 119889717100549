import React, { Component } from 'react';

import moment from 'moment';
import localization from 'moment/locale/it';

import {
  withIonLifeCycle,
  IonRow,
  IonGrid,
  IonCol,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonText,
  IonThumbnail,
  IonImg,
  IonAlert,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
  IonFooter,
  IonButton,
  IonIcon,
  IonModal,
  IonCheckbox,
} from '@ionic/react';

import {
  checkmarkCircleOutline as ok,
  caretDownCircleOutline as caretDown,
  caretBackCircleOutline as caretBack,
  bagAddOutline as bag,
  closeOutline as close,
} from 'ionicons/icons';

import OrderService from '../services/order';
import SearchService from '../services/product';
import PaymentService from '../services/payment';

import CartResource from '../resources/Cart';

import Price from '../components/Price';

class OrderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      order: undefined,
      products: undefined,
      paymentMethods: undefined,
      toggleProducts: true,
      paymentModal: false,
      showAlertOrderConfirm: false,
      showAlertOrderDeny: false,
    };

    this.confirmOrder = this.confirmOrder.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.toggleProducts = this.toggleProducts.bind(this);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.duplicateOrderProducts = this.duplicateOrderProducts.bind(this);
  }

  async ionViewWillEnter() {
    try {
      this.props.loader.current.show();
      const { data: paymentMethods } = await PaymentService.get(this.props.match.params.id);
      await this.setState({ paymentMethods });

      await this.setState({ id: this.props.match.params.id });
      const { data: order } = await OrderService.get(this.state.id);
      await this.setState({ order });

      let promises = order.products.map((product) => SearchService.get(product.code));
      promises = await Promise.allSettled(promises);

      let products = promises
        .map((product) => (product.status === 'fulfilled' ? product.value.data : undefined))
        .filter((product) => !!product);

      order.products.map((product) => {
        if (products.filter((p) => p.codice_ministeriale === product.code).length <= 0) {
          products.push({
            codice_ministeriale: product.code,
          });
        }

        return product;
      });

      await this.setState({ products });
    } catch (error) {
      console.error(error);
    } finally {
      if (!!this.props.loader) {
        this.props.loader.current.dismiss();
      }
    }
  }

  async setPaymentMethod(event) {
    if (!!!event.detail.checked) {
      await this.setState({ paymentModal: false });
      return;
    }

    const type = event.target.getAttribute('type');
    if (type === this.state.order.paymentType) {
      await this.setState({ paymentModal: false });
      return;
    }

    try {
      const { data: order } = await OrderService.paymentType(this.state.id, type);
      this.setState({ order });
      this.props.toast.current.add({
        message: `Metodo di pagamento aggionato!`,
        duration: 3000,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Non è stato possibile modificare il metodo di pagamento.`,
        color: 'warning',
      });
    } finally {
      await this.setState({ paymentModal: false });
    }
  }

  async confirmOrder() {
    try {
      if (this.state.paymentMethods[this.state.order.paymentType].type === 'payOnline') {
        this.props.history.push(`/app/payment/${this.state.order.paymentType}/${this.state.id}`);
        return;
      }

      const { data: order } = await OrderService.confirm(this.state.id);
      await this.setState({ order });
      this.props.history.push(`/app/order-confirmed/${this.state.id}`);
      return;
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Non è stato possibile confermare l'ordine!`,
        color: 'warning',
      });
    }
  }

  async cancelOrder() {
    try {
      const { data: order } = await OrderService.cancel(this.state.id);
      this.setState({ order });
      this.props.toast.current.add({
        message: `Ordine annullato!`,
        duration: 0,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Non è stato possibile annullare l'ordine!`,
        color: 'warning',
      });
    }
  }

  async duplicateOrderProducts() {
    try {
      await OrderService.duplicate(this.state.id);
      this.props.toast.current.add({
        message: `Prodotti aggiunti al carrello`,
      });

      CartResource.emitUpdateEvent();
    } catch (error) {
      console.error(error.response);
      this.props.toast.current.add({
        message: `Non è stato possibile annullare l'ordine!`,
        color: 'warning',
      });
    }
  }

  toggleProducts() {
    this.setState({ toggleProducts: !this.state.toggleProducts });
  }

  render() {
    const { order, products, paymentMethods, paymentModal, toggleProducts, showAlertOrderDeny, showAlertOrderConfirm } =
      this.state;

    const renderStateLabel = (state) => {
      let text;
      let color = 'primary';

      switch (state) {
        case 'cancelled':
          text = `Annullato`;
          color = 'medium';
          break;

        case 'created':
        case 'managing':
          text = `In preparazione`;
          color = 'primary';
          break;

        case 'finalizing':
          text = `In attesa di conferma`;
          color = 'warning';
          break;

        case 'confirmed':
        case 'processing':
          text = `In preparazione`;
          color = 'secondary';
          break;

        case 'shipped':
          text = `In consegna`;
          color = 'tertiary';
          break;

        case 'completed':
          text = `Consegnato`;
          color = 'success';
          break;

        default:
          break;
      }

      return <IonBadge color={color}>{text}</IonBadge>;
    };

    const renderShippingMethod = () => {
      switch (order.shippingType) {
        case 'inShop':
          return (
            <>
              <IonText>Ritiro in negozio</IonText>
              <br />
              <IonText color="medium" className="ion-text-small">
                {order.shippingMethod.label}
              </IonText>
            </>
          );

        case 'address':
          let { address } = order;

          return (
            <IonText>
              {address.name},&nbsp;{address.address_1}&nbsp;{address.address_2}&nbsp;{address.city},&nbsp;
              {address.state_region}&nbsp;{address.postal_code}
            </IonText>
          );

        default:
          //console.log(method);
          break;
      }
    };

    const renderPayment = (method) => {
      if (method === 'payOnline') {
        return <IonText color="default">Anticipato con Carta di credito</IonText>;
      }

      if (!!paymentMethods[method] && !!paymentMethods[method].description) {
        return <IonText color="default">{paymentMethods[method].description}</IonText>;
      }

      return method;
    };

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/orders" />
            </IonButtons>

            <IonTitle>Dettagli ordine</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {!!order && !!paymentMethods ? (
            <>
              <IonList className="ion-margin-vertical">
                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>Data dell'ordine:</IonCol>
                      <IonCol className="ion-text-end">
                        {moment(order.created_at).locale('it', localization).format('lll')}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>Ordine#:</IonCol>
                      <IonCol className="ion-text-end">{order.id}</IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>Numero ricette:</IonCol>
                      <IonCol className="ion-text-end">{!!order.prescriptions ? order.prescriptions.length : 0}</IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>Preparazioni Galeniche:</IonCol>
                      <IonCol className="ion-text-end">
                        {order.product_requests.filter((x) => x.type === 'galenica').length}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>Foto di prodotti:</IonCol>
                      <IonCol className="ion-text-end">
                        {order.product_requests.filter((x) => x.type === 'search').length}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>Spedizione:</IonCol>
                      <IonCol className="ion-text-end">{renderShippingMethod()}</IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        Pagamento:
                        {!!order && order.status === 'finalizing' ? (
                          <>
                            <IonLabel>
                              <IonButton
                                size="small"
                                expand=""
                                color="light"
                                onClick={() => this.setState({ paymentModal: true })}
                                className=""
                              >
                                Cambia
                              </IonButton>
                            </IonLabel>

                            <IonModal isOpen={paymentModal}>
                              <IonHeader>
                                <IonToolbar>
                                  <IonButtons slot="end">
                                    <IonButton onClick={() => this.setState({ paymentModal: false })}>
                                      <IonIcon icon={close} />
                                    </IonButton>
                                  </IonButtons>
                                  <IonTitle>Seleziona un metodo di pagamento</IonTitle>
                                </IonToolbar>
                              </IonHeader>

                              <IonContent>
                                <IonList>
                                  {Object.keys(paymentMethods).map((key, i) => {
                                    return !!paymentMethods[key].shippingType.includes(order.shippingType) ? (
                                      <IonItem key={key}>
                                        <IonLabel>{paymentMethods[key].description}</IonLabel>
                                        <IonCheckbox
                                          slot="start"
                                          type={key}
                                          checked={order.paymentType === key}
                                          onIonChange={this.setPaymentMethod}
                                        />
                                      </IonItem>
                                    ) : null;
                                  })}
                                </IonList>
                              </IonContent>
                            </IonModal>
                          </>
                        ) : null}
                        {!!order.payment && !!order.payment.valid && order.payment.valid === 1 ? (
                          <IonLabel>
                            <IonBadge color="success">Pagato</IonBadge>
                          </IonLabel>
                        ) : null}
                      </IonCol>
                      <IonCol className="ion-text-end">{renderPayment(order.paymentType)}</IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>Consegna prevista:</IonCol>
                      <IonCol className="ion-text-end">
                        {!!order.delivery_date
                          ? moment(order.delivery_date).locale('it', localization).format('lll')
                          : ' non indicata '}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>Stato:</IonCol>
                      <IonCol className="ion-text-end">{renderStateLabel(order.status)}</IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                {!!order.info ? (
                  <IonItem>
                    <IonGrid>
                      <IonRow>
                        <IonCol>Note del farmacista:</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-padding-bottom ion-text-start">{order.info}</IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                ) : null}
              </IonList>

              <IonItem lines="full" onClick={this.toggleProducts}>
                <IonLabel>Prodotti in questo ordine</IonLabel>
                <IonIcon slot="end" icon={!!toggleProducts ? caretDown : caretBack} />
              </IonItem>

              {!!toggleProducts ? (
                !!products && !!products.length ? (
                  <IonList className="ion-margin-vertical">
                    {/* <IonListHeader mode="ios">Prodotti</IonListHeader> */}
                    {products.map((prod, key) => (
                      <IonItem key={key}>
                        <IonThumbnail slot="start">
                          <IonImg
                            src={
                              prod.immagine && prod.immagine !== ''
                                ? `https://static.fogliettoillustrativo.net/ws/it/${prod.immagine}`
                                : `/assets/prodotto_generico_125.png`
                            }
                            onClick={() => this.props.history.push(`/app/product/${prod.codice_ministeriale}`)}
                          />
                        </IonThumbnail>

                        <IonLabel onClick={() => this.props.history.push(`/app/product/${prod.codice_ministeriale}`)}>
                          <h4 className="ion-text-capitalize">
                            {!!prod.nome_prodotto ? prod.nome_prodotto : `Nome non disponibile`}
                          </h4>

                          {!!order.products.filter(({ code }) => code === prod.codice_ministeriale.toString()).pop() ? (
                            <p className="ion-text-capitalize">
                              Quantita:{' '}
                              {
                                order.products.filter(({ code }) => code === prod.codice_ministeriale.toString()).pop()
                                  .quantity
                              }
                            </p>
                          ) : null}

                          <p className="ion-text-capitalize">
                            {!!prod.descrizione_ditta_1_produttrice
                              ? prod.descrizione_ditta_1_produttrice
                              : prod.codice_ministeriale}
                          </p>
                        </IonLabel>

                        <IonLabel className="ion-text-end" slot="end">
                          {!!order.products.filter(({ code }) => code === prod.codice_ministeriale.toString()).pop() ? (
                            <>
                              <IonText className="ion-text-medium">
                                <Price
                                  price={
                                    order.products
                                      .filter(({ code }) => code === prod.codice_ministeriale.toString())
                                      .pop().total
                                  }
                                />
                              </IonText>
                              <span className="euro-symbol">€ </span>
                            </>
                          ) : null}
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                ) : (
                  <IonItem>
                    <IonLabel>
                      <p>Non sono presenti prodotti in questo ordine</p>
                    </IonLabel>
                  </IonItem>
                )
              ) : null}

              <IonItem>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonText className="ion-text-medium">Spedizione:</IonText>
                    </IonCol>
                    <IonCol className="ion-text-end">
                      <IonText className="ion-text-medium">
                        <Price price={order.shippingMethod.price} />
                      </IonText>
                      <span className="euro-symbol">€</span>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonText className="ion-text-medium">Sub totale:</IonText>
                    </IonCol>
                    <IonCol className="ion-text-end">
                      <IonText color="medium" className="ion-text-small">
                        ({order.totalQuantity} articoli){' '}
                      </IonText>
                      <IonText className="ion-text-medium">
                        <Price price={order.subTotal} />
                      </IonText>
                      <span className="euro-symbol">€</span>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>

              <IonItem>
                <IonGrid>
                  <IonRow>
                    <IonCol>Totale ordine:</IonCol>
                    <IonCol className="ion-text-end">
                      <IonText color="dark">
                        <Price price={order.total} />
                      </IonText>
                      <span className="euro-symbol">€</span>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            </>
          ) : null}
          <div className="ion-padding-start ion-padding-end">
            <IonButton
              size="small"
              expand="block"
              color="light"
              slot=""
              onClick={this.duplicateOrderProducts}
              className=""
            >
              <IonIcon slot="start" icon={bag} /> Rimetti i prodotti nel carrello
            </IonButton>
          </div>
        </IonContent>

        <IonFooter>
          {!!order && order.status === 'finalizing' ? (
            <IonToolbar>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonButton
                      expand=""
                      color="warning"
                      onClick={() => this.setState({ showAlertOrderDeny: true })}
                      className=""
                    >
                      <IonIcon slot="start" icon={close} /> Annulla
                    </IonButton>

                    <IonAlert
                      isOpen={showAlertOrderDeny}
                      onDidDismiss={() => this.setState({ showAlertOrderDeny: false })}
                      header={'Annnulla ordine'}
                      subHeader={"Non desidero procerdere con l'acquisto."}
                      message={"Una volta annullato l'ordine non può essere ripristinato."}
                      buttons={[
                        {
                          text: 'Indietro',
                          role: 'cancel',
                          cssClass: 'secondary',
                          handler: (blah) => {
                            this.setState({ showAlertOrderDeny: false });
                          },
                        },
                        {
                          text: 'Conferma',
                          handler: () => {
                            this.setState({ showAlertOrderDeny: false });
                            this.cancelOrder();
                          },
                        },
                      ]}
                    />
                  </IonCol>

                  <IonCol className="ion-text-end">
                    <IonButton
                      expand=""
                      color="success"
                      onClick={() => this.setState({ showAlertOrderConfirm: true })}
                      className=""
                    >
                      <IonIcon slot="start" icon={ok} /> Conferma
                    </IonButton>

                    <IonAlert
                      isOpen={showAlertOrderConfirm}
                      onDidDismiss={() => this.setState({ showAlertOrderConfirm: false })}
                      header={'Conferma ordine?'}
                      subHeader={"Desidero procedere con l'acquisto."}
                      message={''}
                      buttons={[
                        {
                          text: 'Indietro',
                          role: 'cancel',
                          cssClass: 'secondary',
                          handler: (blah) => {
                            this.setState({ showAlertOrderConfirm: false });
                          },
                        },
                        {
                          text: 'Procedi',
                          handler: () => {
                            this.setState({ showAlertOrderConfirm: false });
                            this.confirmOrder();
                          },
                        },
                      ]}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonToolbar>
          ) : null}
        </IonFooter>
      </>
    );
  }
}

export default withIonLifeCycle(OrderPage);
