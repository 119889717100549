import React, {useState, useEffect} from 'react'
// import { useIonViewWillEnter } from '@ionic/react';
import useSearchService from '../hooks/Home/useSearchService';
import TrackingService from '../services/tracking';
import AuthResource from '../resources/Auth';
import CartResource from '../resources/Cart';
import HeaderProduct from '../components/Product/HeaderProduct';
import ContentProduct from '../components/Product/ContentProduct';

export const ProductPage = (props) => {

  const { history } = props;
  const { slot } = props;
  const [toggleDescription, setToggleDescription] = useState(true);
  const [searchService, servicesLoading, loadSearchService] = useSearchService()

  useEffect(() => {
    if(!props.match.params.code) {
      return 0;
    }

    loadSearchService(props.match.params.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.code]);

  useEffect(() => {
    if(searchService !== undefined)
      TrackingService.viewProduct(searchService);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchService])  

  const addProductToCart = async (prod, nome_prodotto = 'Prodotto') => {
    if( !(await AuthResource.isLogged) ) {
      props.toast.current.add({
        message: `Accedi o registrati`,
        color: 'warning',
        duration: 1000
      });
      history.replace(`/auth/login?redirect=${props.location.pathname}`);
      return 0;
    }

    try {
      await CartResource.addProduct(prod);
      props.toast.current.add({
        message: `${ nome_prodotto } aggiunto al carrello`,
      });
    } catch (error) {
      props.toast.current.add({
        message: `Impossibile aggiungere ${ nome_prodotto } al carrello`,
      });
    }    
  }


  return (
    <>
      <HeaderProduct nome_prodotto={searchService?.nome_prodotto} slot={slot} history={history} />
      <ContentProduct 
        searchService={searchService} 
        fetching={servicesLoading} 
        addProductToCart={addProductToCart} 
        toggleDescription={toggleDescription} 
        setToggleDescription={setToggleDescription} 
      />
    </>
  )
}

export default ProductPage;