import React, { Component } from 'react';

import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton, IonItem, IonInput} from '@ionic/react';
import ProfileResource from '../services/profile';


export default class ReferrerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      referrer: ''
    };

  }
  async componentDidMount() {
    try {
      let { data: {data: referrer }}= await  ProfileResource.referrer();
      this.setState({ referrer });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>

        <IonHeader>
         <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>Invita un amico</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-margin ion-padding">
          <p>Il tuo codice amico è:</p>
          <IonItem>
            <IonInput value={this.state.referrer.code} readonly></IonInput>
          </IonItem>

          <p>Invita i tuoi amici, comunicagli il tuo codice amico oppure 
          condividi questo indirizzo:</p>
          <IonItem>
            <IonInput value={`${window.location.protocol+"//"+window.location.host}?ref=${this.state.referrer.code}`} readonly></IonInput>
          </IonItem>
        </IonContent>

      </>
    );
  }
}