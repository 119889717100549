import React from 'react'
import { IonText } from '@ionic/react';
import Price from '../Price';

function PriceSideProduct({searchService}) {
  return (
    
    <IonText className="ion-text-capitalize">
        <div className="ion-margin-bottom">
            <h3>{ searchService?.nome_prodotto }</h3> 
            <p className="price" >
                <IonText color="success">
                    <Price price={searchService?.prezzo}/>
                    <span className="euro-symbol">€</span>
                </IonText>
                {/* { PREZZO SCONTATO
                searchService?.prezzo_bancadati > searchService?.prezzo
                && 
                <>
                    &nbsp;
                    <span className="mainprice">
                        <Price price={searchService?.prezzo_bancadati}/>
                        <span className="euro-symbol">€</span>
                    </span>
                </>
                }

                { 
                searchService?.prezzo_bancadati > 0 && Math.round((searchService?.prezzo_bancadati - searchService?.prezzo)/searchService?.prezzo_bancadati*100)>5
                && 
                <IonText color="danger">&nbsp; Sconto {Math.round((searchService?.prezzo_bancadati - searchService?.prezzo)/searchService?.prezzo_bancadati*100)}%</IonText>
                } */}
                &nbsp;
                { 
                searchService?.quantita > 0
                ?<span>Disponibile</span>
                :<span>Da ordinare</span>
                }
            </p>
        </div>
    </IonText>

  )
}

export default PriceSideProduct