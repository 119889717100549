import {useState} from 'react'
import ShopServiceService from '../../services/shopservice';

const useServices = () => {
    const [services, setServices] = useState()
    // const [servicesLoading, setServicesLoading] = useState()
    // const [servicesError, setServicesError] = useState()

    const loadServices = async () => {
        try { 
            // setServicesLoading(true)
            const resp = await ShopServiceService.get();
            setServices(resp.data.data);
        } catch(error) {
            // setServicesError(error)
        } 
        // finally {
        //     setServicesLoading(false)
        // }
    }

    // return [services, servicesLoading, servicesError, loadServices]
    return [services, loadServices]
}

export default useServices