import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonText, IonHeader, IonFooter, IonTitle, IonToolbar} from '@ionic/react';
import { IonButtons, IonBackButton , IonButton} from '@ionic/react';
import OptionService from '../services/option';
import HeaderButtons from '../components/HeaderButtons';
import NotificationResource from '../resources/Notification/Notification'


class ThankyouPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      thankyou_note:'',
      pushNotification: false,
      smsNotification: false,
      emailNotification: false,

      version: undefined,
    };

  }

  async componentDidMount() {
    try {
      let [ 
        {value: {data: {value: thankyou_note}}}, 
      ] = await Promise.allSettled([
        OptionService.get('thankyou_note'),
      ]);
      this.setState({ thankyou_note });
    } catch (error) {
      //console.error(error);
    }
  }

  async ionViewWillEnter() {

    await this.setState({
      emailNotification: await NotificationResource.status('email')
    });
    await this.setState({
      smsNotification: await NotificationResource.status('sms')
    });
    await this.setState({
      pushNotification: await NotificationResource.status('push')
    });
    

  }

 
  render() {
    return (
      <>
        
        <IonHeader>
         <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>

            <HeaderButtons {...this.props} />  

            <IonTitle>Richiesta inviata</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-margin ion-padding">
          <IonText className="ion-padding-vertical" color="">
            {this.state.thankyou_note}
          </IonText>
          <p>
          { 
            this.state.smsNotification
            ? <>Riceverai un <b>SMS</b> quando il tuo ordine verrà elaborato dalla Farmacia</>
            : <>Attiva le notifiche via <b>SMS</b> per essere avvisato quando il tuo ordine verrà elaborato dalla Farmacia</>
          }
          </p>
          <p>
          { 
            this.state.emailNotification
            ? <>Riceverai una <b>EMail</b> quando il tuo ordine verrà elaborato dalla Farmacia</>
            : <>Attiva le notifiche via <b>EMail</b> per essere avvisato quando il tuo ordine verrà elaborato dalla Farmacia</>
          }
          </p>
          <p>
          { 
            this.state.pushNotification
            ? <>Riceverai una notifica sul tuo <b>SmartPhone</b> quando il tuo ordine verrà elaborato dalla Farmacia</>
            : <><b>Attiva</b> le notifiche Push per essere avvisato sul tuo <b>SmartPhone</b> quando il tuo ordine verrà elaborato dalla Farmacia</>
          }
          </p>
          { 
            this.state.emailNotification || this.state.smsNotification || this.state.pushNotification
            ? <IonButton color="primary" size="small" expand="block" onClick={() => this.props.history.push(`/app/settings`) }>Apri gestione notifiche</IonButton>
            : null
          }

        </IonContent>

        <IonFooter>
          <IonToolbar className="ion-padding">
            <IonButton color="light" expand="block" onClick={() => this.props.history.push(`/app/orders/${this.state.id}`) }>Verifica lo stato ordine</IonButton>
          </IonToolbar>
        </IonFooter>

      </>
    );
  }
}


export default withIonLifeCycle(ThankyouPage);