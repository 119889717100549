import React, { Component } from 'react';
import { helpCircleOutline as help} from 'ionicons/icons';
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  withIonLifeCycle,
} from '@ionic/react';
import Event from '../services/shopevent';
import ProfileService from '../services/profile';

class ShopEvent extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.files = React.createRef();

    this.state = {
      shop_service_id: undefined,
      note: '',
      events: [],
      shopServiceModal: false,
    };

    this.setService = this.setService.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.requestService = this.requestService.bind(this);
  }

  async ionViewWillEnter() {
    this.setState({
      id: this.props.id,
      profile: undefined,
      note: '',
      events: [],
    });

    try {
      let {
        data: { data: events },
      } = await Event.get();

      await this.setState({ events });
    } catch (error) {
      console.error(error);
    }

    try {
      // let {
      //   data: { data: profile },
      // } = await ProfileService.me();
      // await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }

    if (!!this.props.match.params.id) {
      await this.setService(this.props.match.params.id);
    }
  }

  async textHandler(event) {
    await this.setState({ [event.target.name]: event.detail.value });
  }

  async setService(event, date) {
    try {
      // let {
      //   data: { data: profile },
      // } = await ProfileService.me();
      // await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }

    await this.setState({
      shopServiceModal: true,
    });

    const [day, month, year] = date.split('/');
    const dateObject = new Date(year, month - 1, day);
    const yearFormatted = dateObject.getFullYear();
    const monthFormatted = ('0' + (dateObject.getMonth() + 1)).slice(-2);
    const dayFormatted = ('0' + dateObject.getDate()).slice(-2);
    const formattedDate = `${yearFormatted}-${monthFormatted}-${dayFormatted}`;

    this.props.history.push(`/app/events/${event.id}/${formattedDate}`);
  }

  async requestService(e) {
    try {
      let shopservice = {
        shop_service_id: this.state.shop_service_id,
        shop_service_name: this.state.events.find((event) => event.id === this.state.shop_service_id).name,
        note: this.state.note,
      };

      await Event.create(shopservice);
      this.setState({
        shop_service_id: undefined,
        note: '',
        shopServiceModal: false,
      });

      this.props.toast.current.add({
        message: `Richiesta inviata correttamente`,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    }
  }

  render() {
    const { events, profile } = this.state;
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/home" />
            </IonButtons>

            <IonTitle>Prenota un Evento</IonTitle>

            <IonButtons slot="end">
              <IonButton href="/app/help/Services">
                <IonIcon icon={help} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <p className="ion-padding-top ion-padding-start ion-padding-end">
            Ecco l'elenco dei nostri eventi, <br />
            seleziona l'evento che ti interessa e invia una richiesta al Farmacista
          </p>
          <IonGrid className="ion-no-margin ion-no-padding">
            <IonRow>
              {Object.entries(
                events.reduce((acc, event) => {
                  const uniqueAvailabilities = Array.from(
                    new Set(
                      event.availabilities ? event.availabilities.map((availability) => availability.start_at) : []
                    )
                  );

                  uniqueAvailabilities.forEach((start_at) => {
                    const date = new Date(start_at).toLocaleDateString('it-IT');
                    if (!acc[date]) {
                      acc[date] = [];
                    }
                    acc[date].push(event);
                  });

                  return acc;
                }, {})
              ).map(([date, events]) => (
                <React.Fragment key={date}>
                  {events.map((event, i) => (
                    <IonCol
                      size="12"
                      size-md="6"
                      size-lg="4"
                      key={event.id}
                      onClick={() => {
                        this.setService(event, date);
                      }}
                    >
                      <IonCard>
                        <IonCardContent className="text-xl">
                          <p>
                            <strong>{date}</strong>
                          </p>
                          <p className="text-xl">{event.name}</p>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  ))}
                </React.Fragment>
              ))}
            </IonRow>
          </IonGrid>
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(ShopEvent);
