import React, { Component } from 'react';

import moment from 'moment';
import localization from 'moment/locale/it';

import {
  withIonLifeCycle,
  IonContent,
  IonText,
  IonHeader,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonItemDivider,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

import HeaderButtons from '../components/HeaderButtons';
import Price from '../components/Price';

import NotificationResource from '../resources/Notification/Notification';

import OptionService from '../services/option';
import OrderService from '../services/order';
import SearchService from '../services/product';
import TrackingService from '../services/tracking';

class OrderConfirmedPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      orderconfirm_note: '',
      pushNotification: false,
      smsNotification: false,
      emailNotification: false,
      order: undefined,
      version: undefined,
    };
  }

  async componentDidMount() {
    try {
      const { data: order } = await OrderService.get(this.state.id);
      this.setState({ order });
      let promises = order.products.map((product) => SearchService.get(product.code));
      promises = await Promise.allSettled(promises);

      let products = promises
        .map((product) => (product.status === 'fulfilled' ? product.value.data : undefined))
        .filter((product) => !!product);

      TrackingService.purchase(order, products);

      let [
        {
          value: {
            data: { value: orderconfirmed_note },
          },
        },
      ] = await Promise.allSettled([OptionService.get('orderconfirmed_note')]);

      this.setState({ orderconfirmed_note });
    } catch (error) {
      console.error(error);
    }
  }

  async ionViewWillEnter() {
    await this.setState({
      emailNotification: await NotificationResource.status('email'),
    });

    await this.setState({
      smsNotification: await NotificationResource.status('sms'),
    });

    await this.setState({
      pushNotification: await NotificationResource.status('push'),
    });
  }

  render() {
    let { order } = this.state;

    return !!order && order.status === 'confirmed' ? ( //ordine in attesa di conferma
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <HeaderButtons {...this.props} />
            <IonTitle>Ordine Confermato</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-margin ion-padding">
          <IonGrid>
            <IonRow>
              <IonText>
                Ordine N° {this.state.order.id} del {moment(order.created_at).locale('it', localization).format('lll')}
              </IonText>
            </IonRow>
          </IonGrid>

          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText>Sub totale: </IonText>
                <IonText className="ion-text-medium">
                  <Price price={order.subTotal} />
                </IonText>
                <span className="euro-symbol">€ </span>

                <IonText color="medium" className="ion-text-small">
                  ({order.totalQuantity} articoli)
                </IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonText>Spedizione: </IonText>
                <IonText className="ion-text-medium">
                  <Price price={order.shippingMethod.price} />
                </IonText>
                <span className="euro-symbol">€ </span>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <b>
                  <IonText>Totale: </IonText>
                  <IonText color="dark">
                    <Price price={order.total} />
                  </IonText>
                  <span className="euro-symbol">€ </span>
                </b>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonItemDivider />

          <IonText className="ion-padding-vertical" color="">
            {this.state.orderconfirmed_note}
          </IonText>

          {this.state.smsNotification ? (
            <p>
              Riceverai un <b>SMS</b> quando il tuo ordine sarà pronto per il ritiro o la consegna
            </p>
          ) : (
            <p>
              Attiva le notifiche via <b>SMS</b> per essere avvisato quando il tuo ordine sarà pronto per il ritiro o la
              consegna
            </p>
          )}

          {this.state.emailNotification ? (
            <p>
              Riceverai una <b>EMail</b> quando il tuo ordine sarà pronto per il ritiro o la consegna
            </p>
          ) : (
            <p>
              Attiva le notifiche via <b>EMail</b> per essere avvisato quando il tuo ordine sarà pronto per il ritiro o
              la consegna
            </p>
          )}

          {this.state.pushNotification ? (
            <p>
              Riceverai una notifica sul tuo <b>SmartPhone</b> quando il tuo ordine sarà pronto per il ritiro o la
              consegna
            </p>
          ) : (
            <p>
              <b>Attiva</b> le notifiche Push per essere avvisato sul tuo <b>SmartPhone</b> quando il tuo ordine sarà
              pronto per il ritiro o la consegna
            </p>
          )}

          {this.state.emailNotification || this.state.smsNotification || this.state.pushNotification ? (
            <IonButton
              color="primary"
              size="small"
              expand="block"
              onClick={() => this.props.history.push(`/app/settings`)}
            >
              Apri gestione notifiche
            </IonButton>
          ) : null}
        </IonContent>

        <IonFooter>
          <IonToolbar className="ion-padding">
            <IonButton
              color="light"
              expand="block"
              onClick={() => this.props.history.push(`/app/orders/${this.state.id}`)}
            >
              Verifica lo stato ordine
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </>
    ) : null;
  }
}

export default withIonLifeCycle(OrderConfirmedPage);
