import * as firebase from "firebase/app";
import "firebase/analytics";

export default {
  
  signup: async (id) => {
    firebase.analytics().logEvent('user_signup');
  },

  login: async (id) => {
    firebase.analytics().logEvent('user_login');
  },

  recoveryBegin: async (id) => {
    firebase.analytics().logEvent('user_recovery_begin');
  },

  view: async (location) => {
    if(!!location){
        location = window.location.pathname;
    }

    firebase.analytics().setCurrentScreen(location)
    firebase.analytics().logEvent('screen_view')
  },



  viewProduct: async (product) => {
    //Prepare ecommerce event params
    const params3 = {
        currency: 'EUR',
        value: product.prezzo,
        items: [
            {
                item_id: product.codice_ministeriale,
                item_name: product.nome_prodotto,
                item_category: product.categoria1,
                item_variant: product.categoria2,
                item_brand: product.descrizione_ditta_1_produttrice,
                price: product.prezzo
            }
        ]
    };
    
    //Log event
    firebase.analytics().logEvent(firebase.analytics.EventName.VIEW_ITEM, params3);
  },

  addToCart: async (product) => {
    //Prepare ecommerce event params
    const params3 = {
        currency: 'EUR',
        value: product.price*product.quantity,
        items: [
            {
                item_id: product.code,
                item_name: product.nome_prodotto,
                item_category: product.categoria1,
                item_variant: product.categoria2,
                item_brand: product.descrizione_ditta_1_produttrice,
                price: product.amount,
                quantity: product.quantity
            }
        ]
    };

    //Log event
    firebase.analytics().logEvent(firebase.analytics.EventName.ADD_TO_CART, params3);
  },

  purchase: async (order,products) => {
    let items = [];
      items = products.map(product =>{
          return {
            item_id: product.code,
            item_name: product.nome_prodotto,
            item_category: product.categoria1,
            item_variant: product.categoria2,
            item_brand: product.descrizione_ditta_1_produttrice,
            price: product.amount,
            quantity: product.quantity
          };
      });
        
      //Prepare ecommerce bundle
      const params10 = {
        transaction_id: order.id,
        currency: 'EUR',
        value: order.total, // Total Revenue
        items: items
      };

      //Log event
      firebase.analytics().logEvent(firebase.analytics.EventName.PURCHASE, params10);
  },

}