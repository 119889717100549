import axios from './axios';

import auth from '../resources/Auth';

export default {

  get: async(id=undefined) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/product_requests${!!id ? `/${id}`: ''}`;

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },


  create: async(product_request) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/product_requests`;

    product_request.file = JSON.stringify(product_request.file);

    return await axios.post(url, product_request, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },

  update: async(id, product_request) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/product_requests/${id}`;

    product_request.file = JSON.stringify(product_request.file);

    return await axios.put(url, product_request, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },


  remove: async(id) => {
    let url = `${process.env.REACT_APP_API_URL}/cart/product_requests/${id}`;

    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  },



}