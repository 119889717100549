import EventEmitter from 'events';

import SystemService from '../services/system';
import ProfileService from '../services/profile';

const service = SystemService;
const timeout = !!process.env.REACT_APP_CHECK_VERSION_TIMER ? process.env.REACT_APP_CHECK_VERSION_TIMER : 3000;
let _systemInterval = undefined;

class System extends EventEmitter {

  get service() {
    return service;
  }

  get apiversion() {
    return localStorage.getItem('apiversion');
  }

  set apiversion(value) {
    return localStorage.setItem('apiversion', value);
  }

  async activeCheck() {
    if(!!_systemInterval) {
      return;
    }

    _systemInterval = setInterval (async () => {
      this.checkVersion();
    }, timeout);
  }

  async checkVersion() {
    try {
      const {data: {version}} = await this.service.getVersion();
      this.emit('system:online');
      
      if (!this.apiversion) {
        this.apiversion = version;
        return;
      }

      if (this.apiversion !== version) {
        this.apiversion = version;
        this.emit('system:version');
      }      

    } catch (error) {
      if (error.message === 'Network Error') {
        this.emit('system:offline');
        return;
      }
    }
  }

  async privacyCheck() {
    let profilevar = await ProfileService.me();
    
    if (
      !!profilevar && 
      !!profilevar.data && 
      !!profilevar.data.data && 
      !!profilevar.data.data.checkprivacy && 
      profilevar.data.data.checkprivacy === true
    ) {
      this.emit('privacycheck:on')
    } else {
      this.emit('privacycheck:off')
    }
  }

}

const systemResource = new System();


export default systemResource;