import React, { Component } from 'react';

import { IonButton, IonText, IonIcon } from '@ionic/react';
import { IonList, IonItem, IonLabel } from '@ionic/react';
import { IonInput } from '@ionic/react';

import { addCircleOutline as add, saveOutline as save, trashBinOutline as remove } from 'ionicons/icons';

import ProfileService from '../services/profile';
import AddressService from '../services/address';

import '../theme/container.css'

export default class AddressesForm extends Component {

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.name = React.createRef();
    this.address_1 = React.createRef();
    this.address_2 = React.createRef();
    this.city = React.createRef();
    this.state_region = React.createRef();
    this.postal_code = React.createRef();
    this.phone_number = React.createRef();

    this.state = {
      id: undefined,
      name: undefined,
      address_1: undefined,
      address_2: undefined,
      city: undefined,
      state_region: undefined,
      postal_code: undefined,
      phone_number: undefined,
      country: 'it',
      system: undefined,

      errors: {},
    }

    this.inputHandler = this.inputHandler.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.removeAddress = this.removeAddress.bind(this);
    this.setAddress = this.setAddress.bind(this);
  }

  async componentDidMount() {
    if (!!this.props.id) {
      await this.setState({ id: this.props.id });
      let { data: address } = await AddressService.get(this.state.id);
      await this.setState(address);
      return;
    }


    try {
      let {data: {data: {phone_number, name}}} = await ProfileService.me();
      this.setState({ phone_number, name });
      // this.setState({ codice_fiscale: me.codice_fiscale });
    } catch (error) {
      console.error(error);
    }
    
  }

  async inputHandler(event) {
    let errors = this.state.errors;
    delete errors[event.target.name];

    let value = event.target.value;

    
    if(!!value && value.replace(/\s/g, '').length === 0){
      value = value.trim(); 
    }
    if (event.target.uppercase && !!value) {
      value = value.toUpperCase();
    }

    await this.setState({
      [event.target.name]: value,
      errors,
    });
  }

  async handleValidation(event) {
    let errors = this.state.errors;
    let error = !!event.target ? event.target.firstChild.validationMessage : event.firstChild.validationMessage;
    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];
    await this.setState({ errors });

    if(!!error) {
      errors[name] = error;
      await this.setState({ errors });
    }
  }

  handleFormValidation(e) {
    if(!!!this.form.current.reportValidity()) {
       this.handleValidation(this.name.current);
       this.handleValidation(this.address_1.current);
       this.handleValidation(this.address_2.current);
       this.handleValidation(this.city.current);
       this.handleValidation(this.state_region.current);
       this.handleValidation(this.postal_code.current);
       this.handleValidation(this.phone_number.current);
    }
  }

  async removeAddress() {
    await AddressService.remove(this.props.id);
    if(this.props.onSubmit && (typeof this.props.onSubmit === 'function')) {
      this.props.onSubmit();
    }
  }

  async setAddress(e) {

    e.preventDefault();

    let address  = {
      name: this.state.name,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      city: this.state.city,
      state_region: this.state.state_region,
      postal_code: this.state.postal_code,
      phone_number: this.state.phone_number,
      country: this.state.country,
    }

    if(!!this.state.id) {
      address = (await AddressService.update(this.state.id, address)).data;
    } else {
      address = (await AddressService.create(address)).data;
    }

    await this.setState(address);
  
    if(this.props.onSubmit && (typeof this.props.onSubmit === 'function')) {
      this.props.onSubmit(address);
    }
  }


  render() {
    const { errors } = this.state;

    return (
      <form ref={ this.form } onSubmit={ this.setAddress } >
        <IonList lines="full" className="ion-no-margin ion-no-padding">

          <IonItem>
            <IonLabel color={ !!errors.name ? 'danger' : null} position="floating">Nome e cognome<IonText color="danger">*</IonText></IonLabel>
            <IonInput type="text" name="name" required
              ref={ this.name }
              maxlength={50}
              disabled={!!this.state.system}
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler } value={ this.state.name }/>
            {
              !!errors.name
                ? <IonText color="danger">
                    { errors.name }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel color={ !!errors.address_1 ? 'danger' : null} position="floating">Indirizzo<IonText color="danger">*</IonText></IonLabel>
            <IonInput type="text" name="address_1" required placeholder="Indirizzo, casella postale, ragione sociale, presso" color={ !!errors.address_1 ? 'danger' : null}
              ref={ this.address_1 }
              maxlength={60}
              disabled={!!this.state.system}
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler } value={ this.state.address_1 }/>
            {
              !!errors.address_1
                ? <IonText color="danger">
                    { errors.address_1 }
                  </IonText>
                : null
            }
            <IonInput type="text" name="address_2" placeholder="Appartamento, unità, edificio, piano, ecc."
              ref={ this.address_2 }
              maxlength={60}
              disabled={!!this.state.system}
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler } value={ this.state.address_2 }/>
            {
              !!errors.address_2
                ? <IonText color="danger">
                    { errors.address_2 }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel color={ !!errors.city ? 'danger' : null} position="floating">Città <IonText color="danger">*</IonText></IonLabel>
            <IonInput type="text" name="city" required
              ref={ this.city }
              maxlength={50}
              disabled={!!this.state.system}
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler } value={ this.state.city }/>
            {
              !!errors.city
                ? <IonText color="danger">
                    { errors.city }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel color={ !!errors.state_region ? 'danger' : null} position="floating">Provincia <IonText color="danger">*</IonText></IonLabel>
            <IonInput type="text" name="state_region" required
              ref={ this.state_region }
              maxlength={50}
              disabled={!!this.state.system}
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler } value={ this.state.state_region }/>
            {
              !!errors.state_region
                ? <IonText color="danger">
                    { errors.state_region }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel color={ !!errors.postal_code ? 'danger' : null} position="floating">Codice postale <IonText color="danger">*</IonText></IonLabel>
            <IonInput type="text" name="postal_code" required
              ref={ this.postal_code }
              maxlength={20}
              disabled={!!this.state.system}
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler } value={ this.state.postal_code }/>
            {
              !!errors.postal_code
                ? <IonText color="danger">
                    { errors.postal_code }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel color={ !!errors.phone_number ? 'danger' : null} position="floating">Numero di telefono</IonLabel>
            <IonInput type="tel" name="phone_number"
              ref={ this.phone_number }
              maxlength={20}
              disabled={!!this.state.system}
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler } value={ this.state.phone_number }/>
            {
              !!errors.phone_number
                ? <IonText color="danger">
                    { errors.phone_number }
                  </IonText>
                : null
            }
          </IonItem>

          {
            !!!this.state.system
              ? <div className="ion-padding">
                  {
                    !!this.state.id
                      ?  <IonButton expand="block" color="warning" className="ion-margin-bottom" onClick={ this.removeAddress }>
                          <IonIcon slot="start" icon={ remove } /> Cancella
                        </IonButton>
                      : null
                  }
      
                  <IonButton expand="block" type="submit" onClick={ this.handleFormValidation }>
                    {
                      !!this.state.id
                        ? <><IonIcon slot="start" icon={ save } /> Aggiorna</>
                        : <><IonIcon slot="start" icon={ add } /> Aggiungi</>
                    }              
                  </IonButton>
                </div>
              : null
          }
          

        </IonList>
      </form>
    );
  }
}