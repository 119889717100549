import EventEmitter from 'events';

import CartService from '../services/cart';

let cart = {};

class Cart extends EventEmitter {
  get service() {
    return CartService;
  }

  async total() {
    let total = cart.products.reduce((sum, el) => (sum += el.total), 0).toFixed(2);
    /* if(!!cart.vouchers){ //il calcolo del totale sconto viene applicato in fase di conferma ordine e/o pagamento
      for (var i = 0; i < cart.vouchers.length; i++) { 
        let voucher = cart.vouchers[i];
        if(voucher.active === 1){
          if( total >= parseFloat(voucher.minimum_cart_total) ){
            
            total = total - parseFloat(voucher.discount_amount);
          }
        }
      }
    }*/
    return total;
  }

  get products() {
    return cart.products.map((prod) => prod.product);
  }

  async current() {
    try {
      let resp = await CartService.current();
      cart = resp.data;
      return resp;
    } catch (error) {
      cart = {};
      throw error;
    }
  }

  async update({ ...args }) {
    await CartService.update(args);
    this.emitUpdateEvent();
  }

  async checkout({ ...args }) {
    let order = await CartService.checkout(args);
    this.emitUpdateEvent();
    return order;
  }

  async addProduct(code, quantity = 1) {
    await CartService.addProduct(code, quantity);
    this.emitUpdateEvent();
  }

  async quantityProduct(code, quantity = 1) {
    await CartService.quantityProduct(code, quantity);
    this.emitUpdateEvent();
  }

  async deleteProduct(code) {
    await CartService.deleteProduct(code);
    this.emitUpdateEvent();
  }

  async deleteCart(id) {
    await CartService.deleteCart(id);
    this.emitUpdateEvent();
  }

  async addVoucher(code) {
    await CartService.addVoucher(code);
    this.emitUpdateEvent();
  }

  async deleteVoucher(code) {
    await CartService.deleteVoucher(code);
    this.emitUpdateEvent();
  }

  async emitUpdateEvent() {
    this.emit('cart:update');
  }
}

const cartResource = new Cart();

export default cartResource;
