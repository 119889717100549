import React from 'react';

import { IonImg, IonCol, IonButton , IonIcon, IonLabel   } from '@ionic/react';
import {   
    // search,
    documentTextOutline,
    // cubeOutline as order,
    // callOutline,
    // calendarOutline,
    // flaskOutline
  } from 'ionicons/icons';
const css =  { width: '100%', maxWidth: '500px' };

const HomeButton = ({action, img, text, type, name}) => {

    return (
        <IonCol size="6" className="btn">
            {
                type === 'img'
                ?
                    <IonImg className="homeBtn"
                    onClick={action} 
                    style={css}
                    src={img}
                    />
                :
                    <IonButton size="default"  className="ion-padding" expand="block" color="light" fill="solid"   onClick={action} >
                        <IonIcon size="large" slot="start" icon={ documentTextOutline } />
                        <IonLabel>{text}</IonLabel>
                    </IonButton>
            }
        </IonCol>
    );

}

export default HomeButton