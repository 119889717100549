import {useState} from 'react'
import OptionService from '../../services/option';

const useOption = () => {
    const [options, setOptions] = useState()
    // const [optionsLoading, setOptionsLoading] = useState()
    // const [optionsError, setOptionsError] = useState()

    const loadOptions = async () => {
        try { 
            // setOptionsLoading(true)
            const resp = await OptionService.allClean();
            setOptions(resp.data.data);
        } catch(error) {
            // setOptionsError(error)
        } 
        // finally {
        //     setOptionsLoading(false)
        // }
    }
    // return [options, optionsLoading, optionsError, loadOptions]
    return [options, loadOptions]
}

export default useOption