import React, { Component } from 'react';

import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonSkeletonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonList,
  IonThumbnail,
  IonImg,
  IonText,
} from '@ionic/react';

import { arrowForwardOutline as forward } from 'ionicons/icons';

import SearchService from '../services/product';

import Price from '../components/Price';
import AuthResource from '../resources/Auth';
import CartResource from '../resources/Cart';

import { cartOutline as cart } from 'ionicons/icons';

import '../theme/ionIconStack.scss';

export default class CategoryPage extends Component {
  constructor(props) {
    super(props);

    this.loader = React.createRef();

    this.state = {
      title: "",
      categories: undefined,
      searchCategories: null,
      checkCategories: null,
      currentPage: 1,
      requestPerPage: 10,
      fetching: true,
      disabledInfiniteScroll: true,
      prods: [],
      messages: [],
    };
    
    this.openCat = this.openCat.bind(this);
    this.addProductToCart = this.addProductToCart.bind(this);
    this.infiniteScrollHandler = this.infiniteScrollHandler.bind(this);
  }

  async componentDidMount() {
    let par = [];
    if(this.props.match.params.category1 !== undefined){
      par[0] = this.props.match.params.category1;
      if(this.props.match.params.category2 !== undefined){
        par[1] = this.props.match.params.category2;
        if(this.props.match.params.category3 !== undefined){
          par[2] = this.props.match.params.category3;
        }
        if(this.props.match.params.category4 !== undefined){
          par[3] = this.props.match.params.category4;
        }
      }
    }
    
    const { data: categories } = await SearchService.getCategory();
    await this.setState({ categories: categories });
    switch (par.length) {
      case 0:
        await this.setState({ searchCategories: categories });
        break;
      case 1:
        await this.setState({ searchCategories: categories[par[0]] });
        break;
      case 2:
        if( categories[par[1]] === undefined){
          let prods = await this.searchProduct(categories[par[0]][par[1]]);
          await this.setState({ prods: prods });
        }else{
          await this.setState({ searchCategories: categories[par[1]] });
        }
        break;
      default:
        await this.setState({ searchCategories: categories });
        break;
    }
    await this.setState({ fetching: false });
  }

  async searchProduct(category) {
    let prods = this.state.prods;
    await this.setState({ disabledInfiniteScroll: false });
    await this.setState({ fetching: true });
    try {
      let search = await SearchService.searchCategory(
        category,
        this.state.currentPage,
        this.state.requestPerPage
      );

      
      prods = search.data.hits.hits.map((e) => {
        return e._source;
      });
      
      prods = this.state.prods.concat(prods);
      
      if (this.state.currentPage >= search.data.totPages) {
        await this.setState({ disabledInfiniteScroll: true });
      }
    } catch (error) {
      prods = this.state.prods;
    }
    await this.setState({ fetching: false });
    await this.setState({ prods: prods });

    return prods;
  }
  
  async openCat(category) {
    let current = window.location.pathname
    current = current.endsWith('/') ? current.slice(0, -1) : current;
    this.props.history.push(current + '/' + category);
  }

  async infiniteScrollHandler(e) {
    await this.setState({ currentPage: this.state.currentPage + 1 });
    let prods = await this.searchProduct();
    await this.setState({ prods });
    e.target.complete();
  }

  async addProductToCart(prod, nome_prodotto = 'Prodotto') {
    if (!!!(await AuthResource.isLogged)) {
      this.props.toast.current.add({
        message: `Accedi o registrati`,
        color: 'warning',
      });
      this.props.history.push('/auth/login');
      return;
    }

    await CartResource.addProduct(prod);
    this.props.toast.current.add({
      message: `${nome_prodotto} aggiunto al carrello`,
    });
  }

  render() {
    const { searchCategories } = this.state;
    const categoryItems = [];
    if( !!searchCategories ){
      Object.keys(searchCategories).forEach( function(category, index) {
        categoryItems.push(
          <IonItem key={category} onClick={() => this.openCat(category)}>
            <IonLabel>{category.charAt(0).toUpperCase() + category.slice(1)}</IonLabel>
            <IonIcon slot="end" icon={forward} />
          </IonItem>
        )
      }, this);
    }

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="" />
            </IonButtons>
            <IonTitle>
              {this.props.match.params.category1 !== undefined
                ? this.state.title.charAt(0).toUpperCase() + this.state.title.slice(1)
                : 'Ricerca per categorie'}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.state.prods.length > 0 ? (
            this.state.prods.map((prod, key) => (
              <IonItem key={key}>
                <IonThumbnail slot="start">
                  <IonImg
                    src={
                      prod.immagine && prod.immagine !== ''
                        ? `https://static.fogliettoillustrativo.net/ws/it/${prod.immagine}`
                        : `/assets/prodotto_generico_125.png`
                    }
                    onClick={() => this.props.history.push(`/app/product/${prod.codice_ministeriale}`)}
                  />
                </IonThumbnail>
                <IonLabel onClick={() => this.props.history.push(`/app/product/${prod.codice_ministeriale}`)}>
                  <h4 className="ion-text-capitalize">{prod.nome_prodotto}</h4>
                  <p className="ion-text-capitalize">{prod.descrizione_ditta_1_produttrice}</p>
                  <p className="price">
                    <IonText color="success">
                      <Price price={prod.prezzo} />
                      <span className="euro-symbol">€</span>
                    </IonText>
                    {/* PREZZO SCONTATO
                     prod.prezzo_bancadati <= prod.prezzo ? null : (
                      <>
                        &nbsp;
                        <span className="mainprice">
                          <Price price={prod.prezzo_bancadati} />
                          <span className="euro-symbol">€</span>
                        </span>
                      </>
                    )}
                    {prod.prezzo_bancadati > 0 &&
                      Math.round(((prod.prezzo_bancadati - prod.prezzo) / prod.prezzo_bancadati) * 100) > 5 && (
                        <IonText color="danger">
                          &nbsp; Sconto{' '}
                          {Math.round(((prod.prezzo_bancadati - prod.prezzo) / prod.prezzo_bancadati) * 100)}%
                        </IonText>
                      ) */}
                    &nbsp;
                    {prod.quantita > 0 ? <span>Disponibile</span> : <span>Da ordinare</span>}
                  </p>
                </IonLabel>
                <IonButton
                  slot="end"
                  color="light"
                  onClick={() => this.addProductToCart(prod.codice_ministeriale, prod.nome_prodotto)}
                  size="default"
                >
                  <IonIcon slot="icon-only" icon={cart} />
                </IonButton>
              </IonItem>
            ))
          ) : (
            <>
              { !!categoryItems && categoryItems.length >0 ? (
                <IonList lines="full" className="ion-no-margin ion-no-padding">
                  { categoryItems }
                </IonList>
              ) : (
                <>
                {!!!this.state.fetching && ( 
                  <>
                    <div className="ion-margin ion-padding">
                    <h1>Risultato non trovato</h1>
                      <p>La categoria cercata non è esistente</p>
                    </div>
                  </>
                )}
                </>
              )}
            </>
          )}
          {!!this.state.fetching && (
            <>
              {[...Array(this.state.requestPerPage).keys()].map((e, key) => (
                <IonItem key={key}>
                  <IonThumbnail slot="start">
                    <IonSkeletonText animated />
                  </IonThumbnail>
                  <IonLabel>
                    <h4>
                      <IonSkeletonText animated style={{ width: '50%' }} />
                    </h4>
                    <p>
                      {' '}
                      <IonSkeletonText animated style={{ width: '80%' }} />
                    </p>
                    <p>
                      {' '}
                      <IonSkeletonText animated style={{ width: '60%' }} />
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
            </>
          )}
          <IonInfiniteScroll disabled={this.state.disabledInfiniteScroll} onIonInfinite={this.infiniteScrollHandler}>
            <IonInfiniteScrollContent loadingSpinner={null} />
          </IonInfiniteScroll>
        </IonContent>
      </>
    );
  }
}
