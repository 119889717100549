import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons, IonBackButton } from '@ionic/react';

import QueryString from 'query-string';

import FiscalcodeForm from '../components/FiscalcodeForm';

class FiscalcodePage extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(fiscalcode) {
    let queryString = new URLSearchParams();

    let { redirect, ...rest } = QueryString.parse(this.props.location.search);
    redirect = !!redirect ? redirect : '/app/fiscalcodes';

    for (let [key, value] of Object.entries(rest)) {
      queryString.append(key, value);
    }

    if (!!fiscalcode && !!fiscalcode.id) {
      queryString.append('fiscalcode', fiscalcode.id);
    }

    redirect = `${redirect}?${queryString.toString()}`;
    this.props.history.replace(redirect);
  }

  render() {
    const { match } = this.props;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/fiscalcodes" />
            </IonButtons>

            <IonTitle>{!!!match.params.id ? 'Aggiungi' : 'Modifica'} Codice Fiscale</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <FiscalcodeForm id={match.params.id} onSubmit={this.handleSubmit} />
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(FiscalcodePage);
