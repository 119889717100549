import React from 'react';

import { IonContent, useIonViewWillEnter } from '@ionic/react';
// import { IonBackButton, IonButtons, IonButton } from '@ionic/react';
import { IonList } from '@ionic/react';

// import { cartOutline as cart } from 'ionicons/icons';

// import Price from '../components/Price';
// import OptionService from '../services/option';
// import SearchService from '../services/product';
import AuthResource from '../resources/Auth';
import CartResource from '../resources/Cart';

import usePromotions from '../hooks/Promotions/usePromotions';
import SkeletonPromotions from '../components/Promotions/SkeletonPromotions';
import Item from '../components/Promotions/Item';
import HeaderPromotions from '../components/Promotions/HeaderPromotions';
// import { IonSkeletonText } from '@ionic/react';

const PromotionsPage = (props) => {
  const [promotions, promotionsLoading, promotionsError, loadPromotions] = usePromotions();
  const { toast } = props;
  const { history } = props;

  const addProductToCart =  async (prod, nome_prodotto = 'Prodotto') => {
    if( !(await AuthResource.isLogged)  ) {
      toast.current.add({
        message: `Accedi o registrati`,
        color: 'warning',
      });
      history.push('/auth/login');
      return;
    }

    await CartResource.addProduct(prod);
    toast.current.add({
      message: `${ nome_prodotto } aggiunto al carrello`
    });
  } 

  useIonViewWillEnter(() => {
    loadPromotions()
  });
  
  return (
      <>
        <HeaderPromotions />
        <IonContent>
          <IonList>
            {!promotionsLoading && !promotionsError ? 
            <>
            {
              promotions?.map((prod, key) => (
                <Item key={key} prod={prod} history={history} addProductToCart={addProductToCart} />
              ))
            }            
            </>
            :
            <SkeletonPromotions />
            }
          </IonList>
        </IonContent>

      </>

  )

}

export default PromotionsPage

