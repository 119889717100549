import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonPage } from '@ionic/react';

import AuthResource from '../resources/Auth';

import Helper from './Helper';
import Toast from './Toast/Toast';
import Loader from './Loader';

import '../theme/custom.css';

class Wrapper extends Component {

  constructor(props) {
    super(props);

    this.toast = React.createRef();
    this.loader = React.createRef();    
    this.handlePrivate = this.handlePrivate.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.unauthorizedHandler = this.unauthorizedHandler.bind(this);    
    
  }

  async componentDidMount() {
    if (!!this.props.isPrivateRoute) {
      this.handlePrivate();
    }
  }

  async handlePrivate() {
    await Promise.allSettled([
      this.handleAuth(),
    ]);
  }

  async handleAuth() {
    AuthResource.on('auth:unauthorized', () => {
      this.unauthorizedHandler();
    });
  }

  async unauthorizedHandler() {
    this.props.history.push(`/auth/login?redirect=${this.props.path}`);
    // window.location = `/auth/login?redirect=${this.props.path}`;
  } 

  render() {
    const {component: Component, ...rest} = this.props;

    return (
      <IonPage>
        
        <Component {...rest} {...this.props} 
          toast={ this.toast } 
          loader={ this.loader } 
          />
        <Helper toast={ this.toast } />
        <Toast ref={ this.toast } />
        <Loader ref={ this.loader } />
      </IonPage>
    )
  }

}

export default withIonLifeCycle(Wrapper);