import axios from './axios';

export default {

  send: async(message) => {
    let url = `${process.env.REACT_APP_API_URL}/error-log`
    return await axios.post(
      url, 
      {
        message
      }
    );
  },

}