import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Wrapper from './Wrapper';

export default class PrivateRoute extends Component {

  constructor(props) {
    super(props);
    this.toast = React.createRef();
  }
  

  render() {
    const {component: Component, ...rest} = this.props;

    return (
      <Route update={ this.update } {...rest} render={props => (
        <Wrapper isPrivateRoute={ true } {...rest} {...props} component={ Component } />
      )} />
    )
  }

}

