import NotificationService from '../../services/notification';

import Push from './Push';
const push = new Push();

class Notification {

  get service() {
    return NotificationService;
  }

  get push() {
    return push;
  }

  async status(type) {
    switch (type) {
      case 'push':
        return this.push.status();
      case 'email':
        try {
          let { data: {active}} = await this.service.get('email');
          return !!active;
        } catch (error) {
          return false;
        }
      case 'sms':
        try {
          let { data: {active}} = await this.service.get('sms');
          return !!active;
        } catch (error) {
          return false;
        }
      default:
        return false;
    }
  }

  async active(type) {
    switch (type) {
      case 'push':
        return this.push.active();

      case 'email':
        return this.service.active('email', {});
      case 'sms':
        return this.service.active('sms', {});    
      default:
        break;
    }
  }

  disable(type) {

    switch (type) {
      case 'push':
        return this.push.disable();

      case 'email':
        return this.service.disable('email');
      case 'sms':
        return this.service.disable('sms');
      default:
        break;
    }

  }

}


const notificationResource = new Notification();

export default notificationResource;
