import React from 'react';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';

const UserButton = (props) => {

    return (
        <IonItem onClick={() => props.history.push(props.action)}>
            <IonLabel>{props.title}</IonLabel>
            <IonIcon slot="end" icon={props.icon} />
        </IonItem>
    );

}

export default UserButton