import axios from './axios';
import auth from '../resources/Auth';
export default {
  check: async (privacy1, privacy2, privacy3, userid) => {
    let url = `${process.env.REACT_APP_API_URL}/setuserprivacy`;

    const data = { privacy1, privacy2, privacy3 };
    
    await axios.post(url, data, {
      headers: { Authorization: `Bearer ${auth.token}` }
    });
  }
}
