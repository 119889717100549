import {useState} from 'react'
import ShopsService from '../../services/shops';

const useShops = () => {
    const [selected, setSelected] = useState();
    const [shops, setShops] = useState()
    // const [shopsLoading, setShopsLoading] = useState()
    // const [shopsError, setShopsError] = useState()

    const loadShops = async () => {
        try { 
            // setShopsLoading(true)
            const {data} = await ShopsService.get();
            setShops(data.data);

            if(data.userShop !== undefined){
                const {id, name} = data.userShop;
                setSelected({id, name});
            }
        } catch(error) {
            // setShopsError(error)
        } 
        // finally {
        //     setShopsLoading(false)
        // }
    }

    // return [shops, selected, shopsLoading, shopsError, loadShops]
    return [shops, selected, loadShops]
}

export default useShops