import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons, IonBackButton } from '@ionic/react';

import QueryString from "query-string";

import AddressesForm from '../components/AddressForm';



class AddressesPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(address) {
    let queryString = new URLSearchParams();

    let { redirect, ...rest } = QueryString.parse(this.props.location.search);
    redirect = !!redirect ? redirect : '/app/addresses';
 
    for (let [key, value] of Object.entries(rest)) {
      queryString.append(key, value);
    }

    if (!!address && !!address.id) {
      queryString.append('address', address.id)
    }

    redirect = `${redirect}?${queryString.toString()}`;
    this.props.history.replace(redirect);
  }

  render() {
    return (
      <>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/addresses" />
            </IonButtons>

            <IonTitle>{ !!this.props.id ? 'Aggiungi' : 'Modifica' } indirizzo</IonTitle>

          </IonToolbar>
        </IonHeader>

        <IonContent>

          <AddressesForm
            id={ this.props.match.params.id }
            onSubmit={ this.handleSubmit }
            />
          
        </IonContent>

      </>
    );
  }
}


export default withIonLifeCycle(AddressesPage);