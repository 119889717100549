import React, { Component } from 'react';
export default class Wrapper extends Component {

  constructor(props) {
    super(props);

    this.toast = React.createRef();
  }

  async componentDidMount() {
    // if (!!this.props.isPrivateRoute) {
    //   this.handlePrivate();
    // }

    // document.addEventListener('visibilitychange', () => {
    //   console.log(document.visibilityState);
    //   if (document.visibilityState === 'hidden') {
    //     console.log('refresh');
    //     window.location.reload(true);
    //   }
    // });
  }

  render() {
    return (
      <></>
    )
  }

}

