import axios from 'axios';
import Auth from '../resources/Auth';

const instance = axios.create();

instance.interceptors.response.use(
  response => response,
  async error => {
    try {
      if (!!!error.response) {
        return Promise.reject(error);
      }
      
      switch (error.response.status) {
        case 401:
          Auth.emit('auth:unauthorized', error);
          return Promise.reject(error);
  
        case 429:
          Auth.emit('auth:tooManyRequests', error);
          return Promise.reject(error);
        
        default:
          return Promise.reject(error);
      }
    } catch (error) {
      console.log('test', error);
      return Promise.reject(error);
    }
  }
);
export default instance;