import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import { IonText, IonButton } from '@ionic/react';

import AuthResource from '../resources/Auth';

class Reset extends Component {

  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.state = {
      profile: {},
      errors: {},
    };

    this.inputHandler = this.inputHandler.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async inputHandler(event) {
    let errors = this.state.errors;
    delete errors[event.target.name];

    let profile = this.state.profile;
    profile[event.target.name] = event.target.value;

    await this.setState({
      profile,
      errors,
    });
  }

  async handleValidation(event) {
    let errors = this.state.errors;
    let error = !!event.target ? event.target.firstChild.validationMessage : event.firstChild.validationMessage;
    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];

    try {
      this.form.current[name].setCustomValidity('');
      // this.form.current[name].reportValidity();
    } catch (error) {
      console.error(error);
    }
    
    if (name === 'password_confirmation') {
      this.handleValidation({ target: this.form.current.password.parentElement });
    }

    await this.setState({ errors });

    if(!!error) {
      errors[name] = error;
      await this.setState({ errors });
      await this.setState({ disabled: true });
      return;
    }
    await this.setState({ disabled: false });
  }

  async onSubmit(e) {
    e.preventDefault();

    let [email, password, password_confirmation, token] = [
      e.target.email.value,
      e.target.password.value,
      e.target.password_confirmation.value,
      this.props.token
    ];
    
    try {
      let response = await AuthResource.service.reset({ email, password, password_confirmation, token });
      
      if(!!this.props.onSubmit) {
        this.props.onSubmit(null, response);
      };

    } catch (error) {

      console.error(error);
      
      if (error.response) {
        let response = error.response.data.error;
        console.error(response);

        if (response instanceof Object) {
          for (const key in response) {
            if (response.hasOwnProperty(key)) {
              this.form.current[key].setCustomValidity('');
              this.form.current[key].setCustomValidity(response[key]);
              this.form.current[key].reportValidity();
              this.handleValidation({ target: this.form.current[key].parentElement });
            }
          }
        }
      }

      if(!!this.props.onSubmit) {
        this.props.onSubmit(error);
      };
    
    }

  }

  render() {
    const {errors} = this.state;

    return (
      <form ref={ this.form } onSubmit={this.onSubmit} >
        <IonList lines="full" className="ion-no-margin ion-no-padding">

          <IonItem>
            <IonLabel color={ !!errors.email ? 'danger' : null} position="floating">Email</IonLabel>
            <IonInput readonly type="email" name="email" 
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              value={ this.props.email }
              required />
            {
              !!errors.email
                ? <IonText color="danger">
                    { errors.email }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel color={ !!errors.password ? 'danger' : null} position="floating">Digita la nuova password</IonLabel>
            <IonInput type="password" name="password"
              clearInput={ true }
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              />
            {
              !!errors.password
                ? <IonText color="danger">
                    { errors.password }
                  </IonText>
                : null
            }
          </IonItem>

          <IonItem>
            <IonLabel color={ !!errors.password_confirmation ? 'danger' : null} position="floating">Digita ancora la password</IonLabel>
            <IonInput type="password" name="password_confirmation"
              clearInput={ true }
              onIonBlur={ this.handleValidation }
              onIonChange={ this.inputHandler }
              />
            {
              !!errors.password_confirmation
                ? <IonText color="danger">
                    { errors.password_confirmation }
                  </IonText>
                : null
            }
          </IonItem>

        </IonList>

        <div className="ion-padding">
          <IonButton expand="block" type="submit" className="ion-no-margin">Cambia password</IonButton>
        </div>

      </form>
    );
  }
}

export default withIonLifeCycle(Reset);
