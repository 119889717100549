import axios from './axios';

export default {

  me: async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/profile`;
      let token = localStorage.getItem('auth');
  
      return await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      return false;
    }

  },

  update: async ({...body}) => {
    let url = `${process.env.REACT_APP_API_URL}/profile`;
    let token = localStorage.getItem('auth');

    return await axios.put(url, body, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  referrer: async() => {
    let url = `${process.env.REACT_APP_API_URL}/profile/referrer`;
    let token = localStorage.getItem('auth');

    return await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

}