import React from 'react';

import { IonGrid, IonRow, IonCol } from '@ionic/react';
const css =  { width: '100%', maxWidth: '500px' };

const HomeImg = ({homeImage, shopName}) => {

    return (
        <IonGrid className="ion-no-padding">
            <IonRow className="ion-margin-bottom ion-no-padding">
                <IonCol className="ion-text-center ion-no-padding">
                    <img 
                    src={homeImage}
                    alt={shopName}
                    style={css} />
                </IonCol>
            </IonRow>
        </IonGrid>
    );

}

export default HomeImg