import React, { Component } from 'react';
import { helpCircleOutline as help } from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonTextarea,
  withIonLifeCycle,
} from '@ionic/react';
import ShopServiceService from '../services/shopservice';
import ProfileService from '../services/profile';

class ServicesInfo extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.files = React.createRef();

    this.state = {
      shop_service_id: this.props.match.params.id,
      note: '',
      services: [],
      shopServiceModal: false,
    };

    this.setService = this.setService.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.requestService = this.requestService.bind(this);
  }

  async componendDidMount() {
    if (!!this.props.match.params.id) {
      await this.setService(this.props.match.params.id);
    }
    try {
      this.ionViewWillEnter();
    } catch (error) {
      console.error(error);
    }
  }


  async ionViewWillEnter() {
    this.setState({
      id: this.props.match.params.id,
      profile: undefined,
      note: '',
      services: [],
    });

    try {
      let {
        data: { data: services },
      } = await ShopServiceService.get();

      await this.setState({ services });
    } catch (error) {
      console.error(error);
    }

    try {
      let {
        data: { data: profile },
      } = await ProfileService.me();

      await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }

    if (!!this.props.match.params.id) {
      await this.setService(this.props.match.params.id * 1);
    }
  }

  
  async textHandler(event) {
    await this.setState({ [event.target.name]: event.detail.value });
  }

  async setService(shop_service_id) {
    try {
      let {
        data: { data: profile },
      } = await ProfileService.me();

      await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }

    await this.setState({
      shop_service_id,
      shopServiceModal: true,
    });
  }

  async requestService(e) {
    try {
      let service = this.state.services.find((service) => service.id === this.state.shop_service_id);

      let shopservice = {
        shop_service_id: service.id,
        shop_service_name: service.name,
        note: this.state.note,
      };

      await ShopServiceService.create(shopservice);
      this.setState({
        shop_service_id: undefined,
        note: '',
        shopServiceModal: false,
      });

      this.props.toast.current.add({
        message: `Richiesta inviata correttamente`,
      });
      this.props.history.push(`/app/services`);
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    }
  }

  render() {
    const { services, shop_service_id, profile } = this.state;

    const service = services.find((service) => service.id === shop_service_id);

    return (
      <>
      <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/services" />
            </IonButtons>

            <IonTitle>{ !!service ? "Richiedi informazioni per "+service.name : "Richiedi informazioni servizio"}</IonTitle>

            <IonButtons slot="end">
              <IonButton href="/app/help/Services">
                <IonIcon icon={help} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
        { !!profile
        ? <IonItem>
            <p>
              Sarai ricontattato al numero di telefono {profile.phone_number} oppure alla email {profile.email} che hai inserito nel &nbsp;
              <IonButton
                color="light"
                size="small"
                onClick={() => {
                  this.setState({ shopServiceModal: false, shop_service_id: undefined });
                  this.props.history.push(`/app/profile`);
                }}
              >
                tuo profilo
              </IonButton>
            </p>
            <IonLabel position="stacked">Note aggiuntive</IonLabel>
            <IonTextarea
              type="text"
              name="note"
              placeholder="Indica qui se hai richieste particolari da inviare al Farmacista"
              value={this.state.note}
              onIonChange={this.textHandler}
            />
          </IonItem> : null}

          <IonButton expand="block" color="success" onClick={this.requestService} className="ion-margin-horizontal">
            Richiedi informazioni
          </IonButton>
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(ServicesInfo);
