import React, { Component } from 'react';

import moment from 'moment';
import localization from 'moment/locale/it';

import { cardOutline, person, closeOutline as close, card } from 'ionicons/icons';
import {
  withIonLifeCycle,
  IonText,
  IonRow,
  IonGrid,
  IonCol,
  IonImg,
  IonModal,
  IonContent,
  IonHeader,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonItemDivider,
  IonDatetime,
} from '@ionic/react';

import ProfileService from '../../services/profile';
import PaymentService from '../../services/payment';
import OrderService from '../../services/order';
import HeaderButtons from '../../components/HeaderButtons';
import Price from '../../components/Price';

import '../../theme/container.css';
import './payment.css';

class PaymentPage extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      id: this.props.match.params.id,
      errors: [],
      disabled: true,
      order: undefined,
      paymentModal: false,
    };

    this.pay = this.pay.bind(this);
    this.closePaymentModal = this.closePaymentModal.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleExpMonthValidation = this.handleExpMonthValidation.bind(this);
    this.handleExpYearValidation = this.handleExpYearValidation.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
  }

  async ionViewWillEnter() {
    await this.setState({ id: this.props.match.params.id });
    const { data: order } = await OrderService.get(this.state.id);

    if (!!!order.id) {
      this.props.history.push(`/app/orders`);
      return;
    }

    if (
      !!!order.payment || //vero se payment è null
      !!!order.payment.valid ||
      order.payment.valid !== 1
    ) {
      order.payment = {};
      order.payment.valid = 0;
    }

    this.setState({ order });
    if (order.payment.valid === 1) {
      this.props.history.push(`/app/orders/${this.state.id}`);
    }
  }

  async closePaymentModal() {
    const { data: order } = await OrderService.get(this.state.id);

    if (
      !!!order.payment || //vero se payment è null
      !!!order.payment.valid ||
      order.payment.valid !== 1
    ) {
      order.payment = {};
      order.payment.valid = 0;
    }

    this.setState({ order });
    if (order.payment.valid === 1) {
      this.props.history.push(`/app/orders/${this.state.id}`);
    }

    this.setState({ paymentModal: false });
  }

  async inputHandler(event) {
    let errors = this.state.errors;
    delete errors[event.target.name];

    await this.setState({
      [event.target.name]: event.target.value,
      errors,
    });

    await this.handleValidation(event);
    await this.handleFormValidation();
  }

  async handleExpMonthValidation(event) {
    let errors = {};
    let error = false;

    if (!event.target.value) {
      error = 'Compila questo campo.';
    }

    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];
    if (error) errors[name] = error;
    await this.setState({ errors });
    return false;
  }

  async handleExpYearValidation(event) {
    let errors = {};
    let error = false;

    if (!event.target.value) {
      error = 'Compila questo campo.';
    }

    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];
    if (error) errors[name] = error;
    await this.setState({ errors });
    return false;
  }

  async handleValidation(event) {
    let errors = {};
    let error = false;

    if (event.target && event.target.firstChild && event.target.firstChild.validationMessage) {
      error = event.target.firstChild.validationMessage;
    }

    if (event.firstChild && event.firstChild.validationMessage) {
      error = event.firstChild.validationMessage;
    }

    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];
    if (error) errors[name] = error;
    await this.setState({ errors });
    return false;
  }

  async handleFormValidation() {
    await this.setState({ disabled: true });
    if (this.form.current.checkValidity()) {
      await this.setState({ disabled: false });
      return true;
    }

    return false;
  }

  async pay(e) {
    e.preventDefault();
    if (!this.handleFormValidation()) {
      return;
    }

    let creditcard = {
      holder: this.state.cardHolderName,
      number: this.state.cardNumber,
      expMonth: moment(this.state.expMonth).format('MM'),
      expYear: moment(this.state.expYear).format('YY'),
      cvv: this.state.cardCvv,
    };

    try {
      this.props.loader.current.show();
      await this.setState({ disabled: true });
      const { data: paymentCreate } = await PaymentService.create('gestpay', this.state.id);

      let {
        data: { data: profile },
      } = await ProfileService.me();

      const { data: paymentSubmit } = await PaymentService.submit(profile, paymentCreate, creditcard);

      if (!!!paymentSubmit.error.code) {
        throw new Error(`Risposta del server non valida`);
      }

      if (paymentSubmit.error.code !== '0') {
        throw new Error(`${paymentSubmit.error.description} E${paymentSubmit.error.code}`);
      }

      if (!!paymentSubmit.payload.userRedirect.href) {
        window.location.href = paymentSubmit.payload.userRedirect.href;
        return;
      }

      window.location.href = `/app/payment-end?Status=Unk&paymentID=${paymentCreate.payload.paymentID}`;
    } catch (error) {
      this.props.toast.current.add({
        message: `Si è verificato un errore: ${error}. Verifica i dati inseriti e riprova.`,
        color: 'warning',
      });

      console.error(error);
    } finally {
      this.props.loader.current.dismiss();
      this.setState({ disabled: false });
    }
  }

  render() {
    let { order } = this.state;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref={`/app/orders/${this.state.id}`} />
            </IonButtons>
            <HeaderButtons {...this.props} />
            <IonTitle>Pagamento con carta di credito</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {!!order &&
          order.status === 'finalizing' && //ordine in attesa di conferma
          order.payment.valid !== '1' ? ( //pagamento non eseguito
            <form ref={this.form} onSubmit={this.pay}>
              <IonItemDivider>
                <IonLabel>Dati di acquisto</IonLabel>
              </IonItemDivider>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText>
                      Ordine N° {this.state.order.id} del{' '}
                      {moment(order.created_at).locale('it', localization).format('lll')}
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText>Sub totale: </IonText>
                    <IonText className="ion-text-medium">
                      <Price price={order.subTotal} />
                    </IonText>
                    <span className="euro-symbol">€ </span>

                    <IonText color="medium" className="ion-text-small">
                      ({order.totalQuantity} articoli)
                    </IonText>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonText>Spedizione: </IonText>
                    <IonText className="ion-text-medium">
                      <Price price={order.shippingMethod.price} />
                    </IonText>
                    <span className="euro-symbol">€ </span>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <b>
                      <IonText>Totale: </IonText>
                      <IonText color="dark">
                        <Price price={order.total} />
                      </IonText>
                      <span className="euro-symbol">€ </span>
                    </b>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonItemDivider>
                <IonLabel>&nbsp;Dati di pagamento</IonLabel>
              </IonItemDivider>

              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <IonItem>
                      <IonLabel color={!!this.state.errors.cardHolderName ? 'danger' : null} position="floating">
                        <IonIcon icon={person}></IonIcon>&nbsp; Titolare della Carta
                      </IonLabel>
                      <IonInput
                        type="text"
                        name="cardHolderName"
                        placeholder="Nome e Cognome"
                        clearInput={true}
                        onIonBlur={this.handleValidation}
                        onIonChange={this.inputHandler}
                        required
                      />
                      {!!this.state.errors.cardHolderName ? (
                        <IonText color="danger">{this.state.errors.cardHolderName}</IonText>
                      ) : null}
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="8">
                    <IonItem>
                      <IonLabel color={!!this.state.errors.cardNumber ? 'danger' : null} position="floating">
                        <IonIcon icon={cardOutline}></IonIcon>&nbsp; Numero della Carta
                      </IonLabel>
                      <IonInput
                        type="tel"
                        pattern="[\d| ]{16,22}"
                        name="cardNumber"
                        placeholder="0000 0000 0000 0000"
                        clearInput={true}
                        onIonBlur={this.handleValidation}
                        onIonChange={this.inputHandler}
                        required
                      />
                      {!!this.state.errors.cardNumber ? (
                        <IonText color="danger">{this.state.errors.cardNumber}</IonText>
                      ) : null}
                    </IonItem>
                  </IonCol>
                  <IonCol size="4">
                    <IonLabel>
                      <IonImg style={{ width: '100%', height: '50px' }} src={'/assets/visa-mastercard.jpg'} />
                    </IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="3">
                    <IonItem>
                      <IonLabel color={!!this.state.errors.expMonth ? 'danger' : null} position="floating">
                        Mese
                      </IonLabel>
                      <IonDatetime
                        name="expMonth"
                        placeholder="MM"
                        pickerFormat="MM"
                        displayFormat="MM"
                        doneText="Seleziona"
                        cancelText="Annulla"
                        onIonBlur={this.handleExpMonthValidation}
                        onIonCancel={this.handleExpMonthValidation}
                        onIonChange={this.inputHandler}
                        required
                      ></IonDatetime>
                    </IonItem>
                  </IonCol>

                  <IonCol size="4">
                    <IonItem>
                      <IonLabel color={!!this.state.errors.expYear ? 'danger' : null} position="floating">
                        Anno
                      </IonLabel>
                      <IonDatetime
                        name="expYear"
                        max={new Date(new Date().setFullYear(new Date().getFullYear() + 20)).toISOString()}
                        min={new Date().toISOString()}
                        placeholder="YYYY"
                        pickerFormat="YYYY"
                        displayFormat="YYYY"
                        doneText="Seleziona"
                        cancelText="Annulla"
                        required
                        onIonBlur={this.handleExpYearValidation}
                        onIonCancel={this.handleExpYearValidation}
                        onIonChange={this.inputHandler}
                      ></IonDatetime>
                    </IonItem>
                  </IonCol>

                  <IonCol size="4">
                    <IonItem>
                      <IonLabel color={!!this.state.errors.cardCvv ? 'danger' : null} position="floating">
                        CVV
                      </IonLabel>
                      <IonInput
                        style={{ marginTop: '2px', marginBottom: '4px' }}
                        max="999"
                        min="000"
                        placeholder="123"
                        type="number"
                        name="cardCvv"
                        clearInput={true}
                        onIonBlur={this.handleValidation}
                        onIonChange={this.inputHandler}
                        required
                      />
                      {!!this.state.errors.cardCvv ? (
                        <IonText color="danger">{this.state.errors.cardCvv}</IonText>
                      ) : null}
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonItemDivider>
                <IonLabel></IonLabel>
              </IonItemDivider>

              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <IonButton
                      expand="block"
                      color="success"
                      type="submit"
                      disabled={this.state.disabled}
                      onClick={this.handleFormValidation}
                      className="ion-margin-horizontal "
                    >
                      <IonIcon icon={card} />
                      &nbsp;
                      <IonLabel>Esegui Pagamento </IonLabel>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          ) : (
            <div className="ion-margin ion-padding">
              <div className="ion-text-center ion-padding-vertical">
                <p>Non è possibile eseguire il pagamento di questo ordine.</p>
                <p>L'ordine è già stato pagato oppure è in uno stato che non può ricevere pagamenti</p>
                <IonButton
                  color="light"
                  expand="block"
                  onClick={() => this.props.history.push(`/app/orders/${this.state.id}`)}
                >
                  Vedi ordine
                </IonButton>
              </div>
            </div>
          )}
        </IonContent>

        <IonModal isOpen={this.state.paymentModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => this.closePaymentModal()}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
              <IonTitle>Pagamento in corso</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <iframe title="Pagamento in corso" style={{ width: '100%', height: '100%' }} src={this.state.iframeurl} />
          </IonContent>
        </IonModal>

        <IonFooter className="ion-padding"></IonFooter>
      </>
    );
  }
}

export default withIonLifeCycle(PaymentPage);
