import React, { Component } from 'react';
import moment from 'moment';

import { withIonLifeCycle } from '@ionic/react';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonItem,
  IonLabel,
  IonImg,
} from '@ionic/react';

import SystemResource from '../../resources/System';

import FidelityCardService from '../../services/fidelityCard';

class FidelityCardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      card: null,
    };
  }

  async ionViewWillEnter() {
    const {  loader } = this.props;

    if (!!loader) loader.current.show();
    try {
      SystemResource.checkVersion();

      const { data: card } = await FidelityCardService.getMain();

      this.setState({ card });
    } catch (error) {
      console.error(error);
    } finally {
      loader.current.dismiss();
    }
  }

  render() {
    const { card } = this.state;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/user" />
            </IonButtons>

            <IonTitle>Carta Fedeltà</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {card ? (
            <>
              {card.barcode ? (
                <ion-item-group>
                  <ion-item-divider>
                    <IonLabel className="ion-text-uppercase" color="dark">
                      <b>La carta</b>
                    </IonLabel>
                  </ion-item-divider>

                  <IonItem lines="none">
                    <IonLabel>
                      <IonImg  src={`data:image/png;base64, ${card.barcode}`} alt="barcode" ></IonImg>
                    </IonLabel>
                  </IonItem>

                  <IonItem />
                </ion-item-group>
              ) : null}

              <ion-item-group>
                <ion-item-divider>
                  <IonLabel className="ion-text-uppercase" color="dark">
                    <b>Informazioni della carta</b>
                  </IonLabel>
                </ion-item-divider>

                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">ID carta</h4>
                    <p>{card.card_id}</p>
                  </IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Punti</h4>
                    <p>{card.score}</p>
                  </IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Ultimo aggiornamento</h4>
                    <p>{moment(card.updated_at).format('DD MMMM YYYY')}</p>
                  </IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Concorso valido fino al</h4>
                    <p>{moment(card.reset_date).format('DD MMMM YYYY')}</p>
                  </IonLabel>
                </IonItem>

                {/* <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Data di scadenza della carta</h4>
                    <p>{moment(card.expiration_date).format('DD MMMM YYYY')}</p>
                  </IonLabel>
                </IonItem> */}

                {/* <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Data di attivazione</h4>
                    <p>{moment(card.created_at).format('DD MMMM YYYY')}</p>
                  </IonLabel>
                </IonItem> */}
              </ion-item-group>
            </>
          ) : (
            <IonItem lines="none">
              <IonLabel>
                <p>Nessuna carta fedeltà trovata</p>
              </IonLabel>
            </IonItem>
          )}
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(FidelityCardPage);
